import React from 'react';
import { Root, OffsetRootProps } from './Offset.styles';

interface OffsetProps extends OffsetRootProps {
  children: React.ReactElement | Array<React.ReactElement>;
}

export const Offset = (props: OffsetProps) => {
  const { children, ...rootProps } = props;
  return <Root {...rootProps}>{children}</Root>;
};
