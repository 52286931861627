import { AxiosRequestConfig } from 'axios';
import { PaymentHeaders } from './headers';
import { logger } from '@/logger';

function requestInterceptor(config: AxiosRequestConfig): AxiosRequestConfig {
  const { headers, url } = config;

  const attr = {
    ...(headers &&
      headers[PaymentHeaders.DEVICE_ID] && {
        deviceId: headers[PaymentHeaders.DEVICE_ID] as string,
      }),
    ...(headers &&
      headers[PaymentHeaders.STORE_CODE] && {
        storeCode: headers[PaymentHeaders.STORE_CODE] as string,
      }),
    ...(headers && headers[PaymentHeaders.LOCALE] && { locale: headers[PaymentHeaders.LOCALE] }),
    ...(headers &&
      headers[PaymentHeaders.USER_AGENT] && {
        userAgent: headers[PaymentHeaders.USER_AGENT],
      }),
  };

  logger.info(`Triggered request to ${url}`, attr);

  return config;
}

export { requestInterceptor };
