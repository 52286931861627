import styled from 'styled-components';
import { ButtonV2 } from '@getgrover/ui';

export const AddPaymentMethodButtonRoot = styled(ButtonV2)`
  border-radius: 8px;
  justify-content: flex-start;
  height: 60px;
  flex-shrink: 0;

  & > * {
    font-weight: 500;
  }
`;
