import styled from 'styled-components';

export const OutlineWrapper = styled.div`
  width: 36px;
  height: 24px;
  background: ${({ theme }) => theme.colors.background.white};
  border: 1px solid ${({ theme }) => theme.colors.stroke.lighterGrey};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
`;

export const BrandPlaceholder = styled.div`
  width: 72px;
  height: 40px;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.background.superLightGrey};
`;
