import styled from 'styled-components';
import { TextEllipsis } from '@/components/PaymentMethod/PaymentMethod.styles';

export const PaymentMethodSingleViewRoot = styled.div`
  position: relative;
  width: 300px;
  height: 200px;
  padding: 16px;
  border-radius: 8px;
  box-shadow: ${() =>
    '0px 0px 3px rgba(0, 0, 0, 0.05), 0px 4px 10px rgba(0, 0, 0, 0.13)'};
  background: ${({ theme }) => theme.colors.background.white};
`;

export const PaymentMethodSingleViewLoadingRoot = styled(PaymentMethodSingleViewRoot)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const BottomWrapper = styled.div`
  width: calc(100% - 32px);
  position: absolute;
  bottom: 16px;
`;

export const FlexBottomLine = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const GrownEllipsis = styled(TextEllipsis)`
  flex-grow: 1;
`;
