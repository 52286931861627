import { PageType, sendPageAnalyticsEvent } from '@devsbb/analytics-client';

type EventProperties = {
  path: string;
  store?: string;
  store_id?: number;
  locale?: string;
  legacy?: boolean;
};

export const trackYourPaymentsPageVisitedEvent = (properties: EventProperties) => {
  const slug = properties.path.split('/').at(2);
  sendPageAnalyticsEvent('Page Viewed', { page_type: PageType.YourPayments, slug, ...properties });
};

export const trackYourProfilePageVisitedEvent = (properties: EventProperties) => {
  const slug = properties.path.split('/').at(2);
  sendPageAnalyticsEvent('Page Viewed', { page_type: PageType.YourProfile, slug, ...properties });
};
