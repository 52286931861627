import React from 'react';
import { PaymentMethodWithContracts } from '@/types/paymentMethods';
import {
  getPaymentMethodDisplayType,
  getPrimaryText,
  paymentMethodDisplayTextsMap,
} from '@/utils/paymentMethods';
import { useTranslation } from 'react-i18next';

interface ToastTextProps {
  paymentMethod: PaymentMethodWithContracts;
  prefixText: string;
}

export const ToastText = ({ paymentMethod, prefixText }: ToastTextProps) => {
  const { t } = useTranslation();
  const primaryText = getPrimaryText({ paymentMethod, t });
  const displayType = getPaymentMethodDisplayType(paymentMethod);
  const toastTypeText = paymentMethodDisplayTextsMap[displayType];

  return (
    <span>
      <span>{`${prefixText} `}</span>
      <div>
        <b>{`${toastTypeText}: `}</b>
        <span>{primaryText}</span>
      </div>
    </span>
  );
};
