import styled from 'styled-components';

export const EmptyContentWrapper = styled.div`
  text-align: center;
`;

export const ProgressHolder = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.background.white};
  opacity: 0.95;
`;
