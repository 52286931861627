import { sendAnalyticsEvent } from '@devsbb/analytics-client';

type EventProperties = {
  userId: string;
  store: string;
  bulk?: boolean;
};

export const trackChangePaymentMethodCancelEvent = (properties: EventProperties) => {
  sendAnalyticsEvent<EventProperties>('Change Payment Method Cancelled', properties);
};

export const trackChangePaymentMethodSubmitEvent = (properties: EventProperties) => {
  sendAnalyticsEvent<EventProperties>('Change Payment Method Submitted', properties);
};

export const trackChangePaymentMethodAddStartedEvent = (properties: EventProperties) => {
  sendAnalyticsEvent<EventProperties>('Change Payment Method Add Started', properties);
};
