import React from 'react';
import { useTheme } from 'styled-components';

export const WalletIcon = ({ color }: { color?: string }) => {
  const theme = useTheme();
  const defaultColor = theme.colors.text.darkerGrey;

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14 15.5C15.1046 15.5 16 14.6046 16 13.5C16 12.3954 15.1046 11.5 14 11.5C12.8954 11.5 12 12.3954 12 13.5C12 14.6046 12.8954 15.5 14 15.5Z"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinejoin="round"
        stroke={color || defaultColor}
      />
      <path
        d="M4 6V17C4 17.5304 4.21071 18.0391 4.58579 18.4142C4.96086 18.7893 5.46957 19 6 19H19V8H6C5.46957 8 4.96086 7.78929 4.58579 7.41421C4.21071 7.03914 4 6.53043 4 6ZM4 6C4 5.46957 4.21071 4.96086 4.58579 4.58579C4.96086 4.21071 5.46957 4 6 4H15V6"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinejoin="round"
        stroke={color || defaultColor}
      />
    </svg>
  );
};
