import React from 'react';
import { Root, TextRootProps } from './Text.styles';

export interface TextProps extends TextRootProps {
  children: string | null | undefined | React.ReactNode | React.ReactNodeArray;
  'data-testid'?: string;
}

export const Text = (props: TextProps) => {
  const { children } = props;
  return <Root {...props}>{children}</Root>;
};
