import { tk } from '@/i18n/translationKeys';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export enum PageName {
  yourPayments = 'yourPayments',
  paymentMethodList = 'paymentMethodList',
  paymentMethodDetails = 'paymentMethodDetails',
  addPaymentMethod = 'addPaymentMethod',
  changePaymentMethod = 'changePaymentMethod',
  bulkPaymentMethod = 'bulkPaymentMethod',
}

const pageNameToTitleTk: Record<PageName, string> = {
  [PageName.yourPayments]: tk.documentTitleYourPaymentsPage,
  [PageName.paymentMethodList]: tk.documentTitlePaymentMethodListPage,
  [PageName.paymentMethodDetails]: tk.documentTitlePaymentMethodDetailsPage,
  [PageName.addPaymentMethod]: tk.documentTitleAddPaymentMethodPage,
  [PageName.changePaymentMethod]: tk.documentTitleChangePaymentMethodPage,
  [PageName.bulkPaymentMethod]: tk.documentTitleBulkPaymentMethodPage,
};

interface DocumentTitleProps {
  pageName: PageName;
}

export const DocumentTitle = ({ pageName }: DocumentTitleProps) => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t(pageNameToTitleTk[pageName]);
  }, []);

  return null;
};
