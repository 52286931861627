import React, { useCallback, useEffect } from 'react';
import Squid from './assets/Squid.svg';
import { useTranslation } from 'react-i18next';
import { Text } from '@/components/Text';
import { Group } from '@/components/Group';
import { Container, Image, Button } from './styles';
import { tk } from '@/i18n/translationKeys';
import { trackErrorScreenReloadButtonClickEvent, trackErrorScreenShowedEvent } from '@/analytics';
import { useApplicationData } from '@/providers/applicationData';
import { logger } from '@/logger';
import { useTheme } from 'styled-components';

export const Page500 = (): JSX.Element => {
  const { t } = useTranslation();
  const { userId } = useApplicationData();
  const theme = useTheme();
  
  useEffect(() => {
    logger.error('trackErrorScreenShowedEvent');
    trackErrorScreenShowedEvent({ userId, initialLoad: false });
  }, []);

  const handleReloadButtonClick = useCallback(() => {
    trackErrorScreenReloadButtonClickEvent({ userId, initialLoad: false });
    location.reload();
  }, []);

  return (
    <Container centered vertical gap={8} mobileGap={8} data-testid="error500-page">
      <Image src={Squid} />

      <Group vertical gap={4} mobileGap={4}>
        <Text centered typography="Subheadline" color={theme.colors.text.darkerGrey}>
          {t(tk.errorScreenTitle)}
        </Text>

        <Text centered typography="Paragraph" color={theme.colors.text.darkerGrey}>
          {t(tk.errorScreenText)}
        </Text>
      </Group>

      <Button color="red" onClick={handleReloadButtonClick}>
        <Text typography="SuperTinyHeadline">{t(tk.errorScreenButtonText)}</Text>
      </Button>
    </Container>
  );
};
