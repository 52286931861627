import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  REPLACE_PAYMENT_METHOD_FOR_ALL_CONTRACTS_MUTATION,
  SET_PAYMENT_METHOD_MUTATION,
} from '@/gql/mutations';
import { SonntagButtonV2 } from '@getgrover/ui';
import { Text } from '@/components/Text';
import { Group } from '@/components/Group';
import { Offset } from '@/components/Offset';
import { tk } from '@/i18n/translationKeys';
import { ScreenCommon, ScreenKey } from '../../types';
import { usePaymentMethods } from '@/pages/PaymentMethodListPage/PaymentMethodList/usePaymentMethods';
import { PaymentMethodSelectView } from '@/components/PaymentMethod';
import { Notice } from '@/components/Notice';
import { LoadingUnit } from '@/components/Loading';
import { WarningIcon } from '@/icons/WarningIcon';
import {
  FullWidthGroup,
  Grower,
  ShadowWrapper,
  PaymentsGroup,
} from './PaymentMethodListScreen.styles';
import { useScreenMatch } from '@/hooks/useScreenMatch';
import { useMutation } from '@apollo/client';
import { Height } from '../../ChangePaymentMethodPage.styles';
import { useSearchParams } from 'react-router-dom';
import { useApplicationData } from '@/providers/applicationData';
import { trackChangePaymentMethodSubmitEvent } from '@/analytics';
import { LinkedContract, PaymentMethodWithContracts } from '@/types/paymentMethods';
import { AddPaymentMethodButton } from '../../AddPaymentMethodButton';
import { ReplacePaymentMethodMutation, SetPaymentMethodMutation } from '@/generated/types';
import { QueryParamName } from '@/constants';
import { useTheme } from 'styled-components';

const isTargetContract = (contract: LinkedContract, contractId: string) => {
  if (contract.__typename === 'Flex') {
    return contract.id === contractId;
  }

  if (contract.__typename === 'SubscriptionDetails') {
    return contract.id === contractId;
  }

  return false;
};

const getOldPaymentMethodIdBySubscriptionId = (
  paymentMethods: PaymentMethodWithContracts[],
  contractId: string
) => {
  if (!paymentMethods.length || !contractId) {
    return;
  }

  const oldPaymentMethod = paymentMethods.find((pm) => {
    return pm.linkedContracts.find((contract: LinkedContract) =>
      isTargetContract(contract, contractId)
    );
  });

  return oldPaymentMethod?.id;
};

const getTargetContract = (
  paymentMethods: PaymentMethodWithContracts[],
  contractId: string | null
) => {
  if (!contractId) {
    return;
  }

  const oldPaymentMethod = paymentMethods.find((pm) => {
    return pm.linkedContracts.find((contract: LinkedContract) =>
      isTargetContract(contract, contractId)
    );
  });

  if (!oldPaymentMethod) {
    return;
  }

  return oldPaymentMethod.linkedContracts.find((contract: LinkedContract) =>
    isTargetContract(contract, contractId)
  );
};

export const PaymentMethodListScreen = ({ setScreen, onClose, currentScreen }: ScreenCommon) => {
  const [searchParams] = useSearchParams();
  const { userId, storeCode } = useApplicationData();
  const { t } = useTranslation();
  const { isMobile } = useScreenMatch();
  const { paymentMethods, loading } = usePaymentMethods();
  const deleteMode = searchParams.get(QueryParamName.mode) === 'delete';
  const theme = useTheme();
  // if present it is change payment method flow from your-tech
  const contractId = searchParams.get(QueryParamName.subscriptionId);

  // if present - pm was added during change/bulk
  const newlyAddedPaymentMethodId = searchParams.get(QueryParamName.paymentMethodId);

  const oldPaymentMethodId = contractId
    ? getOldPaymentMethodIdBySubscriptionId(paymentMethods, contractId)
    : currentScreen.data.oldPaymentMethodId;

  const paymentMethodIdToRenderOnTop = newlyAddedPaymentMethodId || oldPaymentMethodId;
  const topPaymentMethods = paymentMethods.filter((pm) => pm.id === paymentMethodIdToRenderOnTop);
  const restPaymentMethods = paymentMethods.filter((pm) => pm.id !== paymentMethodIdToRenderOnTop);
  const allPaymentMethods = [...topPaymentMethods, ...restPaymentMethods];

  const targetContract = getTargetContract(allPaymentMethods, contractId);

  const [replacePaymentMethod] = useMutation<ReplacePaymentMethodMutation>(
    REPLACE_PAYMENT_METHOD_FOR_ALL_CONTRACTS_MUTATION
  );
  const [setPaymentMethod] = useMutation<SetPaymentMethodMutation>(SET_PAYMENT_METHOD_MUTATION);
  const [newPaymentMethodId, setNewPaymentMethodId] = useState(
    newlyAddedPaymentMethodId || oldPaymentMethodId
  );
  const [shadowed, setShadowed] = useState(false);

  const oldPaymentMethod = allPaymentMethods.find((pm) => pm.id === oldPaymentMethodId);
  const newPaymentMethod = allPaymentMethods.find((pm) => pm.id === newPaymentMethodId);
  const numberOfContracts = oldPaymentMethod?.linkedContracts?.length || '';

  useEffect(() => {
    setNewPaymentMethodId(newlyAddedPaymentMethodId || oldPaymentMethodId);
  }, [newlyAddedPaymentMethodId, oldPaymentMethodId]);

  const handleScroll = ({ currentTarget }: React.UIEvent<HTMLDivElement>) => {
    if (currentTarget.scrollTop > 0 && !shadowed) {
      setShadowed(true);
    } else if (currentTarget.scrollTop === 0 && shadowed) {
      setShadowed(false);
    }
  };

  const handleItemClick = (id: string) => {
    setNewPaymentMethodId(id);
  };

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = async () => {
    trackChangePaymentMethodSubmitEvent({
      userId,
      store: storeCode,
      bulk: !contractId,
    });

    if (contractId) {
      // the mutation is async and responds right away
      // polling is implemented in changePaymentMethodLoadingScreen
      const { data } = await setPaymentMethod({
        variables: {
          input: {
            paymentMethodId: newPaymentMethodId,
            contractIds: [contractId],
          },
        },
      });

      const operationId = data?.setPaymentMethod.operation?.id;
      return setScreen(ScreenKey.changePaymentMethodLoadingScreen, {
        newPaymentMethodId,
        targetContract,
        operationId,
        oldPaymentMethod,
      });
    }

    // the mutation is async and responds right away
    // polling is implemented in bulkUpdateLoadingScreen
    const { data } = await replacePaymentMethod({
      variables: {
        input: {
          oldPaymentMethodId,
          newPaymentMethodId,
          deleteOld: deleteMode,
        },
      },
    });

    const operationId = data?.replacePaymentMethod.operation?.id;
    setScreen(ScreenKey.bulkUpdateLoadingScreen, {
      newPaymentMethod,
      deleteMode,
      oldPaymentMethod,
      operationId,
    });
  };

  const paymentMethodList = loading
    ? [1, 2].map((key) => <LoadingUnit key={key} height={15} />)
    : allPaymentMethods.map((pm) => {
        return (
          <PaymentMethodSelectView
            key={pm.id}
            paymentMethod={pm}
            selected={pm.id === newPaymentMethodId}
            onClick={handleItemClick}
          />
        );
      });

  const renderAddButton = () => {
    return (
      <AddPaymentMethodButton
        key="add-pm-button"
        contractId={contractId}
        oldPaymentMethodId={contractId ? undefined : oldPaymentMethodId}
        withDelete={deleteMode}
      />
    );
  };

  const titleTranslationKey = deleteMode ? tk.bulkUpdateDeleteModeTitle : tk.paymentMethodBulkTitle;

  const subtitleTranslationKey = deleteMode
    ? tk.bulkUpdateDeleteModeSubtitle
    : tk.paymentMethodBulkSubtitle;

  const submitButtonTranslationKey = deleteMode
    ? tk.bulkUpdateDeleteModeSubmitButtonText
    : tk.paymentMethodBulkUpdateButtonText;

  return (
    <Height units={140} mobile={isMobile}>
      <ShadowWrapper shadowed={shadowed}>
        <Group vertical gap={2} mobileGap={2}>
          <Text typography="Subheadline" color={theme.colors.text.darkerGrey} data-testid="title">
            {t(titleTranslationKey)}
          </Text>
          {contractId ? null : (
            <Text typography="Paragraph" color={theme.colors.text.darkGrey} data-testid="subtitle">
              {t(subtitleTranslationKey, { numberOfSubs: numberOfContracts || '' })}
            </Text>
          )}
        </Group>
      </ShadowWrapper>

      <Grower onScroll={handleScroll}>
        <PaymentsGroup vertical gap={4} mobileGap={4}>
          {[...paymentMethodList, renderAddButton()]}
        </PaymentsGroup>
      </Grower>

      <Offset top={4}>
        <Group vertical gap={4} mobileGap={4}>
          {contractId ? null : (
            <Notice
              icon={<WarningIcon />}
              status="warn"
              text={t(tk.paymentMethodBulkTwoHoursNoticeText)}
            />
          )}

          <FullWidthGroup gap={4} mobileGap={2}>
            <SonntagButtonV2 fullWidth outlined onClick={handleClose}>
              {t(tk.paymentMethodBulkCancelButtonText)}
            </SonntagButtonV2>

            <SonntagButtonV2
              fullWidth
              onClick={handleSubmit}
              disabled={oldPaymentMethodId === newPaymentMethodId}
            >
              {t(submitButtonTranslationKey)}
            </SonntagButtonV2>
          </FullWidthGroup>
        </Group>
      </Offset>
    </Height>
  );
};
