import { GroverFormData } from './formData';
import { PaymentJSLib } from '@/types/ixopay';
import { mapIntegrationLibError } from './mapIntegrationLibError';

export const generateToken = (formData: GroverFormData, ixopayInstance: PaymentJSLib) =>
  new Promise((resolve, reject) => {
    const [expirationMonth, expirationYear] = formData.cardExpiration.value.split('/');
    const data = {
      card_holder: formData.cardholderName.value,
      month: expirationMonth.trim(),
      year: `20${expirationYear.trim()}`,
    };

    ixopayInstance.tokenize(
      data,
      (token: string) => resolve(token),
      (errors: any) => reject(errors.map(mapIntegrationLibError))
    );
  });
