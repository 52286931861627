import React from 'react';
import { Text } from '@/components/Text';
import { Group } from '@/components/Group';
import { InvalidIcon } from '@/icons/InvalidIcon';
import { useTranslation } from 'react-i18next';
import { tk } from '@/i18n/translationKeys';
import { useTheme } from 'styled-components';

export const InvalidNote = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Group centered gap={1} mobileGap={1}>
      <InvalidIcon />
      <Text
        noWrap
        typography="TinyParagraph"
        color={theme.colors.status.baseRed}
        data-testid="invalid-indicator"
      >
        {t(tk.paymentMethodInvalidNote)}
      </Text>
    </Group>
  );
};
