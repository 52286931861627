import { AxiosError } from 'axios';
import { getUserId, getStore } from '@/utils/cookies';
import { logger } from '@/logger';

export const errorHandlerInterceptor = (error: Error | AxiosError): void => {
  const userId = getUserId();
  const storeCode = getStore();

  logger.error(`(errorHandlerInterceptor): ${error.message}`, {
    userId,
    storeCode,
    error: {
      message: error.message,
    },
  });

  throw error;
};
