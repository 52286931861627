import {
  EventArgs,
  EventHandler,
  EventListenerWithPromiseOptions,
  EventPayload,
  WebViewEventName,
  WebViewEventType,
} from './types';

export function isInWebView(): boolean {
  return Boolean(window.ReactNativeWebView || window.navigator.userAgent.includes('Grover'));
}

export function webViewAPIPush(payload: EventPayload): void {
  if (!window.InWebViewAPI) {
    return;
  }

  window.InWebViewAPI.push(payload);
}

export function addEventListener(name: WebViewEventName, handler: EventHandler): void {
  webViewAPIPush([WebViewEventType.listen, name, handler]);
}

export function removeEventListener(name: WebViewEventName, handler: EventHandler): void {
  webViewAPIPush([WebViewEventType.remove, name, handler]);
}

export function fireEvent(name: WebViewEventName, args?: EventArgs): void {
  webViewAPIPush([WebViewEventType.fire, name, args]);
}

export function addEventListenerWithPromise<T>(
  event: WebViewEventName,
  { timeout }: EventListenerWithPromiseOptions
): Promise<T> {
  return new Promise<{ result: T; eventHandler: (result: T) => void }>((resolve, reject) => {
    let timeoutId = 0;

    function eventHandler(result: T) {
      window.clearTimeout(timeoutId);
      resolve({
        result,
        eventHandler,
      });
    }

    function timeoutHandler() {
      removeEventListener(event, eventHandler);
      reject(new Error(`Waiting period of ${timeout} milliseconds has timed out`));
    }

    addEventListener(event, eventHandler);
    timeoutId = window.setTimeout(timeoutHandler, timeout);
  }).then(({ result, eventHandler }) => {
    removeEventListener(event, eventHandler);

    return result;
  });
}
