import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';

import { tk } from '@/i18n/translationKeys';
import { SUBSCRIPTION_COLLECTION } from '@/gql/queries';
import { useApplicationData } from '@/providers/applicationData';
import {
  UserType,
  PaymentState,
  PaymentEnum,
  SubscriptionListFilterInput,
} from '@/generated/types';
import { FilterTagOption, FilterType } from './types';

interface UseFilterOptionsReturn {
  filterOptions: FilterTagOption[];
  deviceOptions: FilterTagOption[];
  deviceOptionsLoading: boolean;
  // statusOptions: FilterTagOption[];
  // paymentTypeOptions: FilterTagOption[];
}
type UseFilterOptionsResult = (args: {
  filter: SubscriptionListFilterInput;
  first: number;
  searchTerm: string;
}) => UseFilterOptionsReturn;

export const useFilterOptions: UseFilterOptionsResult = ({ filter, first, searchTerm }) => {
  const { t } = useTranslation();
  const { user } = useApplicationData();
  const isUserBusiness = user?.type === UserType.BUSINESS;

  const filterOptions = [
    {
      label: t(tk.yourPaymentsFilterTitleDevice),
      value: FilterType.Device,
    },
    // to unmark once status & paymentType tags finish

    // {
    //   label: t(tk.yourPaymentsListTitleStatus),
    //   value: FilterType.Status,
    // },
    // {
    //   label: t(tk.yourPaymentsFilterTitlePaymentType),
    //   value: FilterType.PaymentType,
    // },
  ];

  const businessFilterOptions = filterOptions.filter(
    (option) => option.value !== FilterType.Device
  );

  // const statusOptions = [
  //   {
  //     label: t(tk.yourPaymentsStatePaid),
  //     value: PaymentState.PAID,
  //   },
  //   {
  //     label: t(tk.yourPaymentsStateScheduled),
  //     value: PaymentState.SCHEDULED,
  //   },
  //   {
  //     label: t(tk.yourPaymentsStateFailed),
  //     value: PaymentState.FAILED,
  //   },
  //   {
  //     label: t(tk.yourPaymentsStateCanceled),
  //     value: 'CANCELED',
  //   },
  // ];

  // const paymentTypeOptions = [
  //   {
  //     label: t(tk.yourPaymentsTypePurchaseText),
  //     value: PaymentEnum.PURCHASE_PAYMENT,
  //   },
  //   {
  //     label: t(tk.yourPaymentsTypeSubscriptionText),
  //     value: PaymentEnum.SUBSCRIPTION_PAYMENT,
  //   },
  //   {
  //     label: t(tk.yourPaymentsTypeOrderText),
  //     value: PaymentEnum.ORDER_PAYMENT,
  //   },
  // ];

  const variables = {
    searchTerm,
    first,
    filter,
  };

  const { data, loading: deviceOptionsLoading } = useQuery(SUBSCRIPTION_COLLECTION, {
    variables,
    skip: isUserBusiness,
    fetchPolicy: 'network-only',
    onError: () => {
      throw Error('subscriptionCollection Error');
    },
  });

  const subscriptionCollection = data?.subscriptionCollection?.nodes ?? [];
  const sortedSubscription = [...subscriptionCollection].sort(
    (a, b) => new Date(b.product.createdAt).getTime() - new Date(a.product.createdAt).getTime()
  );

  // To filter out duplicate name
  const filteredSubscription = sortedSubscription.filter(
    (item, index, self) => index === self.findIndex((s) => s.name === item.name)
  );

  const deviceOptions = filteredSubscription.map((subscription) => ({
    label: subscription.name,
    value: subscription.id,
  }));

  return {
    filterOptions: isUserBusiness ? businessFilterOptions : filterOptions,
    deviceOptions,
    deviceOptionsLoading,
    // statusOptions,
    // paymentTypeOptions,
  };
};
