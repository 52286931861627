import { DefaultTheme } from 'styled-components';
import { lightBaseTheme } from '@getgrover/ui';
import { ThemeBreakpoints, HeaderScreenSizes, ScreenSizes } from './types';

export const headerUIScreenSizes: HeaderScreenSizes = {
  phone: 'min-width: 0px',
  averagePhone: 'min-width: 321px',
  largePhone: 'min-width: 425px',
  tablet: 'min-width: 768px',
  desktop: 'min-width: 1024px',
  largeDesktop: 'min-width: 1280px',
  wideScreen: 'min-width: 1600px',
  wideDesktop: 'min-width: 1440px',
  extraWideScreen: 'min-width: 1728px',
  extraWideDesktop: 'min-width: 2160px',
};

const sizes = {
  mobileMin: '0px',
  mobileMax: '767px',
  tabletMin: '768px',
  tabletMax: '1023px',
  desktopMin: '1024px',
  desktopMax: '1279px',
  largeDesktopMin: '1280px',
  largeDesktopMax: '1599px',
  widescreenMin: '1600px',
};

export const screenSizes: ScreenSizes = {
  mobile: `(min-width: ${sizes.mobileMin}) and (max-width: ${sizes.mobileMax})`,
  mobileMax: `(max-width: ${sizes.mobileMax})`,
  tablet: `(min-width: ${sizes.tabletMin}) and (max-width: ${sizes.tabletMax})`,
  tabletMax: `(max-width: ${sizes.tabletMax})`,
  desktop: `(min-width: ${sizes.desktopMin}) and (max-width: ${sizes.desktopMax})`,
  desktopMax: `(max-width: ${sizes.desktopMax})`,
  largeDesktop: `(min-width: ${sizes.largeDesktopMin}) and (max-width: ${sizes.largeDesktopMax})`,
  widescreenMin: `(min-width: ${sizes.widescreenMin})`,
};

const breakpoints: ThemeBreakpoints = {
  mobile: {
    min: 0,
    max: 767,
  },
  tablet: {
    min: 768,
    max: 1023,
  },
  desktop: {
    min: 1024,
    max: 1279,
  },
  largeDesktop: {
    min: 1280,
    max: 1599,
  },
  widescreen: {
    min: 1600,
  },
};

const lightTheme: DefaultTheme = {
  ...lightBaseTheme,
  breakpoints,
  screens: headerUIScreenSizes,
  screenSizes,
};

export { lightTheme };
