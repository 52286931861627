import styled, { css } from 'styled-components';

const LoadingUnitBase = css`
  background: ${(props) => props.theme.colors.hover.lighterGrey};
  position: relative;
  overflow: hidden;

  &:last-child {
    margin-right: 0;
  }

  &:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background: linear-gradient(
      90deg,
      transparent 40%,
      rgba(255, 255, 255, 0.3) 65%,
      transparent 90%,
      transparent
    );
    animation: shimmer 2s infinite;
    content: '';
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
`;

export const LoadingUnit = styled.div<{ height: number; width?: number; borderRadius?: string }>`
  width: ${({ width }) => (width ? `${width * 4}px` : '100%')};
  height: ${({ height }) => `${height * 4}px`};
  border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : '8px')};

  ${LoadingUnitBase}
`;
