import styled from 'styled-components';

export const EmptyScreenRoot = styled.div<{
  maxWidth?: string;
}>`
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : '380px')};
  align-self: center;
  text-align: center;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
