import React from 'react';

import { Group } from '@/components/Group';
import { LoadingUnit } from '@/components/Loading';
import { Wrapper } from '@/components/Wrapper';
import { useScreenMatch } from '@/hooks/useScreenMatch';

import {
  PaymentCardRoot,
  IconCircle,
  Content,
  PaymentMobileCard,
} from './PaymentCard.styles';

export const PaymentCardPreloader = () => {
  const { isMobile } = useScreenMatch();

  return (
    <PaymentCardRoot
      isMobile={isMobile}
      loading
    >
      {isMobile ? (
        <PaymentMobileCard>
          <Wrapper justifyContent="space-between" alignItems="flex-start" margin="0 0 8px 0">
            <Group vertical mobileGap={2}>
              <LoadingUnit height={5} width={51} />
              <LoadingUnit height={5} width={51} />
            </Group>
            <LoadingUnit height={7} width={12} />
          </Wrapper>

          <Wrapper justifyContent="space-between">
            <LoadingUnit height={3} width={20} />
            <LoadingUnit height={3} width={12} />
          </Wrapper>
        </PaymentMobileCard>
      ) : (
        <>
          <IconCircle>
            <LoadingUnit height={12} borderRadius='50%' />
          </IconCircle>

          <Content>
            <Group vertical gap={2}>
              <LoadingUnit height={6} width={85} />
              <LoadingUnit height={3} width={42} />
            </Group>
          </Content>

          <Wrapper width="120px" justifyContent="end">
            <LoadingUnit height={7} width={12} />
          </Wrapper>

          <Wrapper width="212px" justifyContent="end">
            <LoadingUnit height={3} width={20} />
          </Wrapper>

          <Wrapper width="96px" justifyContent="end" padding='0 12px 0 0'>
            <LoadingUnit height={6} width={6} borderRadius='50%' />
          </Wrapper>
        </>
      )}
    </PaymentCardRoot>
  );
};
