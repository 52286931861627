import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    body {
        width: 100vw;
        height: 100vh;
        background: ${({ theme }): string => theme.colors.background.white};
    }
`;

export default GlobalStyle;
