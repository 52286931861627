import React from 'react';

export const BrandMastercardIcon = ({ width = 36 }: { width: number }) => {
  return (
    <svg
      width={width}
      height="36"
      viewBox="0 0 58 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="18" cy="18" r="18" transform="matrix(1 0 0 -1 22 36)" fill="#EAA33F" />
      <circle cx="18" cy="18" r="18" transform="matrix(1 0 0 -1 0 36)" fill="#D82F2C" />
    </svg>
  );
};
