import styled from 'styled-components';
import { Text } from '@/components/Text';

export const SubscriptionItemRoot = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border-radius: 8px;
  padding: 8px 4px;
  transition: background-color 0.15s ease-in-out;

  &:hover {
    background-color: ${({ theme }) => theme.colors.hover.lighterGrey};
  }
`;

export const LeftWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  overflow: hidden;
`;

export const CenterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  overflow: hidden;
`;

export const IconWrapper = styled.div`
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  border-radius: 24px;
  background: ${({ theme }) => theme.colors.hover.lighterGrey};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`;

export const Image = styled.div<{ src: string }>`
  background-image: ${({ src }) => `url("${src}")`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  display: block;
  margin-right: 16px;
  width: 48px;
  height: 48px;
  min-width: 48px;
`;

export const EllipsisText = styled(Text)`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const PriceText = styled(Text)`
  margin-left: 8px;
  min-width: 72px;
  text-align: end;
`;

export const ChevronIconWrapper = styled.div`
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`;
