import styled from 'styled-components';

export const TopPaneRoot = styled.div`
  top: 300px;
  width: 396px;

  @media ${({ theme }) => theme.screenSizes.tabletMax} {
    width: 100%;
    margin-bottom: 24px;
  }
`;

export const ButtonContent = styled.div`
  width: 229px;
  height: 32px;
  display: flex;
  align-items: center;
    span{
      width: 180px;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-left: 16px;
    }
`;
