import styled from 'styled-components';
import { Text } from '../Text';

export const Line = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2px;
  width: 100%;
  background: ${({ theme }) => theme.colors.stroke.lighterGrey};
  margin: 12px 0 28px 0;
`;

export const TextOverlay = styled(Text)`
  padding: 4px 8px;
  background: ${({ theme }) => theme.colors.background.white};
`;