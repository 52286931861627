export enum GroverFieldName {
  cardholderName = 'cardholderName',
  cardExpiration = 'cardExpiration'
}

export enum IxopayFieldName {
  cardNumber = 'cardNumber',
  cardCVV = 'cardCVV'
}

export interface GroverFormField {
  value: string;
  error: string | null;
}

export type GroverFormData = Record<GroverFieldName, GroverFormField>;
export type IxopayFormErrorsData = Record<IxopayFieldName, string | null>;


export const initialFormData: GroverFormData = {
  [GroverFieldName.cardholderName]: {
    error: null, 
    value: '',
  },
  [GroverFieldName.cardExpiration]: {
    error: null, 
    value: '',
  },
};

export const initialIxopayFormErrorsData: IxopayFormErrorsData = {
  [IxopayFieldName.cardNumber]: null,
  [IxopayFieldName.cardCVV]: null,
};

export interface IxoPayEventData {
  cardType: 'mastercard' | 'visa' | 'unknown';
  cvvLength: number;
  firstSix: string;
  numberLength: number;
  subBrand: null | string;
  validCvv: boolean;
  validNumber: boolean;
}

export const initialIxopayData: IxoPayEventData = {
  cardType: 'unknown',
  cvvLength: 0,
  firstSix: '',
  numberLength: 0,
  subBrand: null,
  validCvv: false,
  validNumber: false,
};
