import React from 'react';
import { useTheme } from 'styled-components';

export const ErrorIcon = ({ color }: { color?: string }) => {
  const theme = useTheme();
  const defaultColor = theme.colors.text.darkerGrey;

  return (
    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5308 1.5C11.3761 -0.5 8.4893 -0.5 7.3346 1.5L0.406399 13.5C-0.748301 15.5 0.695075 18 3.00448 18H16.8609C19.1703 18 20.6137 15.5 19.459 13.5L12.5308 1.5ZM9.06665 2.5C9.45155 1.83333 10.4138 1.83333 10.7987 2.5L17.7269 14.5C18.1118 15.1667 17.6307 16 16.8609 16H3.00448C2.23468 16 1.75355 15.1667 2.13845 14.5L9.06665 2.5ZM9.93268 11C10.485 11 10.9327 10.5523 10.9327 10V6C10.9327 5.44771 10.485 5 9.93268 5C9.38039 5 8.93268 5.44771 8.93268 6V10C8.93268 10.5523 9.38039 11 9.93268 11ZM10.9327 13C10.9327 12.4477 10.485 12 9.93268 12C9.38039 12 8.93268 12.4477 8.93268 13C8.93268 13.5523 9.38039 14 9.93268 14C10.485 14 10.9327 13.5523 10.9327 13Z"
        fill={color || defaultColor}
      />
    </svg>
  );
};
