import React from 'react';
import { useTheme } from 'styled-components';

export const BrandUnknownIcon = ({ width = 36 }: { width: number }) => {
  const theme = useTheme();

  return (
    <svg
      width={width}
      height="22"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 2C0 0.895431 0.89543 0 2 0H18C19.1046 0 20 0.895432 20 2V14C20 15.1046 19.1046 16 18 16H2C0.895429 16 0 15.1046 0 14V2ZM18 2H2V9H18V2ZM18 11H2V14L18 14V11ZM16 6C16 6.55228 15.5523 7 15 7H13C12.4477 7 12 6.55228 12 6V5C12 4.44772 12.4477 4 13 4H15C15.5523 4 16 4.44772 16 5V6Z"
        fill={theme.colors.text.darkerGrey}
      />
    </svg>
  );
};
