export enum FeatureFlagName {
  NEW_CARD_FORM = 'pmmp_new_card_form',
  FILTER_YOUR_PAYMENTS = 'pmmp_filter_your_payments',
}

export const flagKeys: FeatureFlagName[] = [
  FeatureFlagName.NEW_CARD_FORM,
  FeatureFlagName.FILTER_YOUR_PAYMENTS,
];

export const defaultFlagData = flagKeys.reduce((acc, item) => {
  return {
    ...acc,
    [item]: false,
  };
}, {});
