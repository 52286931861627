import { gql } from '@apollo/client';

export const SUBSCRIPTION_COLLECTION = gql`
  query SubscriptionCollection(
    $filter: SubscriptionListFilterInput!
    $first: Int
    $searchTerm: String
  ) {
    subscriptionCollection(filter: $filter, first: $first, searchTerm: $searchTerm) {
      totalCount
      nodes {
        ... on SubscriptionCard {
          id
          name
          state
          product {
            createdAt
          }
        }
      }
    }
  }
`;
