import styled from 'styled-components';

export const InputWrapper = styled.div<{ hasError: boolean; focused: boolean; disabled: boolean; }>`
  height: 56px;
  border-radius: 8px;
  font-size: 16px;
  width: 100%;
  background: ${({ theme, disabled }) => { 
    if (disabled) return theme.colors.background.superLightGrey;
    return theme.colors.background.white;
  }};
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: text;
  position: relative;
  box-sizing: border-box;
  outline-style: solid;
  outline-color: ${({ theme, hasError, focused, disabled }) => {
    if (disabled) return theme.colors.background.superLightGrey;
    if (hasError) return theme.colors.status.darkRed;
    if (focused) return theme.colors.stroke.darkerGrey;
    return theme.colors.stroke.baseGrey;
  }};
  outline-width: ${({ focused }) => {
    if (focused) return '2px';
    return '1px';
  }};
  overflow: hidden;
  input {
    padding-top: 20px;
    padding-left: 20px;
    box-sizing: border-box;
    height: 100%;
    border-radius: 8px;
    outline: none;
    font-size: 16px;

    :disabled {
      background: ${({ theme }) => theme.colors.background.superLightGrey};
      color: ${({ theme }) => theme.colors.text.baseGrey};
    }
  }
`;

export const Label = styled.label<{ shifted: boolean; htmlFor: string; }>`
  position: absolute;
  top: ${({ shifted }) => (shifted ? '6px' : '18px')};
  left: 20px;
  font-size: ${({ shifted }) => (shifted ? '14px' : '16px')};
  line-height: 16px;
  transition: all .15s ease-in-out;
  color: ${({ theme }) => theme.colors.text.darkGrey};
  z-index: 3;
  pointer-events: none;
`;