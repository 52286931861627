import styled from 'styled-components';

export const ProviderItemRoot = styled.div<{ withNotice?: boolean, expanded?: boolean }>`
  border: 2px solid ${({ theme }) => theme.colors.stroke.lighterGrey};
  border-radius: 8px;
  padding: 16px;
  max-height: ${(props) => {
    if (props.expanded) return '620px';
    if (props.withNotice) return 'unset';
    return '60px';
  }};
  width: 100%;
  transition: max-height .15s ease-in-out;
  overflow: hidden;
  position: relative;
`;

export const PrimaryContainer = styled.div<{ expanded?: boolean }>`
  width: 100%;
  cursor: ${({ expanded }) => expanded ? 'default' : 'pointer'};
`;

export const IconWrapper = styled.div`
  position: absolute;
  top: 16px;
  right: 8px;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;