import React from 'react';

export const DeleteIcon = () => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      xmlns="http://www.w3.org/2000/svg"
      fill="inherit"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M6 8H8L8 16H6L6 8Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M10 16V8H12V16H10Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 4H18V6H16V17C16 18.6569 14.6569 20 13 20H5C3.34315 20 2 18.6569 2 17V6H0V4H5V3C5 1.34315 6.34315 0 8 0H10C11.6569 0 13 1.34315 13 3V4ZM8 2C7.44772 2 7 2.44772 7 3V4H11V3C11 2.44772 10.5523 2 10 2H8ZM14 6H4V17C4 17.5523 4.44772 18 5 18H13C13.5523 18 14 17.5523 14 17V6Z"
      />
    </svg>
  );
};
