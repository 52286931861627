import styled from 'styled-components';

export interface WrapperRootProps {
  width?: string;
  maxWidth?: string;
  height?: string;
  justifyContent?: string;
  alignItems?: string;
  margin?: string;
  padding?: string;
}

export const Root = styled.div<WrapperRootProps>`
  display: flex;
  width: ${({ width }) => (width)};
  max-width: ${({ maxWidth }) => (maxWidth)};
  height: ${({ height }) => (height)};
  justify-content: ${({ justifyContent }) => (justifyContent)};
  align-items: ${({ alignItems }) => (alignItems ? alignItems : 'center')};
  margin: ${({ margin }) => (margin)};
  padding: ${({ padding }) => (padding)};
`;
