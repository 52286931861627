import { AxiosResponse } from 'axios';
import { PaymentHeaders } from './headers';
import { logger } from '@/logger';

function responseInterceptor(response: AxiosResponse): AxiosResponse {
  const { config: requestConfig, status, statusText } = response;
  const { headers: requestHeaders, url } = requestConfig;

  logger.info(`Received response from ${url}`, {
    status,
    statusText,
    ...(requestHeaders &&
      requestHeaders[PaymentHeaders.DEVICE_ID] && {
        deviceId: requestHeaders[PaymentHeaders.DEVICE_ID] as string,
      }),
    ...(requestHeaders &&
      requestHeaders[PaymentHeaders.STORE_CODE] && {
        storeCode: requestHeaders[PaymentHeaders.STORE_CODE] as string,
      }),
    ...(requestHeaders &&
      requestHeaders[PaymentHeaders.LOCALE] && {
        locale: requestHeaders[PaymentHeaders.LOCALE],
      }),
    ...(requestHeaders &&
      requestHeaders[PaymentHeaders.USER_AGENT] && {
        userAgent: requestHeaders[PaymentHeaders.USER_AGENT],
      }),
  });

  return response;
}

export { responseInterceptor };
