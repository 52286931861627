import React, { useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { tk } from '@/i18n/translationKeys';
import { trackYourPaymentsPageVisitedEvent } from '@/analytics';
import { useScreenMatch } from '@/hooks/useScreenMatch';
import { useQueryParams } from '@/hooks/useQueryParams';
import { useApplicationData } from '@/providers/applicationData';
import { getLocale } from '@/utils/cookies';
import { EmptyScreen } from '@/components/EmptyScreen';
import { DisplayIf } from '@/components/DisplayIf';
import { Page } from '@/components/Page';
import { DocumentTitle, PageName } from '@/components/DocumentTitle';
import { Text } from '@/components/Text';
import { Wrapper } from '@/components/Wrapper';
import { ArrowLeftIcon } from '@/icons/ArrowLeftIcon';
import { PaymentState, PaymentEnum } from '@/generated/types';
import { DEFAULT_LOCALE } from './constants';
import { TopPane } from './TopPane';
import { LinkToPaymentMethods } from './LinkToPaymentMethods';
import { FilterPane } from './FilterPane';
import { PaymentsList } from './PaymentsList';
import { usePaymentsList } from './PaymentsList/usePaymentsList';

import { Root, BackButton } from './YourPaymentsPage.styles';

export const YourPaymentsPage = (): JSX.Element => {
  const { subscriptionId, device, status, paymentType } = useQueryParams();
  const { t } = useTranslation();
  const { isMobile } = useScreenMatch();
  const { activeStore, storeCode, flags } = useApplicationData();
  const navigate = useNavigate();
  const locale = getLocale() || DEFAULT_LOCALE;
  const theme = useTheme();
  const queryParamsFilterValues = [device, status, paymentType];

  const isFilterEmptyResult =
    (status === PaymentState.FAILED && paymentType === PaymentEnum.ORDER_PAYMENT) ||
    (status === 'CANCELED' && paymentType && paymentType !== PaymentEnum.ORDER_PAYMENT) ||
    (status === 'CANCELED' && device);

  const paymentsListResult = usePaymentsList({
    subscriptionId,
    device,
    status,
    paymentType,
    isFilterEmptyResult: !!isFilterEmptyResult,
  });

  const filterValueCount = queryParamsFilterValues.filter(
    (value) => Boolean(value)
  ).length;

  useEffect(() => {
    trackYourPaymentsPageVisitedEvent({
      path: location.pathname,
      store: storeCode,
      store_id: activeStore.store_id,
      locale,
    });
  }, []);

  const handleBackClick = useCallback(() => {
    navigate(-1); // Go back one step in history
  }, []);

  return (
    <Page>
      <DocumentTitle pageName={PageName.yourPayments} />
      <Root isMobile={isMobile}>
        <DisplayIf desktop>
          <Wrapper height="64px" justifyContent="space-between" margin="16px 0">
            <Wrapper>
              <Text
                typography="Subheadline"
                color={theme.colors.text.darkerGrey}
                data-testid="your-payments-page-main-title"
              >
                {t(tk.yourPaymentsPageMainTitle)}
              </Text>
            </Wrapper>

            <LinkToPaymentMethods />
          </Wrapper>
        </DisplayIf>

        <DisplayIf mobile>
          <Wrapper height="64px" justifyContent="space-between" margin="0 0 8px">
            <Wrapper>
              <BackButton onClick={handleBackClick}>
                <ArrowLeftIcon />
              </BackButton>

              <Text
                typography="SuperTinyHeadline"
                color={theme.colors.text.darkerGrey}
                data-testid="your-payments-page-main-title"
              >
                {t(tk.yourPaymentsPageMainTitle)}
              </Text>
            </Wrapper>

            <LinkToPaymentMethods />
          </Wrapper>
        </DisplayIf>

        <TopPane />

        {flags.pmmp_filter_your_payments ? (
          <DisplayIf desktop>
            <FilterPane
              filterValueCount={filterValueCount}
            />
          </DisplayIf>
        ) : null}

        {isFilterEmptyResult ? (
          <EmptyScreen
            title={t(tk.yourPaymentsEmptyStateTitle)}
            subtitle={t(tk.yourPaymentsEmptyStateText)}
            maxWidth={isMobile ? '380px' : 'unset'}
          />
        ) : (
          <PaymentsList paymentsListResult={paymentsListResult} />
        )}
      </Root>
    </Page>
  );
};
