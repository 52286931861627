import React, { Component, ErrorInfo, ReactNode } from 'react';
import { logger } from '@/logger';
import { StyledPage500 } from './ErrorBoundary.styles';

export interface ErrorBoundaryProps {
  children: ReactNode;
  hasButton?: boolean;
}

type ErrorBoundaryState = {
  hasError: boolean;
};

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    logger.error('ErrorBoundary', {
      statusText: errorInfo?.componentStack,
      message: error?.message,
    });

    this.setState({
      hasError: true,
    });
  }

  render(): JSX.Element {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return <StyledPage500 />;
    }

    return <>{children}</>;
  }
}
