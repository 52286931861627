import {
  ApolloLink,
  FetchResult,
  fromPromise,
  NextLink,
  Observable,
  Operation,
} from '@apollo/client';
import { GraphQLErrors, NetworkError } from '@apollo/client/errors';
import { ErrorLink } from '@apollo/client/link/error';
import { GraphQLError } from 'graphql';
import { getAccessToken } from '@/utils/cookies';
import { refreshAccessToken, forceLogout } from '../auth';

type ObservableAccessTokenRefresmentParameters = {
  graphQLErrors?: GraphQLErrors;
  networkError?: NetworkError;
  operation: Operation;
  forward: NextLink;
};

function isUnauthenticatedError(error: GraphQLError) {
  return error?.extensions?.code === 'UNAUTHENTICATED';
}

function handleObservableAccessTokenRefreshment({
  operation,
  forward,
}: ObservableAccessTokenRefresmentParameters): Observable<
  FetchResult<Record<string, any>, Record<string, any>, Record<string, any>>
> {
  const { headers } = operation.getContext();

  const refreshTokenObservable = fromPromise(
    refreshAccessToken().catch((error) => {
      forceLogout();
      throw error;
    })
  ).flatMap(() => {
    const refreshedAccessToken = getAccessToken();

    operation.setContext({
      headers: {
        ...headers,
        authorization: `Bearer ${refreshedAccessToken}`,
      },
    });

    return forward(operation);
  });

  return refreshTokenObservable;
}

const ErrorHandlingMiddleware = new ErrorLink(
  ({ graphQLErrors, networkError, operation, forward }) => {
    const serverError = networkError as any;
    const hasNetworkUnauthenticatedError = serverError?.statusCode === 401;
    const hasGraphqlUnauthenticatedError = graphQLErrors?.some(isUnauthenticatedError);
    const hasUnauthenticatedErrorInResponse =
      serverError?.result?.errors?.some(isUnauthenticatedError);

    if (
      hasNetworkUnauthenticatedError ||
      hasGraphqlUnauthenticatedError ||
      hasUnauthenticatedErrorInResponse
    ) {
      return handleObservableAccessTokenRefreshment({
        operation,
        forward,
        graphQLErrors,
        networkError,
      });
    }

    return undefined;
  }
);

const BeforeQueryMiddleware = new ApolloLink((operation, forward) => {
  const accessToken = getAccessToken();

  if (!accessToken) {
    return handleObservableAccessTokenRefreshment({ operation, forward });
  }

  return forward(operation);
});

export const refreshTokenLink = ApolloLink.from([BeforeQueryMiddleware, ErrorHandlingMiddleware]);
