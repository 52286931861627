interface ExpirableItem {
  value: any;
  expiry: number;
}

export function setItemWithExpiry(key: string, value: any, expiryInSeconds: number): void {
  const now = new Date();
  const item: ExpirableItem = {
    value: value,
    expiry: now.getTime() + expiryInSeconds * 1000,
  };
  localStorage.setItem(key, JSON.stringify(item));
}

export function getItemWithExpiry(key: string): any {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) {
    return null;
  }
  const item: ExpirableItem = JSON.parse(itemStr);
  const now = new Date();
  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
}
