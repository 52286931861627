import { sendAnalyticsEvent } from '@devsbb/analytics-client';

type EventProperties = {
  userId: string;
  store: string;
};

export const trackPaymentMethodListYourProfileLinkClickEvent = (properties: EventProperties) => {
  sendAnalyticsEvent<EventProperties>('Your Profile Link Clicked', properties);
};

export const trackPaymentMethodListPageAddButtonClickEvent = (properties: EventProperties) => {
  sendAnalyticsEvent<EventProperties>('Add Button Clicked', properties);
};

export const trackPaymentMethodListPageItemClickEvent = (properties: EventProperties) => {
  sendAnalyticsEvent<EventProperties>('Payment Method Item Clicked', properties);
};
