import { TFn } from '@/pages/YourPaymentsPage/types';
import { CREDIT_CARD_MASK_SYMBOL } from './CreditCardForm';
import { GroverFieldName } from './formData';
import { tk } from '@/i18n/translationKeys';

export const validateGroverField = (name: GroverFieldName, value: string, t: TFn) => {
  switch (name) {
    case GroverFieldName.cardholderName:
      return validateCardholderName(value, t);
    case GroverFieldName.cardExpiration:
      return validateCardExpiration(value, t);
  }
};

const validateCardholderName = (value: string, t: TFn) => {
  if (!value) {
    return t(tk.addPaymentMethodCardholderNameInvalidText);
  }

  const regex = /^[\p{L}\s'-]{2,50}$/u;
  if (!regex.test(value)) {
    return t(tk.addPaymentMethodCardholderNameInvalidText);
  }

  return null;
};

const validateCardExpiration = (value: string, t: TFn) => {
  if (value.length !== 5 || value.includes(CREDIT_CARD_MASK_SYMBOL)) {
    return t(tk.addPaymentMethodCardExpiryDateInvalidText);
  }

  const [month, year] = value.split('/').map(Number);

  if (month < 1 || month > 12) {
    return t(tk.addPaymentMethodCardExpiryDateInvalidText);
  }

  const now = new Date();
  const currentYear = now.getFullYear() % 100; // Last two digits of the year
  const currentMonth = now.getMonth() + 1; // JavaScript months are 0-based

  if (year < currentYear || (year === currentYear && month < currentMonth)) {
    return t(tk.addPaymentMethodCardExpiryDateInvalidText);
  }

  return null;
};