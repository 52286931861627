import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Markdown from 'markdown-to-jsx';
import { Text } from '@/components/Text';
import { Group } from '@/components/Group';
import { tk } from '@/i18n/translationKeys';
import { AddPaymentMethodOtherError, ScreenCommon, ScreenKey } from '../../types';
import { ProviderItem } from './ProviderItem';
import {
  Height,
  ExternalLink,
  TitleWrapper,
  NoticeText,
  Hidden,
} from '@/pages/AddPaymentMethodPage/AddPaymentMethodPage.styles';
import { useApplicationData } from '@/providers/applicationData';
import { useScreenMatch } from '@/hooks/useScreenMatch';
import { PaymentOptionType, TransactionErrorCode } from '@/generated/types';
import {
  trackAddPaymentMethodPaypalOptionClickEvent,
  trackAddPaymentMethodSepaOptionClickEvent,
} from '@/analytics';
import { LoadingUnit } from '@/components/Loading';
import { Notice } from '@/components/Notice';
import { Offset } from '@/components/Offset';
import { InfoIcon } from '@/icons/InfoIcon';
import { useTheme } from 'styled-components';
import { CreditCardForm } from './CreditCardForm';
import { Separator } from '@/components/Separator';
import { PaymentMethodBrand } from '@/components/PaymentMethod/PaymentMethodBrand';
import { PaymentMethodDisplayType } from '@/utils/paymentMethods';

export const PaymentMethodOptionsScreen = ({ setScreen, paymentOptions }: ScreenCommon) => {
  const { t } = useTranslation();
  const { isMobile } = useScreenMatch();
  const { storeCode, userId, platform, rawUseragent } = useApplicationData();
  const theme = useTheme();
  const [creditCardFormReady, setCreditCardFormReady] = useState<boolean>(false);

  const handleCreditCardFormReady = () => {
    setCreditCardFormReady(true);
  };

  const handlePaypalClick = () => {
    setScreen(ScreenKey.paypalRedirection, {});
    trackAddPaymentMethodPaypalOptionClickEvent({
      userId,
      store: storeCode,
      legacy: false,
      platform,
      rawUseragent,
    });
  };

  const handleSepaClick = () => {
    setScreen(ScreenKey.sepaRedirection, {});
    trackAddPaymentMethodSepaOptionClickEvent({
      userId: userId,
      store: storeCode,
      legacy: false,
      platform,
      rawUseragent,
    });
  };

  const handleAddCardError = (
    customErrorCode: TransactionErrorCode | AddPaymentMethodOtherError
  ) => {
    setScreen(ScreenKey.addPaymentMethodError, { customErrorCode });
  };

  const renderNotice = () => (
    <Offset top={3}>
      <Notice
        icon={<InfoIcon />}
        text={
          <Markdown
            options={{
              overrides: {
                Text: {
                  component: NoticeText,
                },
                a: {
                  component: ExternalLink,
                },
              },
            }}
          >
            {t(tk.paymentMethodSepaNoticeText)}
          </Markdown>
        }
      />
    </Offset>
  );

  const renderContent = () => {
    if (!paymentOptions.length) {
      return (
        <Group vertical gap={4} mobileGap={4}>
          {[1, 2].map((key) => (
            <LoadingUnit key={key} height={15} />
          ))}
        </Group>
      );
    }

    return (
      <div>
        {creditCardFormReady ? null : (
          <Group vertical gap={4} mobileGap={4}>
            {[1, 2].map((key) => (
              <LoadingUnit key={key} height={15} />
            ))}
          </Group>
        )}
        <Hidden visible={creditCardFormReady}>
          <Group vertical gap={4} mobileGap={4}>
            {paymentOptions.some((item) => item.type === PaymentOptionType.BANKCARD) ? (
              <ProviderItem expanded>
                <div>
                  <Group gap={2} mobileGap={2} centered>
                    <PaymentMethodBrand
                      outlined
                      iconKey={PaymentMethodDisplayType.mastercard}
                      size="normal"
                    />
                    <PaymentMethodBrand
                      outlined
                      iconKey={PaymentMethodDisplayType.visa}
                      size="normal"
                    />
                    <PaymentMethodBrand
                      iconKey={PaymentMethodDisplayType.americanExpress}
                      size="normal"
                    />
                    <Text typography="Paragraph" color={theme.colors.text.darkerGrey}>
                      {t(tk.paymentMethodCardTitle)}
                    </Text>
                  </Group>

                  <CreditCardForm
                    onFormReady={handleCreditCardFormReady}
                    paymentOptions={paymentOptions}
                    onError={handleAddCardError}
                  />
                </div>
              </ProviderItem>
            ) : null}

            <Separator text={t(tk.optionsScreenSeparatorText)} />

            {paymentOptions.some((item) => item.type === PaymentOptionType.SEPA) ? (
              <ProviderItem onClick={handleSepaClick} notice={renderNotice()}>
                <Group gap={2} mobileGap={2}>
                  <PaymentMethodBrand
                    outlined
                    iconKey={PaymentMethodDisplayType.sepa}
                    size="normal"
                  />
                  <Text typography="Paragraph" color={theme.colors.text.darkerGrey}>
                    {t(tk.paymentMethodSepaTitle)}
                  </Text>
                </Group>
              </ProviderItem>
            ) : null}

            {paymentOptions.some((item) => item.type === PaymentOptionType.PAYPAL) ? (
              <ProviderItem onClick={handlePaypalClick}>
                <Group gap={2} mobileGap={2}>
                  <PaymentMethodBrand
                    outlined
                    iconKey={PaymentMethodDisplayType.paypal}
                    size="normal"
                  />
                  <Text typography="Paragraph" color={theme.colors.text.darkerGrey}>
                    {t(tk.paymentMethodPaypalTitle)}
                  </Text>
                </Group>
              </ProviderItem>
            ) : null}
          </Group>
        </Hidden>
      </div>
    );
  };

  return (
    <Height mobile={isMobile}>
      <Group vertical gap={6} mobileGap={6}>
        <TitleWrapper>
          <Text typography="Subheadline" color={theme.colors.text.darkerGrey}>
            {t(tk.optionsScreenTitle)}
          </Text>
        </TitleWrapper>

        {renderContent()}
      </Group>
    </Height>
  );
};
