import { PaymentMethod } from '@/generated/types';
import { tk } from '@/i18n/translationKeys';
import { TFn } from '@/pages/YourPaymentsPage/types';
import { PaymentMethodWithContracts } from '@/types/paymentMethods';

export const isByInvoicePaymentMethod = (paymentMethod: Partial<PaymentMethod>): boolean => {
  return paymentMethod?.__typename === 'ByInvoice';
};

export enum PaymentMethodDisplayType {
  visa,
  mastercard,
  americanExpress,
  sepa,
  paypal,
  byInvoice,
  unknown,
}

export const getPaymentMethodDisplayType = (
  paymentMethod: PaymentMethodWithContracts
): PaymentMethodDisplayType => {
  if (paymentMethod.__typename === 'CardPaymentMethod') {
    switch (paymentMethod.brand) {
      case 'VISA':
      case 'visa':
        return PaymentMethodDisplayType.visa;
      case 'mastercard':
      case 'MASTERCARD':
      case 'MASTER_CARD':
        return PaymentMethodDisplayType.mastercard;
      case 'amex':
      case 'AMEX':
        return PaymentMethodDisplayType.americanExpress;
    }

    return PaymentMethodDisplayType.unknown;
  }

  if (paymentMethod.__typename === 'PaypalPaymentMethod') {
    return PaymentMethodDisplayType.paypal;
  }

  if (paymentMethod.__typename === 'SepaPaymentMethod') {
    return PaymentMethodDisplayType.sepa;
  }

  if (paymentMethod.__typename === 'ByInvoicePaymentMethod') {
    return PaymentMethodDisplayType.byInvoice;
  }

  return PaymentMethodDisplayType.unknown;
};

const getPlugs = (numberLength: number, cardNumber: string): string => {
  let result = cardNumber ? '' : '···· ···· ···· ····';

  const breaks = [4, 8, 12];
  for (let i = 0; i < numberLength; i++) {
    if (breaks.includes(i)) {
      result = result + ' ·';
    } else {
      result = result + '·';
    }
  }

  return result;
};

export const getPrimaryText = ({
  paymentMethod,
  singleView,
  editingView,
  numberLength,
  cardNumber,
  t,
} : {
  paymentMethod: PaymentMethodWithContracts,
  singleView?: boolean,
  editingView?: boolean,
  numberLength?: number,
  cardNumber?: string,
  t?: TFn,
}): string => {
  switch (paymentMethod.__typename) {
    case 'CardPaymentMethod': {
      if (editingView) {
        return getPlugs(numberLength as number, cardNumber as string);
      }
      return `${singleView ? '···· ···· ' : ''}···· ${paymentMethod.lastFourDigits}`;
    }
    case 'PaypalPaymentMethod':
      return paymentMethod.accountName ?? '';
    case 'SepaPaymentMethod':
      return t ? t(tk.paymentMethodSepaTitle) : tk.paymentMethodSepaTitle;
    case 'ByInvoicePaymentMethod': {
      if (singleView) {
        return `VIBAN: ${paymentMethod.viban}`;
      }
      return t ? t(tk.paymentMethodPayByInvoiceBrand) : tk.paymentMethodPayByInvoiceBrand;
    }
    default:
      return '';
  }
};

export const paymentMethodDisplayTextsMap: Record<PaymentMethodDisplayType, string> = {
  [PaymentMethodDisplayType.visa]: 'Visa',
  [PaymentMethodDisplayType.mastercard]: 'Mastercard',
  [PaymentMethodDisplayType.americanExpress]: 'American Express',
  [PaymentMethodDisplayType.sepa]: 'SEPA',
  [PaymentMethodDisplayType.paypal]: 'PayPal',
  [PaymentMethodDisplayType.byInvoice]: 'By Invoice',
  [PaymentMethodDisplayType.unknown]: 'Unknown',
};

export const buildPaymentMethodSpecificText = (paymentMethod: PaymentMethodWithContracts) => {
  if (paymentMethod.__typename === 'CardPaymentMethod') {
    const { type, lastFourDigits, brand } = paymentMethod;
    const cardType = type ? ` ${type}` : '';
    return `${brand}${cardType}: ***${lastFourDigits}`;
  }

  if (paymentMethod.__typename === 'PaypalPaymentMethod') {
    const { accountName } = paymentMethod;
    return `PayPal: ${accountName}`;
  }

  return null;
};

export const getDate = (date?: string) => {
  const shortYear = date?.slice(2, 4);
  const month = date?.slice(5, 7);
  const day = date?.slice(8, 10);

  return date ? `${day}.${month}.${shortYear}` : '';
};

