import styled from 'styled-components';
import { Text } from '@/components/Text';
import { Group, GroupProps } from '@/components/Group';
import { ButtonV2 } from '@getgrover/ui';

export const Container = styled(Group)<GroupProps>`
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;
  margin-bottom: ${({ theme }): string => theme.spacers.XXL};
  border-radius: 8px;
  height: calc(100vh - 180px);

  @media ${({ theme }) => theme.screenSizes.tabletMax} {
    margin: 0;
    border: none;
    padding: 5%;
  }
`;

export const Image = styled.img`
  width: 50%;
  max-width: 600px;
  height: auto;
  margin: 0 auto;

  @media ${({ theme }) => theme.screenSizes.tabletMax} {
    width: 90%;
  }
`;

export const ErrorStyledText = styled(Text)`
  text-align: center;
  width: 50%;

  @media ${({ theme }) => theme.screenSizes.tabletMax} {
    width: 90%;
  }
`;

export const Button = styled(ButtonV2)`
  &:last-child {
    color: #ffffff;
  }
`;
