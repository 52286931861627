import { useRef, useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { PAYMENTS_QUERY } from '@/gql/queries';
import { PAYMENTS_PER_PAGE } from '@/pages/YourPaymentsPage/constants';
import { PaymentsUnion } from '@/generated/types';
import { useTranslation } from 'react-i18next';
import { QueryResult, GroupedPayments } from '../types';
import { mapPaymentsQueryResponseToGroupedPayments } from '../mappers';
import { PaymentState, PaymentEnum } from '@/generated/types';

interface ResultData {
  groups: GroupedPayments[];
  payments: PaymentsUnion[];
  totalCount: number;
}

export type UsePaymentsListResult = QueryResult<ResultData> & {
  loadMore: () => void;
  hasMore: boolean;
  infiniteScrollLoading: boolean;
};

type Filters = {
  state?: {
    _eq: PaymentState;
  };
  type?: {
    _eq: PaymentEnum;
  };
};

type UsePaymentsList = (args: {
  subscriptionId?: string;
  device?: string;
  status?: string;
  paymentType?: string;
  isFilterEmptyResult?: boolean;
  page?: number;
}) => UsePaymentsListResult;

type Cursor = string | null;

const getFilters = (status?: string, paymentType?: string) => {
  const filters: Filters = {};
  if (status === 'CANCELED') {
    return {
      state: {
        _eq: PaymentState.FAILED,
      },
      type: {
        _eq: PaymentEnum.ORDER_PAYMENT,
      },
    };
  }

  if (status) {
    filters.state = {
      _eq: status as PaymentState,
    };
  }

  if (paymentType) {
    filters.type = {
      _eq: paymentType as PaymentEnum,
    };
  }
  return filters;
};

export const usePaymentsList: UsePaymentsList = ({
  subscriptionId,
  device,
  status,
  paymentType,
  isFilterEmptyResult,
  page = 0,
}) => {
  const hasMoreRef = useRef<boolean>();
  const lastCallCursorRef = useRef<string | null>();
  const { t } = useTranslation();
  const [cursor, setCursor] = useState<Cursor>(null);
  const [data, setData] = useState<ResultData>({ groups: [], payments: [], totalCount: 0 });
  const [loading, setLoading] = useState(true);
  const [infiniteScrollLoading, setInfiniteScrollLoading] = useState(false);
  const [error, setError] = useState(false);

  const variables = {
    firstPayments: PAYMENTS_PER_PAGE,
    subscriptionId: subscriptionId || (device ? device : undefined),
    cursor,
    where: getFilters(status, paymentType),
  };

  useEffect(() => {
    if (isFilterEmptyResult) {
      setData({ groups: [], payments: [], totalCount: 0 });
    } else {
      setLoading(true);
    }

    if (device) {
      setCursor(null);
    }

    if (status === 'CANCELED') {
      setLoading(false);
    }
  }, [device, status, paymentType, device]);

  useQuery(PAYMENTS_QUERY, {
    variables,
    skip: isFilterEmptyResult,
    fetchPolicy: 'network-only',
    onCompleted: (response) => {
      const totalCount = response.payments?.totalCount ?? 0;
      lastCallCursorRef.current = response.payments?.pageInfo.endCursor ?? null;
      hasMoreRef.current = response.payments?.pageInfo.hasNextPage ?? false;

      const hasPreviousPage = response.payments?.pageInfo.hasPreviousPage;
      let payments = (response.payments?.nodes as PaymentsUnion[]) || [];

      if (hasPreviousPage) {
        const previouslyLoadedPayments = data?.payments ?? [];
        payments = [...previouslyLoadedPayments, ...payments];
      }

      const groups = mapPaymentsQueryResponseToGroupedPayments(payments, t);

      setData({ groups, payments, totalCount });
      setLoading(false);
      setInfiniteScrollLoading(false);
      setError(false);
    },
    onError: () => {
      setError(true);
    },
  });

  const loadMore = () => {
    if (!hasMoreRef.current) {
      return;
    }

    setLoading(true);
    setInfiniteScrollLoading(true);
    setCursor(lastCallCursorRef.current ?? null);
  };

  return {
    data,
    loading,
    infiniteScrollLoading,
    loadMore,
    hasMore: hasMoreRef.current ?? false,
    error,
  };
};
