import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SonntagButtonV2 } from '@getgrover/ui';
import { useApplicationData } from '@/providers/applicationData';
import { ScreenCommon } from '@/pages/ChangePaymentMethodPage/types';
import { Text } from '@/components/Text';
import { Group } from '@/components/Group';
import { Height } from '../../ChangePaymentMethodPage.styles';
import { PaymentMethodWrapper } from './ChangePaymentMethodSuccessScreen.styles';
import { PaymentMethodSingleView } from '@/components/PaymentMethod';
import { tk } from '@/i18n/translationKeys';
import { FlexDetails } from '@/generated/types';
import { Container } from '@/components/Container';
import { LinkedContract, PaymentMethodWithContracts } from '@/types/paymentMethods';
import { QueryParamName } from '@/constants';
import { SubscriptionItem } from '@/components/SubscriptionItem';
import { useTheme } from 'styled-components';

export const ChangePaymentMethodSuccessScreen = ({ currentScreen }: ScreenCommon) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { routeBuilder } = useApplicationData();
  const [searchParams] = useSearchParams();
  const targetContractId = searchParams.get(QueryParamName.subscriptionId);

  const newPaymentMethodForContract = currentScreen.data
    .newPaymentMethod as PaymentMethodWithContracts;
  const targetContract = newPaymentMethodForContract.linkedContracts.find(
    (contract: LinkedContract) => contract.id === targetContractId
  );

  const handleButtonClick = () => {
    location.href = routeBuilder.buildSubscriptionDetailsRoute({
      subscriptionId: targetContractId as string,
    });
  };

  return (
    <Height units={136}>
      <PaymentMethodWrapper>
        <PaymentMethodSingleView paymentMethod={newPaymentMethodForContract} />
      </PaymentMethodWrapper>

      <Group vertical gap={4} mobileGap={4}>
        <Text centered color={theme.colors.text.darkerGrey} typography="Subheadline">
          {t(tk.changePaymentMethodSuccessTitle)}
        </Text>
        <Text centered color={theme.colors.text.darkerGrey} typography="Paragraph">
          {t(tk.changePaymentMethodSuccessText)}
        </Text>

        <Container fullWidth testId="linked-subscriptions-block">
          <SubscriptionItem contract={targetContract as FlexDetails} />
        </Container>

        <SonntagButtonV2 fullWidth onClick={handleButtonClick}>
          {t(tk.changePaymentMethodSuccessButtonText)}
        </SonntagButtonV2>
      </Group>
    </Height>
  );
};
