import React, { useRef, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Action, SonntagIcon } from '@getgrover/ui';
import { useTheme } from 'styled-components';
import Markdown from 'markdown-to-jsx';

import { tk } from '@/i18n/translationKeys';
import { downloadIconPath, eyeIconPath } from '@/iconPaths';
import { FeatureName, LogPrefix, logger } from '@/logger';
import {
  trackYourPaymentsPageInvoiceDownloadClickEvent,
  trackYourPaymentsPagePaymentItemClickEvent,
  trackYourPaymentsPageSubscriptionLinkClickEvent,
} from '@/analytics';
import { useApplicationData } from '@/providers/applicationData';
import { useScreenMatch } from '@/hooks/useScreenMatch';
import { useInfiniteScroll } from '@/hooks/useInfiniteScroll';
import { LoadingUnit } from '@/components/Loading';
import { EmptyScreen } from '@/components/EmptyScreen';
import { Group } from '@/components/Group';
import { Text } from '@/components/Text';
import { DisplayIf } from '@/components/DisplayIf';
import { ModalWindow } from '@/components/ModalWindow';
import { Wrapper } from '@/components/Wrapper';
import { PaymentCardPreloader } from '../PaymentCard/PaymentCardPreloader';
import { ChevronRightIcon } from '@/icons/ChevronRightIcon';
import { PaymentState } from '@/generated/types';
import { UsePaymentsListResult } from './usePaymentsList';
import { PaymentCard } from '../PaymentCard';
import { GroupedPayments, UiFriendlyPayment } from '../types';

import {
  PaymentsListRoot,
  ModalContentActions,
  DownloadInvoiceDisabled,
  IconCircle,
  Content,
  ExternalLink,
  IconWrapper,
} from './PaymentsList.styles';

interface PaymentsListProps {
  paymentsListResult: UsePaymentsListResult;
}

export const PaymentsList = ({ paymentsListResult }: PaymentsListProps) => {
  const [modalPayment, setModalPayment] = useState<UiFriendlyPayment | null>(null);
  const { t } = useTranslation();
  const theme = useTheme();
  const { isMobile } = useScreenMatch();
  const scrollableElementRef = useRef<Window>(window);
  const { userId, storeCode } = useApplicationData();
  const { error, data, loading, infiniteScrollLoading, loadMore, hasMore } = paymentsListResult;

  const mainTitles = [
    {
      text: t(tk.yourPaymentsListTitlePayments),
      width: '640px',
      justifyContent: 'start',
      loadingUnitWidth: 19,
    },
    {
      text: t(tk.yourPaymentsListTitleStatus),
      width: '120px',
      justifyContent: 'end',
      loadingUnitWidth: 14,
    },
    {
      text: t(tk.yourPaymentsListTitleAmount),
      width: '212px',
      justifyContent: 'end',
      loadingUnitWidth: 16,
    },
    {
      text: t(tk.yourPaymentsListTitleInvoice),
      width: '96px',
      justifyContent: 'end',
      loadingUnitWidth: 15,
    },
  ];

  if (error) {
    throw Error('PaymentsList Error');
  }

  useInfiniteScroll({
    loading,
    fetchNextPage: loadMore,
    scrollableElementRef,
    hasNextPage: hasMore,
  });

  const handleDownloadInvoiceEvent = () => {
    logger.info(LogPrefix.Success, { feature: FeatureName.DownloadInvoice, userId });
    trackYourPaymentsPageInvoiceDownloadClickEvent({
      userId,
      store: storeCode,
    });
  };

  const handleOpenModal = (payment: UiFriendlyPayment) => {
    setModalPayment(payment);

    trackYourPaymentsPagePaymentItemClickEvent({
      userId,
      store: storeCode,
    });
  };

  const handleCloseModal = () => {
    setModalPayment(null);
  };

  const handleNavigateToYourTechEvent = () => {
    trackYourPaymentsPageSubscriptionLinkClickEvent({
      userId,
      store: storeCode,
    });
  };

  const renderDownloadInvoice = () => {
    if (!modalPayment) {
      return null;
    }

    const { invoiceUrl, state } = modalPayment;

    if (!invoiceUrl && state === PaymentState.SCHEDULED) {
      return (
        <DownloadInvoiceDisabled>
          <IconCircle>
            <SonntagIcon iconPath={downloadIconPath} color={theme.colors.text.darkerGrey} />
          </IconCircle>
          <Content>
            <Text bold typography="Paragraph" color={theme.colors.text.darkerGrey}>
              {t(tk.yourPaymentsDownloadInvoiceButtonText)}
            </Text>
            <Text typography="SmallParagraph" color={theme.colors.text.baseGrey}>
              {t(tk.yourPaymentsDownloadInvoiceButtonDisabledSimpleText)}
            </Text>
            <Text typography="SmallParagraph" color={theme.colors.text.baseGrey}>
              <Markdown
                options={{
                  overrides: {
                    a: {
                      component: ExternalLink,
                    },
                  },
                }}
              >
                {t(tk.yourPaymentsDownloadInvoiceButtonDisabledLearnMore)}
              </Markdown>
            </Text>
          </Content>
          <IconWrapper>
            <ChevronRightIcon color={theme.colors.text.baseGrey} />
          </IconWrapper>
        </DownloadInvoiceDisabled>
      );
    }

    if (invoiceUrl) {
      return (
        <Action
          onClick={() => handleDownloadInvoiceEvent()}
          href={invoiceUrl}
          target="_blank"
          title={t(tk.yourPaymentsDownloadInvoiceButtonText)}
          icon={<SonntagIcon iconPath={downloadIconPath} color={theme.colors.text.darkerGrey} />}
        />
      );
    } else {
      return (
        <Action
          disabled
          title={t(tk.yourPaymentsDownloadInvoiceButtonText)}
          description={t(tk.yourPaymentsDownloadInvoiceButtonDescription)}
          icon={<SonntagIcon iconPath={downloadIconPath} color={theme.colors.text.darkerGrey} />}
        />
      );
    }
  };

  const renderLinkToYourTech = () => {
    if (!modalPayment) {
      return null;
    }

    const { url, title, description } = modalPayment.linkToSubscription;

    return (
      <Action
        onClick={handleNavigateToYourTechEvent}
        href={url}
        title={title}
        description={description}
        icon={<SonntagIcon iconPath={eyeIconPath} color={theme.colors.text.darkerGrey} />}
      />
    );
  };

  return (
    <PaymentsListRoot isMobile={isMobile}>
      <DisplayIf desktop>
        <Group>
          {mainTitles.map((title) => (
            <Wrapper
              key={title.width}
              width={title.width}
              height="32px"
              justifyContent={title.justifyContent}
            >
              {loading ? (
                <LoadingUnit height={4} width={title.loadingUnitWidth} />
              ) : (
                <Text
                  block
                  uppercase
                  typography="SmallCapsHeadline"
                  color={theme.colors.text.baseGrey}
                  data-testid="your-payments-main-title"
                >
                  {title.text}
                </Text>
              )}
            </Wrapper>
          ))}
        </Group>
      </DisplayIf>

      {data?.groups?.map((group: GroupedPayments) => (
        <Fragment key={'your-payments-main-list' + group.name}>
          {group.payments.map((payment) => (
            <PaymentCard
              key={payment.id}
              payment={payment}
              onClick={() => handleOpenModal(payment)}
            />
          ))}
        </Fragment>
      ))}

      {loading && !infiniteScrollLoading
        ? [1, 2, 3, 4, 5, 6].map((key) => <PaymentCardPreloader key={key} />)
        : null}

      {infiniteScrollLoading ? [7].map((key) => <PaymentCardPreloader key={key} />) : null}

      {!loading && !data?.groups.length ? (
        <EmptyScreen
          title={t(tk.yourPaymentsEmptyStateTitle)}
          subtitle={t(tk.yourPaymentsEmptyStateText)}
          maxWidth={isMobile ? '380px' : 'unset'}
        />
      ) : null}

      {modalPayment ? (
        <ModalWindow onClose={handleCloseModal}>
          <ModalContentActions>
            <PaymentCard key={modalPayment.id} payment={modalPayment} isModal={true} />

            {renderDownloadInvoice()}
            {renderLinkToYourTech()}
          </ModalContentActions>
        </ModalWindow>
      ) : null}
    </PaymentsListRoot>
  );
};
