import React from 'react';
import { SonntagIcon } from '@getgrover/ui';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import Markdown from 'markdown-to-jsx';

import { tk } from '@/i18n/translationKeys';
import { useScreenMatch } from '@/hooks/useScreenMatch';
import { useApplicationData } from '@/providers/applicationData';
import { Text } from '@/components/Text';
import { Notice } from '@/components/Notice';
import { Offset } from '@/components/Offset';
import { Wrapper } from '@/components/Wrapper';
import { InfoIcon } from '@/icons/InfoIcon';
import { downloadIconPath } from '@/iconPaths';
import { FeatureName, LogPrefix, logger } from '@/logger';
import { trackYourPaymentsPageInvoiceDownloadClickEvent } from '@/analytics';
import { PaymentState } from '@/generated/types';
import { StatusTag } from '../StatusTag';
import { UiFriendlyPayment } from '../types';

import {
  PaymentCardRoot,
  IconCircle,
  Content,
  MaxTwoLinesTitle,
  Strikethrough,
  PaymentMobileCard,
  DownloadInvoiceButton,
  TooltipContainer,
  TooltipText,
  ExternalLink,
  PaidDateContainer,
} from './PaymentCard.styles';

interface PaymentCardProps {
  payment: UiFriendlyPayment;
  onClick?: () => void;
  isModal?: boolean;
}

export const PaymentCard = ({ payment, onClick, isModal }: PaymentCardProps) => {
  const { t } = useTranslation();
  const { title, subtitle, svgPath, strikethroughPrice, actualPrice, state, invoiceUrl, paidDate, typename } = payment;
  const { isMobile } = useScreenMatch();
  const { userId, storeCode } = useApplicationData();
  const theme = useTheme();

  const isFailedOrderPayment = typename === 'OrderPayment' && state === PaymentState.FAILED;

  const handleDownloadInvoiceEvent = (e: React.MouseEvent, invoiceUrl: string) => {
    e.stopPropagation();
    window.open(invoiceUrl, '_blank');

    logger.info(LogPrefix.Success, { feature: FeatureName.DownloadInvoice, userId });
    trackYourPaymentsPageInvoiceDownloadClickEvent({
      userId,
      store: storeCode,
    });
  };

  return (
    <PaymentCardRoot
      onClick={onClick}
      isMobile={isMobile}
      isModal={isModal}
      data-testid="your-payments-payment-card"
    >
      {isMobile || isModal ? (
        <PaymentMobileCard>
          <Wrapper justifyContent="space-between" alignItems="flex-start" margin="0 0 8px 0">
            <Wrapper width="56%">
              <MaxTwoLinesTitle>
                <Text block bold typography="Paragraph" color={theme.colors.text.darkerGrey}>
                  {title}
                </Text>
              </MaxTwoLinesTitle>
            </Wrapper>
            <StatusTag status={state} typename={typename} />
          </Wrapper>

          <Wrapper justifyContent="space-between">
            <Wrapper width="50%">
              <Text block typography="TinyParagraph" color={theme.colors.text.baseGrey}>
                {subtitle}
              </Text>
            </Wrapper>
            <Text noWrap typography="Paragraph" color={isFailedOrderPayment ? theme.colors.text.baseGrey : theme.colors.text.darkerGrey}>
              {strikethroughPrice ? <Strikethrough>{strikethroughPrice}</Strikethrough> : null}
              {isFailedOrderPayment ? <Strikethrough isFailedOrderPayment>{actualPrice}</Strikethrough> : actualPrice}
            </Text>
          </Wrapper>
          {isModal && strikethroughPrice ? (
            <Offset top={2}>
              <Notice
                icon={<InfoIcon />}
                status="warn"
                text={t(tk.yourPaymentsNoticeDiscountApplied)}
                height="32px"
              />
            </Offset>
          ) : null}
          {isModal && isFailedOrderPayment ? (
            <Offset top={3}>
              <Notice
                icon={<InfoIcon />}
                status="default"
                text={t(tk.yourPaymentsNoticeFailedOrderPayment)}
                height="52px"
                typography='TinyParagraph'
              />
            </Offset>
          ) : null}
          {isModal && paidDate ? (
            <PaidDateContainer isMobile={isMobile}>
              <Text typography="SmallParagraph" color={theme.colors.text.darkerGrey}>
                {paidDate}
              </Text>
            </PaidDateContainer>
          ) : null}
        </PaymentMobileCard>
      ) : (
        <>
          <IconCircle>
            <SonntagIcon color={theme.colors.text.darkerGrey} iconPath={svgPath} />
          </IconCircle>

          <Content>
            <MaxTwoLinesTitle>
              <Text block bold typography="Paragraph" color={theme.colors.text.darkerGrey}>
                {title}
              </Text>
            </MaxTwoLinesTitle>
            <Text typography="Paragraph" color={theme.colors.text.baseGrey}>
              {subtitle}
            </Text>
          </Content>

          <Wrapper width="120px" justifyContent="end">
            <StatusTag status={state} typename={typename} />
          </Wrapper>

          <Wrapper width="212px" justifyContent="end">
            <Text noWrap typography="Paragraph" color={isFailedOrderPayment ? theme.colors.text.baseGrey : theme.colors.text.darkerGrey}>
              {strikethroughPrice ? <Strikethrough>{strikethroughPrice}</Strikethrough> : null}
              {isFailedOrderPayment ? <Strikethrough isFailedOrderPayment>{actualPrice}</Strikethrough> : actualPrice}
            </Text>
          </Wrapper>

          <Wrapper width="96px" justifyContent="end">
            <TooltipContainer>
              <DownloadInvoiceButton
                outlined
                iconPath={downloadIconPath}
                iconColor={theme.colors.text.darkerGrey}
                onClick={(e) => handleDownloadInvoiceEvent(e, invoiceUrl)}
                disabled={!invoiceUrl}
                data-testid="your-payments-download-invoice-button"
              />
              {!invoiceUrl && (state === PaymentState.SCHEDULED) ? (
                <TooltipText onClick={(e) => e.stopPropagation()}>
                  <Text typography="SmallParagraph">
                    <Markdown
                      options={{
                        overrides: {
                          a: {
                            component: ExternalLink,
                          },
                        },
                      }}
                    >
                      {t(tk.yourPaymentsDownloadInvoiceButtonDisabledFullText)}
                    </Markdown>
                  </Text>
                </TooltipText>
              ) : null}
            </TooltipContainer>
          </Wrapper>
        </>
      )}
    </PaymentCardRoot>
  );
};
