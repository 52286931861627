import styled, { css } from 'styled-components';
import { HeaderUI } from '@devsbb/header-ui';

const navBarHeight = '64px';

export const PageRoot = styled.main`
  background: ${({ theme }) => theme.colors.background.white};
  min-height: 100vh;
`;

export const HeaderWrapper = styled(HeaderUI)<{ mobile?: boolean }>`
  background: ${({ theme }) => theme.colors.background.white};

  margin: auto;
  max-width: 1600px;

  ${({ mobile }) =>
    !mobile &&
    css`
      padding: 0 72px;
    `}
`;

export const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  margin: auto;
  max-width: 1600px;
  padding: 0 72px 16px 72px;

  @media ${({ theme }) => theme.screenSizes.tabletMax} {
    padding: 0 16px calc(${navBarHeight} + 16px) 16px;
  }
`;
