import React from 'react';
import { ProviderItemRoot, PrimaryContainer, IconWrapper } from './ProviderItem.styles';
import { ChevronRightIcon } from '@/icons/ChevronRightIcon';

interface ProviderItemProps {
  children: React.ReactNode;
  onClick?: () => void;
  notice?: React.ReactElement;
  expanded?: boolean;
}

export const ProviderItem = ({ children, onClick, notice, expanded }: ProviderItemProps) => {
  return (
    <ProviderItemRoot withNotice={Boolean(notice)} expanded={expanded}>
      <PrimaryContainer onClick={onClick} data-testid="payment-option-item" expanded={expanded}>
        {children}

        {!expanded ? (
          <IconWrapper>
            <ChevronRightIcon />
          </IconWrapper>
        ) : null}
      </PrimaryContainer>

      {notice ? notice : null}
    </ProviderItemRoot>
  );
};