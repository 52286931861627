import { LinkedContract, PaymentMethodWithContracts } from '@/types/paymentMethods';

export enum ScreenKey {
  paymentMethodList,
  bulkUpdateLoadingScreen,
  changePaymentMethodSuccessScreen,
  changePaymentMethodLoadingScreen,
}

export interface ScreenData {
  oldPaymentMethodId?: string;
  newPaymentMethodId?: string;
  newPaymentMethod?: PaymentMethodWithContracts;
  oldPaymentMethod?: PaymentMethodWithContracts;
  targetContract?: LinkedContract;
  deleteMode?: boolean;
  operationId?: string;
}

export interface CurrentScreen {
  key: ScreenKey;
  data: ScreenData;
}

export interface ScreenCommon {
  currentScreen: CurrentScreen;
  setScreen: (key: ScreenKey, data: ScreenData) => void;
  onClose: () => void;
  onBack: () => void;
}
