import React from 'react';
import { useTranslation } from 'react-i18next';
import { tk } from '@/i18n/translationKeys';

interface NoticeTextProps {
  highlightedText: string;
  deleteMode?: boolean;
}

export const NoticeText = ({ highlightedText, deleteMode }: NoticeTextProps) => {
  const { t } = useTranslation();
  const prefixTranslationKey = deleteMode
    ? tk.paymentMethodInBulkDeleteWarningPrefix
    : tk.paymentMethodInBulkWarningPrefix;

  const prefixText = t(prefixTranslationKey);
  const awaitingText = t(tk.paymentMethodInBulkWarning2Hours);

  return (
    <span>
      <span>{prefixText}</span>
      <b>{highlightedText}</b>
      <span>{awaitingText}</span>
    </span>
  );
};
