import { TypographyItemName } from '@getgrover/ui/dist/types/theme/types';
import styled, { DefaultTheme } from 'styled-components';

export interface TextRootProps {
  block?: boolean;
  bold?: boolean;
  uppercase?: boolean;
  centered?: boolean;
  color?: keyof DefaultTheme['colors'] | string;
  typography?: keyof typeof TypographyItemName;
  noWrap?: boolean;
}

export const Root = styled.span<TextRootProps>`
  display: ${(props: TextRootProps) => (props.block ? 'block' : 'inline')};
  text-transform: ${(props: TextRootProps) => (props.uppercase ? 'uppercase' : 'none')};
  white-space: ${(props: TextRootProps) => (props.noWrap ? 'nowrap' : 'none')};
  color: ${({ theme, color }) =>
    color ? (typeof color === 'string' ? color : theme.colors[color] || 'inherit') : 'inherit'};
  text-align: ${({ centered }) => (centered ? 'center' : 'auto')};
  ${({ theme, typography }) => (typography ? theme.typography[typography] || '' : '')}
  ${({ bold }) => (bold ? 'font-weight: 700;' : '')}
`;
