import { useMutation } from '@apollo/client';
import { ADD_PAYMENT_METHOD_MUTATION } from '@/gql/mutations/paymentMethod';
import { PaymentOptionType, AddPaymentMethodResult, TransactionErrorCode } from '@/generated/types';
import { useState } from 'react';
import { useApplicationData } from '@/providers/applicationData';
import { useSearchParams } from 'react-router-dom';
import { GraphQLErrors } from '@apollo/client/errors';
import { QueryParamName } from '@/constants';

type CreatePaymentMethod = (args: { option: PaymentOptionType; cardToken?: string }) => void;
type UseCreatePaymentMethodResult = [
  CreatePaymentMethod,
  {
    createPaymentMethodInProgress: boolean;
    createPaymentMethodError: TransactionErrorCode | null;
  }
];

export const useCreatePaymentMethod = (): UseCreatePaymentMethodResult => {
  const { routeBuilder } = useApplicationData();
  const [addPaymentMethod] = useMutation(ADD_PAYMENT_METHOD_MUTATION);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<TransactionErrorCode | null>(null);

  const [searchParams] = useSearchParams();
  const bulkId = searchParams.get(QueryParamName.bulkId);
  const subscriptionId = searchParams.get(QueryParamName.subscriptionId);
  const mode = searchParams.get(QueryParamName.mode);

  const extraQueryParams: Record<string, string> = {};
  if (bulkId) extraQueryParams[QueryParamName.bulkId] = bulkId;
  if (subscriptionId) extraQueryParams[QueryParamName.subscriptionId] = subscriptionId;
  if (mode) extraQueryParams[QueryParamName.mode] = mode;

  const createPaymentMethod: CreatePaymentMethod = ({ option, cardToken }) => {
    setLoading(true);
    addPaymentMethod({
      variables: {
        input: {
          option,
          cardToken,
          errorRedirectUrl: location.origin + routeBuilder.buildAddPaymentMethodRoute({
            query: {
              ...extraQueryParams,
              [QueryParamName.paymentMethodStatus]: 'f',
              [QueryParamName.paymentMethodId]: '{{payment-method-id}}' 
            }
          }),
          cancelRedirectUrl: location.origin + routeBuilder.buildAddPaymentMethodRoute({
            query: {
              ...extraQueryParams,
              [QueryParamName.paymentMethodStatus]: 'c',
              [QueryParamName.paymentMethodId]: '{{payment-method-id}}'
            }
          }),
          successRedirectUrl: location.origin + routeBuilder.buildAddPaymentMethodRoute({
            query: {
              ...extraQueryParams,
              [QueryParamName.paymentMethodStatus]: 's',
              [QueryParamName.paymentMethodId]: '{{payment-method-id}}' 
            }
          }),
        }
      },
      onError: (err) => {
        const graphqlErrorCode = extractGraphqlErrorCode(err.graphQLErrors);
        setLoading(false);
        setError(graphqlErrorCode);
      },
      onCompleted: (data: { addPaymentMethod: AddPaymentMethodResult }) => {
        const { redirectUrl } = data.addPaymentMethod;

        if (redirectUrl) {
          window.location.href = redirectUrl;
          return;
        }

        if (PaymentOptionType.BANKCARD) {
          const successRedirectUrl = location.origin + routeBuilder.buildAddPaymentMethodRoute({ query: { ...extraQueryParams, 'p-status': 's', 'pm-id': data.addPaymentMethod.id } });
          window.location.href = successRedirectUrl;
          return;
        }
      },
    });
  };

  return [
    createPaymentMethod,
    {
      createPaymentMethodInProgress: loading,
      createPaymentMethodError: error,
    },
  ];
};

const extractGraphqlErrorCode = (graphqlErrors: GraphQLErrors) => {
  return (graphqlErrors[0].extensions?.code ??
    TransactionErrorCode.OPERATION_FAILED) as TransactionErrorCode;
};
