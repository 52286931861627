import React, { FormEvent, useEffect, useRef, useState } from 'react';
import { Text } from '@/components/Text';
import { Group } from '@/components/Group';
import { Offset } from '@/components/Offset';
import { useTranslation } from 'react-i18next';
import { ButtonV2, Theme } from '@getgrover/ui';
import { CircleLoader } from '@/components/CircleLoader';
import { tk } from '@/i18n/translationKeys';
import { PaymentJSLib } from '@/types/ixopay';
import { useApplicationData } from '@/providers/applicationData';
import { generateToken } from './utils';
import { Form, LoaderWrapper, InputWrapper, PaymentFormInputIframe } from './CreditCardForm.styles';
import {
  GroverFormData,
  GroverFieldName,
  initialFormData,
  initialIxopayData,
  IxoPayEventData,
  initialIxopayFormErrorsData,
  IxopayFormErrorsData,
  IxopayFieldName,
} from './formData';
import { Notice } from '@/components/Notice';
import { PaymentOption, PaymentOptionType, TransactionErrorCode } from '@/generated/types';
import { trackAddPaymentMethodCardSubmittedEvent } from '@/analytics';
import { useCreatePaymentMethod } from '../../../useCreatePaymentMethod';
import { GroverPaymentSDK } from './sdk';
import { useTheme } from 'styled-components';
import InputMask from 'react-input-mask';
import { Input } from '@/components/Input';
import { PadlockIcon } from '@/icons/PadlockIcon';
import { validateGroverField } from './validators';
import { AddPaymentMethodOtherError } from '@/pages/AddPaymentMethodPage/types';

export const CREDIT_CARD_MASK_SYMBOL = '\u2022';
const paymentSdk = new GroverPaymentSDK();

enum ScreenState {
  formInitialising,
  formInitialisingError,
  form,
  submitting,
  d3Secure,
}

const getIxopayInputStyles = (theme: Theme, disabled: boolean) => {
  const baseStyle = {
    border: 'none',
    outline: 'none',
    height: '100%',
    'font-size': '16px',
    background: 'transparent',
    padding: '20px 0 0 20px',
  };

  if (disabled) {
    return {
      ...baseStyle,
      'pointer-events': 'none',
      color: theme.colors.text.baseGrey,
    };
  }

  return baseStyle;
};

interface InputMaskInstance {
  getInputDOMNode: () => HTMLInputElement | null;
}

interface CreditCardFormProps {
  paymentOptions: PaymentOption[];
  onFormReady: () => void;
  onError: (customErrorCode: TransactionErrorCode | AddPaymentMethodOtherError) => void;
}

export const CreditCardForm = ({ paymentOptions, onFormReady, onError }: CreditCardFormProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { userId, storeCode, platform, rawUseragent } = useApplicationData();
  const [screenState, setScreenState] = useState<ScreenState>(ScreenState.formInitialising);

  const [ixopayInstance, setIxopayInstance] = useState<PaymentJSLib | null>(null);
  const [groverFormData, setGroverFormData] = useState<GroverFormData>(initialFormData);
  const [ixopayFormData, setIxopayFormData] = useState<IxopayFormErrorsData>(
    initialIxopayFormErrorsData
  );
  const [ixopayData, setIxopayData] = useState<IxoPayEventData>(initialIxopayData);

  const [numberFocus, setNumberFocus] = useState<boolean>(false);
  const [cvvFocus, setCvvFocus] = useState<boolean>(false);
  const [expirationFocus, setExpirationFocus] = useState<boolean>(false);

  const [createPaymentMethod, { createPaymentMethodInProgress, createPaymentMethodError }] =
    useCreatePaymentMethod();

  const groverFormDataRef = useRef<GroverFormData>();
  const expirationInputRef = useRef<InputMask & InputMaskInstance>(null);

  const cardProvider = paymentOptions.find(
    (option) => option.type === PaymentOptionType.BANKCARD
  ) as PaymentOption;
  const apiPublicKey = cardProvider.ixopay.publicKey ?? null;
  const noticeTextTranslationKey =
    storeCode === 'us' ? tk.creditCardFormNoticeTextUs : tk.creditCardFormNoticeTextEu;

  useEffect(() => {
    groverFormDataRef.current = groverFormData;
  }, [groverFormData]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setGroverFormData((prevState: GroverFormData) => {
      // hack to not reset error for cardExpiration input when focused
      // focus triggers onChange in mask-input because changing '' to '**/**'
      if (name === GroverFieldName.cardExpiration) {
        if (prevState.cardExpiration.value === '' && value === '••/••') {
          return prevState;
        }
      }
      // end hack

      return {
        ...prevState,
        [name as GroverFieldName]: {
          value,
          error: null,
        },
      };
    });
  };

  const handleGroverInputBlur = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = target;
    const error = validateGroverField(name as GroverFieldName, value, t);

    if (error) {
      setGroverFormData((prevState: GroverFormData) => {
        return {
          ...prevState,
          [name as GroverFieldName]: {
            value,
            error,
          },
        };
      });
    }
  };

  useEffect(() => {
    if (!apiPublicKey) {
      return;
    }

    paymentSdk
      .loadPaymentLibrary()
      .then(() => {
        paymentSdk
          .getPaymentLibrary()
          .init(apiPublicKey, 'myRandomId-cardNumber', 'myRandomId-cvv', setIxopayInstance);
      })
      .catch(() => {
        setScreenState(ScreenState.formInitialisingError);
      });
  }, [apiPublicKey]);

  useEffect(() => {
    if (!ixopayInstance) {
      return;
    }

    const ixopayInputStyles = getIxopayInputStyles(theme, false);
    ixopayInstance.cInst.setNumberStyle(ixopayInputStyles);
    ixopayInstance.cInst.setCvvStyle(ixopayInputStyles);

    ixopayInstance.numberOn('input', (ixopayData: IxoPayEventData) => {
      setIxopayFormData({
        ...ixopayFormData,
        [IxopayFieldName.cardNumber]: null,
      });
      setIxopayData(ixopayData);
    });

    ixopayInstance.cvvOn('input', (ixopayData: IxoPayEventData) => {
      setIxopayFormData({
        ...ixopayFormData,
        [IxopayFieldName.cardCVV]: null,
      });
      setIxopayData(ixopayData);
    });

    ixopayInstance.numberOn('focus', () => setNumberFocus(true));
    ixopayInstance.numberOn('enter', handleFormSubmitFromIframeFields);

    ixopayInstance.cvvOn('focus', () => setCvvFocus(true));
    ixopayInstance.cvvOn('enter', handleFormSubmitFromIframeFields);

    ixopayInstance.numberOn('blur', (ixopayData: IxoPayEventData) => {
      setIxopayFormData((prevState: IxopayFormErrorsData) => {
        return {
          ...prevState,
          [IxopayFieldName.cardNumber]: ixopayData.validNumber
            ? null
            : t(tk.addPaymentMethodCardNumberInvalidText),
        };
      });

      setNumberFocus(false);
    });

    ixopayInstance.cvvOn('blur', (ixopayData: IxoPayEventData) => {
      setIxopayFormData((prevState: IxopayFormErrorsData) => {
        return {
          ...prevState,
          [IxopayFieldName.cardCVV]: ixopayData.validCvv
            ? null
            : t(tk.addPaymentMethodCardCvvInvalidText),
        };
      });

      setCvvFocus(false);
    });

    setScreenState(ScreenState.form);
    onFormReady();
  }, [ixopayInstance]);

  const handleFormSubmitFromIframeFields = () => {
    submitForm();
  };

  const handleFormSubmitFromGroverFields = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    submitForm();
  };

  const submitForm = async () => {
    if (!ixopayInstance) {
      return;
    }

    trackAddPaymentMethodCardSubmittedEvent({
      userId,
      store: storeCode,
      legacy: false,
      platform,
      rawUseragent,
    });

    setScreenState(ScreenState.submitting);

    let ixopayToken;
    try {
      ixopayToken = await generateToken(
        groverFormDataRef.current as GroverFormData,
        ixopayInstance
      );
    } catch (error) {
      return onError(AddPaymentMethodOtherError.IXOPAY_TOKEN_CREATION_FAILED);
    }

    createPaymentMethod({
      option: PaymentOptionType.BANKCARD,
      cardToken: ixopayToken as string,
    });
  };

  useEffect(() => {
    if (createPaymentMethodError) {
      onError(createPaymentMethodError);
    }
  }, [createPaymentMethodError]);

  useEffect(() => {
    if (ixopayInstance && screenState === ScreenState.submitting) {
      const ixopayInputStyles = getIxopayInputStyles(theme, true);
      ixopayInstance.cInst.setNumberStyle(ixopayInputStyles);
      ixopayInstance.cInst.setCvvStyle(ixopayInputStyles);
    }
  }, [screenState]);

  const renderState = () => {
    switch (screenState) {
      case ScreenState.formInitialising:
        return null;
      case ScreenState.form:
        return null;
      case ScreenState.formInitialisingError:
        return <div>Form error</div>;
      case ScreenState.submitting: {
        return null;
      }
      case ScreenState.d3Secure:
        return renderPreloaderState('3D secure redirect');
      default:
        return null;
    }
  };

  const renderPreloaderState = (text: string) => {
    return (
      <LoaderWrapper>
        <CircleLoader />

        <Offset top={4}>
          <Text
            block
            typography="Paragraph"
            color={theme.colors.text.baseGrey}
            data-testid="loading-text"
          >
            {text}
          </Text>
        </Offset>
      </LoaderWrapper>
    );
  };

  const handleClickForMaskedInputFocusCorrection = () => {
    const current = expirationInputRef?.current;
    current?.getInputDOMNode()?.focus();
    setExpirationFocus(true);
  };

  return (
    <Offset top={5}>
      <Group vertical gap={4} mobileGap={4}>
        {renderState()}

        <Form
          onSubmit={handleFormSubmitFromGroverFields}
          visible={[ScreenState.form, ScreenState.submitting].includes(screenState)}
        >
          <Group vertical gap={6} mobileGap={6}>
            <Input
              disabled={screenState === ScreenState.submitting}
              focus={numberFocus}
              name="cardNumber"
              label={t(tk.addPaymentMethodCardNumberLabel)}
              value={ixopayData.firstSix} // hack for input to hold title above the text
              onChange={handleInputChange}
              onClick={() => ixopayInstance?.cInst.focusNumber()}
              error={ixopayFormData.cardNumber}
              renderInput={() => {
                return <PaymentFormInputIframe id="myRandomId-cardNumber" />;
              }}
            />

            <Input
              disabled={screenState === ScreenState.submitting}
              name="cardholderName"
              label={t(tk.addPaymentMethodCardholderNameLabel)}
              value={groverFormData.cardholderName.value}
              onChange={handleInputChange}
              onBlur={handleGroverInputBlur}
              error={groverFormData.cardholderName.error}
            />

            <Group gap={4} mobileGap={4}>
              <InputWrapper>
                <Input
                  disabled={screenState === ScreenState.submitting}
                  focus={expirationFocus}
                  name="cardExpiration"
                  label={t(tk.addPaymentMethodCardExpiryDateLabel)}
                  value={groverFormData.cardExpiration.value}
                  onChange={handleInputChange}
                  onClick={handleClickForMaskedInputFocusCorrection}
                  error={groverFormData.cardExpiration.error}
                  renderInput={() => {
                    return (
                      <InputMask
                        disabled={screenState === ScreenState.submitting}
                        ref={expirationInputRef}
                        name="cardExpiration"
                        value={groverFormData.cardExpiration.value}
                        data-testid="form-input-mask"
                        mask="99/99"
                        maskChar={CREDIT_CARD_MASK_SYMBOL}
                        onChange={handleInputChange}
                        onBlur={(e) => {
                          handleGroverInputBlur(e);
                          setExpirationFocus(false);
                        }}
                        onFocus={() => {
                          setExpirationFocus(true);
                        }}
                      />
                    );
                  }}
                />
              </InputWrapper>

              <InputWrapper>
                <Input
                  disabled={screenState === ScreenState.submitting}
                  focus={cvvFocus}
                  name="cvv"
                  label={t(tk.addPaymentMethodCardCvvLabel)}
                  value={ixopayData.cvvLength ? '***' : ''} // hack for input to hold title above the text
                  onChange={handleInputChange}
                  onClick={() => ixopayInstance?.cInst.focusCvv()}
                  error={ixopayFormData.cardCVV}
                  renderInput={() => {
                    return <PaymentFormInputIframe id="myRandomId-cvv" />;
                  }}
                />
              </InputWrapper>
            </Group>

            <Notice icon={<PadlockIcon />} text={t(noticeTextTranslationKey)} />

            <ButtonV2
              type="submit"
              fullWidth
              color="red"
              isDisabled={
                screenState === ScreenState.submitting ||
                !ixopayData.validCvv ||
                !ixopayData.validNumber
              }
            >
              {t(tk.addCardSubmitButtonText)}
            </ButtonV2>
          </Group>
        </Form>
      </Group>
    </Offset>
  );
};
