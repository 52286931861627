import styled, { keyframes } from 'styled-components';

const inOutTransitionFunction = 'cubic-bezier(0.4, 0.0, 0.2, 1)';

const animationDurationInMs = 1400;

const rotationKeyframe = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(270deg);
    }
`;

const dashKeyframe = keyframes`
    50% {
        transform: rotate(135deg);
    }
    100% {
        transform: rotate(450deg);
    }
`;

export const SpinnerContainer = styled.div`
  width: 45px;
  height: 45px;
  animation: ${rotationKeyframe} ${animationDurationInMs}ms linear infinite;
`;

export const SpinnerCircle = styled.div`
  border-left-color: ${({ theme }): string => theme.colors.brand.lightExciteRed};
  border-top-color: ${({ theme }): string => theme.colors.brand.lightExciteRed};
  box-sizing: border-box;
  border-width: 4px;
  border-style: solid;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border-right-color: transparent;
  border-bottom-color: transparent;
  animation: ${dashKeyframe} ${animationDurationInMs}ms ${inOutTransitionFunction} infinite;
`;

export const Container = styled.div`
  flex-shrink: 0;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;
