import { useQuery } from '@apollo/client';
import { HeaderQueryDocument, HeaderSearchQueryDocument } from '@devsbb/header-ui';
import { HeaderConsumer } from '@/generated/types';
import { GetHeaderSearchQuery } from './types';

export const useHeader = ({
  consumer,
  isGroverApp,
}: {
  consumer: HeaderConsumer;
  isGroverApp: boolean;
}) => {
  return useQuery(HeaderQueryDocument, {
    variables: {
      consumer,
    },
    errorPolicy: 'none',
    fetchPolicy: 'cache-first',
    skip: isGroverApp,
  });
};

export const useHeaderSearch = ({ consumer, q }: { consumer: HeaderConsumer; q: string }) => {
  return useQuery<GetHeaderSearchQuery>(HeaderSearchQueryDocument, {
    variables: {
      consumer,
      q: q.trim(),
    },
    skip: !q.trim(),
    errorPolicy: 'none',
    fetchPolicy: 'cache-first',
  });
};
