import React, { useRef, useMemo } from 'react';
import { useWindowScroll } from 'react-use';
import { AppBarContainer as Container, AppBarBackButton } from './NavigationBar.styles';
import { arrowBackIconPath } from '@/icons/iconPaths';
import { useNavigate } from 'react-router-dom';

type NavigationBarProps = {
  className?: string;
  url: string;
  external?: boolean;
  onClick?: () => void;
  text: string;
};

export const NavigationBar = ({
  className,
  url,
  external,
  onClick,
  text,
}: NavigationBarProps): JSX.Element => {
  const navigate = useNavigate();
  const containerRef = useRef<HTMLElement | null>(null);
  const { y: scrolledFromTop } = useWindowScroll();

  const hasShadow = useMemo(() => {
    const rect = containerRef.current?.getBoundingClientRect();

    return rect ? scrolledFromTop > rect.bottom : false;
  }, [scrolledFromTop]);

  const handleClick = () => {
    if (onClick) {
      onClick();
    }

    if (external) {
      window.location.href = url;
    } else {
      navigate(url);
    }
  };

  return (
    <Container ref={containerRef} hasShadow={hasShadow} className={className} onClick={handleClick}>
      <AppBarBackButton size="small" iconPath={arrowBackIconPath}>
        {text}
      </AppBarBackButton>
    </Container>
  );
};
