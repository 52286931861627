import React from 'react';

const FailureIcon = () => {
  return (
    <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="13.335" cy="13.335" r="13.335" fill="#EF432D" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3198 8.6746C9.86563 8.22045 9.12927 8.22045 8.6751 8.6746C8.22093 9.12875 8.22093 9.86507 8.6751 10.3192L11.6907 13.3346L8.67512 16.35C8.22094 16.8042 8.22094 17.5405 8.67512 17.9946C9.12929 18.4488 9.86565 18.4488 10.3198 17.9946L13.3354 14.9792L16.3504 17.9941C16.8045 18.4483 17.5409 18.4483 17.9951 17.9941C18.4493 17.54 18.4493 16.8036 17.9951 16.3495L14.9801 13.3346L17.9951 10.3197C18.4493 9.86559 18.4493 9.12926 17.9951 8.67512C17.5409 8.22097 16.8046 8.22097 16.3504 8.67512L13.3354 11.69L10.3198 8.6746Z"
        fill="white"
      />
    </svg>
  );
};

export default FailureIcon;
