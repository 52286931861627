import React, { useCallback } from 'react';
import { useScreenMatch } from '@/hooks/useScreenMatch';
import {
  DesktopGroverLogo,
  TopBarDesktop,
  TopBarMobile,
  ContentDesktop,
  ContentMobile,
  ContentWrapper,
  CloseButton,
  FlowPageRoot,
  BackButton,
} from '@/components/FlowPage/FlowPage.styles';
import { ArrowLeftIcon } from '@/icons/ArrowLeftIcon';
import { CloseIcon } from '@/icons/CloseIcon';

interface FlowPageProps {
  children: React.ReactNode;
  onClose: () => void;
  onBack: () => void;
  withMobileBackButton: boolean;
  newDesign?: boolean;
}

export const FlowPage = ({
  children,
  onClose,
  onBack,
  withMobileBackButton,
  newDesign,
}: FlowPageProps) => {
  const { isMobile } = useScreenMatch();

  const handleBackClick = useCallback(() => {
    onBack();
  }, []);

  const handleCloseClick = useCallback(() => {
    onClose();
  }, []);

  if (isMobile) {
    return (
      <FlowPageRoot>
        {withMobileBackButton ? (
          <TopBarMobile>
            <BackButton role="button" data-testid="x-back" onClick={handleBackClick}>
              <ArrowLeftIcon />
            </BackButton>
          </TopBarMobile>
        ) : null}

        <ContentMobile data-testid="modal-content">{children}</ContentMobile>
      </FlowPageRoot>
    );
  }

  return (
    <FlowPageRoot>
      <TopBarDesktop>
        <DesktopGroverLogo />
      </TopBarDesktop>

      <ContentWrapper>
        <ContentDesktop data-testid="modal-content" newDesign={newDesign}>
          <CloseButton role="button" data-testid="x-back" onClick={handleCloseClick}>
            <CloseIcon />
          </CloseButton>

          {children}
        </ContentDesktop>
      </ContentWrapper>
    </FlowPageRoot>
  );
};
