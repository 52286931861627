export enum FeatureName {
  DownloadInvoice = 'Download Invoice',
  DownloadInvoiceOld = 'Download Invoice Old',
  AddPaymentMethod = 'Add Payment Method',
  ChangePaymentMethod = 'Change Payment Method',
  BulkUpdate = 'Bulk Update',
  DeletePaymentMethod = 'Delete Payment Method',
}

export enum ApplicationLaunchResult {
  Success = 'All Good',
  Error = 'Fatal Error',
  Auth = 'Redirect to Auth',
}

export enum LogPrefix {
  Success = 'Feature Success',
  Failure = 'Feature Failure',
}
