import React from 'react';

export const GreenCheckIcon = ({ color }: { color?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      data-testid="green-check-icon"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 24.5C5.3724 24.5 0 19.1276 0 12.5C0 5.8724 5.3724 0.5 12 0.5C18.6276 0.5 24 5.8724 24 12.5C24 19.1276 18.6276 24.5 12 24.5ZM17.6485 10.9485C18.1172 10.4799 18.1172 9.7201 17.6485 9.25147C17.1799 8.78284 16.4201 8.78284 15.9515 9.25147L10.8 14.4029L8.04853 11.6515C7.5799 11.1828 6.8201 11.1828 6.35147 11.6515C5.88284 12.1201 5.88284 12.8799 6.35147 13.3485L10.8 17.7971L17.6485 10.9485Z"
        fill="#53B023"
      />
      <path
        d="M17.6485 10.9485C18.1172 10.4799 18.1172 9.7201 17.6485 9.25147C17.1799 8.78284 16.4201 8.78284 15.9515 9.25147L10.8 14.4029L8.04853 11.6515C7.5799 11.1828 6.8201 11.1828 6.35147 11.6515C5.88284 12.1201 5.88284 12.8799 6.35147 13.3485L10.8 17.7971L17.6485 10.9485Z"
        fill="white"
      />
    </svg>
  );
};
