import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { tk } from '@/i18n/translationKeys';
import { SonntagButtonV2 } from '@getgrover/ui';
import { trackYourPaymentsPagePaymentMethodsLinkClickEvent } from '@/analytics';
import { useApplicationData } from '@/providers/applicationData';
import { useScreenMatch } from '@/hooks/useScreenMatch';
import { Group } from '@/components/Group';
import { WalletIcon } from '@/icons/WalletIcon';

import { LinkToPaymentMethodsRoot } from './LinkToPaymentMethods.styles';


export const LinkToPaymentMethods = () => {
  const { userId, storeCode, routeBuilder } = useApplicationData();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isMobile } = useScreenMatch();

  const handleClick = useCallback(() => {
    trackYourPaymentsPagePaymentMethodsLinkClickEvent({
      userId,
      store: storeCode,
    });

    navigate(routeBuilder.buildPaymentMethodListRoute());
  }, [storeCode]);

  return (
    <LinkToPaymentMethodsRoot onClick={handleClick} data-testid="your-payments-manage-payment-methods-button">
      {isMobile ? <div ><WalletIcon /></div> : (
        <SonntagButtonV2 size="small">
          <Group centered gap={1}>
            <WalletIcon />
            <span>{t(tk.yourPaymentsLinkToPaymentMethodsText)}</span>
          </Group>
        </SonntagButtonV2>)}
    </LinkToPaymentMethodsRoot>
  );
};
