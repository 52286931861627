import React from 'react';
import { DesktopContainer } from './PageContainer.styles';
import { DisplayIf } from '@/components/DisplayIf';

interface PageContainerProps {
  children: React.ReactElement | null | Array<React.ReactElement | null>;
}

export const PageContainer = ({ children }: PageContainerProps) => {
  return (
    <>
      <DisplayIf desktop>
        <DesktopContainer>{children}</DesktopContainer>
      </DisplayIf>
      <DisplayIf mobile>{children}</DisplayIf>
    </>
  );
};
