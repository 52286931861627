import styled from 'styled-components';

export const CardWrapper = styled.header`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 24px 0 40px 0;
`;

export const ExternalLink = styled.a`
  text-decoration: underline;
  cursor: pointer;
  white-space: nowrap;
`;
