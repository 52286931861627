import React from 'react';
import { SonntagIcon } from '@getgrover/ui';
import { Price, FlexDetails } from '@/generated/types';
import { getLocale } from '@/utils/cookies';
import { Text } from '@/components/Text';
import {
  SubscriptionItemRoot,
  IconWrapper,
  Image,
  EllipsisText,
  LeftWrapper,
  CenterWrapper,
  PriceText,
  ChevronIconWrapper,
} from './SubscriptionItem.styles';
import { useTheme } from 'styled-components';
import { TFn } from '@/pages/YourPaymentsPage/types';
import { tk } from '@/i18n/translationKeys';
import { useTranslation } from 'react-i18next';
import { ChevronRightIcon } from '@/icons/ChevronRightIcon';
import { Group } from '@/components/Group';
import { Offset } from '@/components/Offset';
import { mapContractToSubscriptionItem } from './mapContractToSubscriptionItem';
import { formatDate } from '@/utils/date';
import { useApplicationData } from '@/providers/applicationData';
import { CURRENCIES_ASSOCIATION } from '@/constants';
import { formatPriceByLanguage } from '@/utils/money';

type FormatPrice = (args: { price?: Price }) => string | null;
const formatPrice: FormatPrice = ({ price }) => {
  if (!price) {
    return null;
  }

  const { inCents, currency } = price;
  const currencySymbol = CURRENCIES_ASSOCIATION[currency as 'EUR' | 'USD' | 'GBP'];
  return formatPriceByLanguage(inCents, getLocale(), currencySymbol);
};

const getText = ({ date, t }: { date?: Date; t: TFn }) => {
  if (!date) {
    return null;
  }

  const formattedDate = formatDate({ date: String(date), locale: getLocale(), mobile: true });

  return `${t(tk.nextPaymentDueText)}: ${formattedDate}`;
};

export const SubscriptionItem = ({ contract }: { contract: FlexDetails }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { routeBuilder } = useApplicationData();
  const mappedContractData = mapContractToSubscriptionItem(contract);

  if (!mappedContractData) {
    return null;
  }

  const { productName, iconSvgPath, imageSrc, amountDue, nextPeriodStartsAt } = mappedContractData;

  return (
    <SubscriptionItemRoot
      href={routeBuilder.buildSubscriptionDetailsRoute({ subscriptionId: contract.id })}
      data-testid="linked-subscription-item"
    >
      <LeftWrapper>
        {imageSrc ? (
          <Image src={imageSrc} data-testid="image" />
        ) : (
          <IconWrapper>
            <SonntagIcon color={theme.colors.text.darkerGrey} iconPath={iconSvgPath as string} />
          </IconWrapper>
        )}

        <CenterWrapper>
          <EllipsisText typography="SuperTinyHeadline" color={theme.colors.text.darkerGrey}>
            {productName}
          </EllipsisText>

          <Offset top={1}>
            <Text typography="Paragraph" color={theme.colors.text.baseGrey}>
              {getText({ date: nextPeriodStartsAt, t })}
            </Text>
          </Offset>
        </CenterWrapper>
      </LeftWrapper>

      <Group centered gap={4} mobileGap={4}>
        <PriceText typography="Paragraph" color={theme.colors.text.darkGrey}>
          {formatPrice({ price: amountDue })}
        </PriceText>

        <ChevronIconWrapper>
          <ChevronRightIcon color={theme.colors.text.darkGrey} />
        </ChevronIconWrapper>
      </Group>
    </SubscriptionItemRoot>
  );
};

export default SubscriptionItem;
