import AppRoutes from '@/routes/AppRoutes';
import React, { Suspense } from 'react';
import { loadBrowserEnv } from '@/config';
import { ThemeProvider } from 'styled-components';
import { toast } from 'react-toastify';
import GlobalStyle from '@/theme/globalStyle';
import { lightTheme } from '@/theme/themes';
import { DisplayIf } from '@/components/DisplayIf';
import { StyledToastContainer } from './components/Toast/Toast.styles';
import './styles.css';

import 'react-toastify/dist/ReactToastify.css';
import { initAnalytics } from '@devsbb/analytics-client';
import { initDatadog } from './logger';

const browserEnv = loadBrowserEnv();
const segmentWriteKey = browserEnv.SEGMENT_WRITE_KEY;

initAnalytics({ segmentWriteKey, app: { name: 'payments-ui' } });
initDatadog();

const App = () => {
  return (
    <ThemeProvider theme={lightTheme}>
      <GlobalStyle />

      <Suspense fallback={null}>
        <AppRoutes />
      </Suspense>

      <DisplayIf mobile>
        <StyledToastContainer
          mobile
          hideProgressBar
          draggableDirection="y"
          position={toast.POSITION.TOP_CENTER}
          autoClose={4000}
        />
      </DisplayIf>
      <DisplayIf desktop>
        <StyledToastContainer
          hideProgressBar
          position={toast.POSITION.TOP_RIGHT}
          autoClose={4000}
        />
      </DisplayIf>
    </ThemeProvider>
  );
};

export default App;
