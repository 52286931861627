import React from 'react';
import { Offset } from '@/components/Offset';
import { Text } from '@/components/Text';
import { ContainerRoot } from './Container.styles';
import { useTheme } from 'styled-components';

type Child = React.ReactElement | null;
interface ContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  title?: string;
  fullWidth?: boolean;
  noPadding?: boolean;
  heightInUnits?: number;
  children: Child | Child[];
  testId?: string;
}

export const Container = ({
  title,
  fullWidth,
  children,
  noPadding,
  heightInUnits,
  onScroll,
  testId,
}: ContainerProps) => {
  const theme = useTheme();

  return (
    <ContainerRoot
      fullWidth={fullWidth}
      noPadding={noPadding}
      heightInUnits={heightInUnits}
      onScroll={onScroll}
      data-testid={testId}
    >
      {title ? (
        <Offset bottom={6}>
          <Text
            typography="SmallCapsHeadline"
            color={theme.colors.text.darkerGrey}
            data-testid="container-title"
          >
            {title}
          </Text>
        </Offset>
      ) : null}
      {children}
    </ContainerRoot>
  );
};
