import React from 'react';

export const BrandPaypalIcon = ({ width = 36 }: { width: number }) => {
  return (
    <svg
      width={width}
      height="28"
      viewBox="0 0 68 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.2012 1.40774C11.6852 0.905091 11.0669 0.520927 10.3888 0.28175C9.71079 0.042574 8.98919 -0.0459196 8.27381 0.022374H3.07198C2.89019 0.025915 2.71534 0.0931466 2.57763 0.212458C2.43991 0.331769 2.34798 0.495668 2.31771 0.675848L0.00289995 14.4772C-0.00444736 14.5432 0.0022051 14.6101 0.0224197 14.6734C0.0426342 14.7366 0.0759525 14.7949 0.120181 14.8442C0.164409 14.8936 0.218544 14.933 0.279022 14.9599C0.339501 14.9867 0.404951 15.0004 0.471064 15H3.07198C3.22758 14.9932 3.37761 14.9398 3.50276 14.8467C3.62791 14.7535 3.72246 14.6248 3.77423 14.4772L4.34643 10.7132C4.36575 10.533 4.45093 10.3664 4.58546 10.2457C4.72 10.1249 4.89433 10.0587 5.07468 10.0597H6.76528C7.5131 10.1466 8.27069 10.0779 8.99095 9.85787C9.71121 9.63788 10.3787 9.27132 10.952 8.78099C11.5253 8.29066 11.9921 7.68704 12.3233 7.00767C12.6545 6.32829 12.8432 5.5877 12.8774 4.83194C13.024 4.24646 13.0393 3.63559 12.9224 3.04343C12.8054 2.45127 12.5591 1.89259 12.2012 1.40774V1.40774ZM8.89803 5.04105C8.61193 6.97533 7.12941 6.97533 5.72491 6.97533H4.91863L5.46482 3.34202C5.48262 3.23562 5.53626 3.13861 5.61676 3.06723C5.69726 2.99585 5.79972 2.95444 5.90698 2.94994H6.27111C7.23345 2.94994 8.16978 2.94993 8.61193 3.49885C8.77384 3.71678 8.88384 3.96915 8.93344 4.23652C8.98304 4.50389 8.97092 4.77912 8.89803 5.04105Z"
        fill="#253B80"
      />
      <path
        d="M23.4355 5.24564H20.9834C20.8836 5.25113 20.7878 5.28722 20.7089 5.34909C20.6301 5.41097 20.5718 5.49568 20.542 5.59186V6.30904L20.3703 6.03701C19.9992 5.66022 19.5483 5.37322 19.0513 5.19759C18.5544 5.02197 18.0244 4.9623 17.5013 5.02307C16.1598 5.05442 14.8742 5.5713 13.8793 6.47935C12.8844 7.3874 12.2465 8.62604 12.082 9.96912C11.958 10.6286 11.9748 11.3072 12.1312 11.9596C12.2877 12.6121 12.5802 13.2234 12.9893 13.7528C13.3729 14.181 13.8486 14.5151 14.3798 14.7294C14.9111 14.9437 15.4839 15.0326 16.0545 14.9893C16.6763 14.9961 17.2929 14.8745 17.8664 14.6321C18.4399 14.3897 18.9582 14.0315 19.3895 13.5797V14.2969C19.3825 14.4111 19.4193 14.5235 19.4924 14.611C19.5654 14.6985 19.6691 14.7543 19.7818 14.7668H21.9642C22.1347 14.7747 22.302 14.7182 22.4334 14.6085C22.5649 14.4988 22.6511 14.3436 22.6753 14.1733L23.975 5.74024C24.0026 5.6661 24.0075 5.58531 23.989 5.50832C23.9706 5.43133 23.9297 5.3617 23.8716 5.30842C23.8135 5.25515 23.7408 5.22068 23.6631 5.20949C23.5854 5.1983 23.5061 5.21089 23.4355 5.24564ZM17.3051 12.3927C17.0054 12.4094 16.7056 12.3606 16.4262 12.2496C16.1469 12.1386 15.8946 11.9681 15.6867 11.7497C15.5076 11.4987 15.3806 11.2138 15.3131 10.9121C15.2457 10.6104 15.2393 10.2981 15.2944 9.99385C15.3719 9.31817 15.6911 8.69422 16.1922 8.23895C16.6933 7.78368 17.3418 7.52835 18.0163 7.52082C18.3202 7.50691 18.6233 7.56189 18.9034 7.68173C19.1835 7.80156 19.4334 7.98319 19.6347 8.21327C19.8352 8.44783 19.9777 8.72713 20.0501 9.02812C20.1226 9.32911 20.1231 9.64317 20.0515 9.94438C19.9783 10.6241 19.6562 11.2518 19.1482 11.7047C18.6403 12.1575 17.983 12.4028 17.3051 12.3927Z"
        fill="#253B80"
      />
      <path
        d="M37.6113 5.06923H35.1094C34.9898 5.06783 34.8719 5.09466 34.7668 5.14717C34.6617 5.19968 34.5729 5.27611 34.5089 5.36921L31.0813 9.98421L29.6301 5.48458C29.583 5.34636 29.4901 5.22524 29.3644 5.13831C29.2388 5.05138 29.0867 5.003 28.9296 5.00001H26.4277C26.3587 4.99965 26.2906 5.01478 26.2294 5.0441C26.1682 5.07341 26.1156 5.11602 26.0762 5.16826C26.0368 5.2205 26.0117 5.28079 26.0032 5.34394C25.9947 5.40709 26.003 5.47119 26.0274 5.53073L28.7545 13.007L26.2525 16.376C26.2061 16.4349 26.1783 16.5046 26.1724 16.5773C26.1664 16.6499 26.1825 16.7227 26.2189 16.7873C26.2553 16.852 26.3104 16.9061 26.3782 16.9435C26.4461 16.9809 26.5238 17.0001 26.6028 16.999H29.1047C29.2223 17.0051 29.3396 16.9828 29.4449 16.9342C29.5502 16.8857 29.64 16.8125 29.7052 16.7221L37.9616 5.62303C37.9899 5.56587 38.0029 5.50328 37.9995 5.44052C37.9961 5.37776 37.9764 5.31667 37.9421 5.26239C37.9077 5.20812 37.8598 5.16224 37.8022 5.12862C37.7446 5.09501 37.6792 5.07464 37.6113 5.06923V5.06923Z"
        fill="#253B80"
      />
      <path
        d="M49.2368 1.40682C48.7194 0.905587 48.1005 0.522342 47.4222 0.283244C46.7438 0.0441455 46.0222 -0.0451778 45.3063 0.0213673H39.9182C39.7405 0.020877 39.5692 0.088018 39.4388 0.209275C39.3084 0.330532 39.2286 0.496901 39.2154 0.674885L37.0029 14.4772C36.9955 14.5432 37.0022 14.6101 37.0224 14.6733C37.0427 14.7366 37.076 14.7948 37.1203 14.8442C37.1645 14.8936 37.2187 14.933 37.2792 14.9599C37.3398 14.9867 37.4053 15.0004 37.4714 15H40.2306C40.3555 14.9943 40.4743 14.9436 40.5651 14.8572C40.6559 14.7708 40.7127 14.6545 40.7251 14.5295L41.3498 10.5822C41.3745 10.4042 41.4613 10.2408 41.5948 10.1211C41.7283 10.0014 41.8998 9.93318 42.0787 9.92869H43.7966C44.5437 10.0078 45.2989 9.93358 46.0165 9.71061C46.7341 9.48765 47.3991 9.12056 47.9712 8.63163C48.5432 8.1427 49.0104 7.54213 49.3443 6.86638C49.6782 6.19062 49.8719 5.45377 49.9136 4.70055C50.0292 4.13415 50.0288 3.55005 49.9125 2.9838C49.7961 2.41755 49.5662 1.881 49.2368 1.40682V1.40682ZM45.905 5.1188C45.6447 7.05322 44.161 7.05321 42.7294 7.05321H41.9225L42.4951 3.41966C42.4985 3.3647 42.5125 3.31093 42.5366 3.26145C42.5606 3.21197 42.5942 3.16774 42.6353 3.13131C42.6764 3.09488 42.7243 3.06696 42.7762 3.04915C42.8281 3.03135 42.8829 3.02401 42.9376 3.02755H43.3281C44.2912 3.02755 45.2022 3.02755 45.6708 3.5765C45.8124 3.78919 45.9067 4.03009 45.9471 4.28274C45.9875 4.53539 45.9732 4.79382 45.905 5.04038V5.1188Z"
        fill="#179BD7"
      />
      <path
        d="M61.5228 5.24672H59.0497C58.9469 5.24416 58.8464 5.27723 58.7653 5.34033C58.6841 5.40343 58.6273 5.49266 58.6045 5.59287V6.30991L58.4314 6.03793C58.0631 5.66017 57.6127 5.37224 57.1153 5.19645C56.6178 5.02066 56.0865 4.96171 55.5625 5.02419C54.2096 5.05553 52.913 5.57232 51.9096 6.48019C50.9061 7.38806 50.2627 8.62646 50.0969 9.96928C49.9583 10.6282 49.9685 11.3097 50.1268 11.9642C50.2851 12.6186 50.5875 13.2295 51.012 13.7523C51.3927 14.1814 51.8678 14.5164 52.3999 14.7309C52.932 14.9454 53.5067 15.0336 54.0787 14.9885C54.7093 14.9943 55.3346 14.8724 55.9169 14.6302C56.4992 14.388 57.0264 14.0305 57.4669 13.5792V14.2962C57.4598 14.3554 57.4655 14.4155 57.4835 14.4723C57.5014 14.5292 57.5313 14.5815 57.571 14.626C57.6108 14.6704 57.6596 14.7059 57.7141 14.73C57.7686 14.7542 57.8277 14.7664 57.8873 14.766H60.0884C60.2561 14.7677 60.4188 14.7085 60.5461 14.5994C60.6735 14.4902 60.7569 14.3386 60.7809 14.1726L61.9927 5.74122C62.0051 5.67576 62.0017 5.60831 61.9829 5.54441C61.964 5.48051 61.9303 5.42199 61.8844 5.3737C61.8385 5.3254 61.7818 5.2887 61.7189 5.26662C61.656 5.24454 61.5888 5.23772 61.5228 5.24672ZM55.3647 12.3924C55.0585 12.411 54.7518 12.3632 54.4658 12.2523C54.1798 12.1413 53.9212 11.9698 53.7077 11.7495C53.526 11.4994 53.399 11.2137 53.3351 10.9113C53.2712 10.6088 53.2717 10.2963 53.3367 9.994C53.404 9.31847 53.7193 8.69183 54.2218 8.23517C54.7242 7.77851 55.3781 7.52423 56.0572 7.52146C56.372 7.49375 56.6888 7.54281 56.9805 7.66443C57.2722 7.78605 57.53 7.9766 57.7319 8.21972C57.9337 8.46285 58.0736 8.7513 58.1394 9.06033C58.2052 9.36936 58.1951 9.68975 58.1099 9.994C58.0358 10.6648 57.7126 11.2834 57.2043 11.7275C56.696 12.1716 56.0395 12.4088 55.3647 12.3924V12.3924Z"
        fill="#179BD7"
      />
      <path
        d="M67.6008 0.0010238H65.2685C65.1732 -0.00644882 65.0789 0.0271965 65.0052 0.0950235C64.9314 0.16285 64.8837 0.259691 64.872 0.365582L63.0062 14.4791C62.9959 14.5435 62.9984 14.6095 63.0137 14.6726C63.0289 14.7357 63.0565 14.7942 63.0944 14.844C63.1323 14.8938 63.1796 14.9336 63.2329 14.9606C63.2862 14.9875 63.3442 15.001 63.4027 14.9999H65.3851C65.5433 15.0018 65.6967 14.9394 65.8168 14.8245C65.9369 14.7095 66.0156 14.5498 66.0382 14.375L67.9972 0.495781C68.0039 0.433429 67.9985 0.370217 67.9816 0.310345C67.9646 0.250472 67.9365 0.19531 67.899 0.148517C67.8615 0.101725 67.8155 0.0643737 67.7641 0.0389445C67.7127 0.0135153 67.657 0.000587582 67.6008 0.0010238Z"
        fill="#179BD7"
      />
    </svg>
  );
};
