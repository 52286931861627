import React from 'react';
import { FeatureFlagName } from '@/flags/flagKeys';
import { UserType } from '@/generated/types';
import { Platform } from '@/utils/useragent';
import RouteBuilder from '@/routes/routeBuilders';
import { GroverStore } from '../axiosRequest';

type User = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string | null;
  company: any | null;
  type: UserType;
};

export interface ApplicationDataContextType {
  storeCode: string;
  countryCode: string;
  userId: string;
  activeStore: Partial<GroverStore>;
  user: Partial<User>;
  isConsolidatedBilling?: boolean;
  billingDay?: number | null;
  flags: Record<FeatureFlagName, boolean>;
  platform: Platform;
  rawUseragent: string;
  routeBuilder: RouteBuilder;
}

const ApplicationDataContext = React.createContext<ApplicationDataContextType | null>(null);
export const ApplicationDataProvider = ApplicationDataContext.Provider;

export const useApplicationData = () => {
  const contextData = React.useContext(ApplicationDataContext) as ApplicationDataContextType;
  const { activeStore, user, isConsolidatedBilling, billingDay, userId, storeCode, countryCode, flags, platform, rawUseragent, routeBuilder } = contextData;
  return { activeStore, user, isConsolidatedBilling, billingDay, userId, storeCode, countryCode, flags, platform, rawUseragent, routeBuilder };
};
