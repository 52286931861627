import 'dayjs/locale/de';
import 'dayjs/locale/es';
import 'dayjs/locale/nl';

import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';

export enum LOCALE {
  EN = 'en',
  DE = 'de',
  NL = 'nl',
  ES = 'es',
}

dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);
dayjs.extend(duration);

enum DateFormat {
  en = 'MMMM DD, YYYY',
  enMobile = 'MMM DD',
  nl = 'DD MMMM YYYY',
  nlMobile = 'DD MMM',
  default = 'DD. MMMM YYYY',
  defaultMobile = 'DD. MMM',
}

type FormatDate = (args: {
  date: string;
  locale: LOCALE;
  dateFormat?: string;
  mobile?: boolean;
}) => string;
export const formatDate: FormatDate = ({ date, locale, dateFormat, mobile }): string => {
  if (locale === LOCALE.EN) {
    const format = mobile ? DateFormat.enMobile : DateFormat.en;
    return dayjs(date).format(dateFormat || format);
  }
  if (locale === LOCALE.NL) {
    const format = mobile ? DateFormat.nlMobile : DateFormat.nl;
    return dayjs(date)
      .locale('nl')
      .format(dateFormat || format);
  }
  if (locale === LOCALE.ES) {
    const format = mobile ? DateFormat.defaultMobile : DateFormat.default;
    return dayjs(date)
      .locale('es')
      .format(dateFormat || format);
  }

  const format = mobile ? DateFormat.defaultMobile : DateFormat.default;
  return dayjs(date)
    .locale('de')
    .format(dateFormat || format);
};
