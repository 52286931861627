import styled from 'styled-components';
import { Text } from '@/components/Text';
import { ExpiryStatus } from '@/components/PaymentMethod/helpers';

export const SecondaryText = styled(Text) <{ status?: ExpiryStatus }>`
  white-space: nowrap;
  color: ${({ status, theme }) => {
    if (status === ExpiryStatus.transparent) return theme.colors.background.white;
    if (status === ExpiryStatus.expired) return theme.colors.status.baseRed;
    if (status === ExpiryStatus.expiringSoon) return theme.colors.status.darkYellow;
    return theme.colors.text.baseGrey;
  }};
`;

export const TextEllipsis = styled(Text)`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const BrandWrapper = styled.div<{ monochrome?: boolean }>`
  filter: ${({ monochrome }) => (monochrome ? 'grayscale(1)' : 'none')};
`;
