import styled from 'styled-components';
import { Group, GroupProps } from '@/components/Group';

export const Centred = styled.div`
  display: flex;
  justify-content: center;
`;

export const InputWrapper = styled.div`
  width: 50%;
`;

export const FullWidthGroup = styled(Group)<GroupProps>`
  width: 100%;
`;

export const Form = styled.form<{ visible: boolean }>`
  display: ${(props) => (props.visible ? 'block' : 'none')};
`;

export const LoaderWrapper = styled.div`
  position: absolute;
  top: 300px;
  width: calc(100% - 32px);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PaymentFormInputIframe = styled.div`
  height: 56px;
  z-index: 2;
`;