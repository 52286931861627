import React, { useCallback, useState, useEffect } from 'react';
import { FlowPage } from '@/components/FlowPage';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useApplicationData } from '@/providers/applicationData';
import { PaymentMethodListScreen } from './screens/PaymentMethodListScreen';
import { BulkUpdateLoadingScreen } from './screens/BulkUpdateLoadingScreen';
import { ChangePaymentMethodSuccessScreen } from './screens/ChangePaymentMethodSuccessScreen';
import { ChangePaymentMethodLoadingScreen } from './screens/ChangePaymentMethodLoadingScreen';
import { CurrentScreen, ScreenCommon, ScreenData, ScreenKey } from './types';
import { trackChangePaymentMethodCancelEvent, trackYourProfilePageVisitedEvent } from '@/analytics';
import { QueryParamName } from '@/constants';
import { DocumentTitle, PageName } from '@/components/DocumentTitle';

const Screens: Record<ScreenKey, React.FunctionComponent<ScreenCommon>> = {
  [ScreenKey.paymentMethodList]: PaymentMethodListScreen,
  [ScreenKey.bulkUpdateLoadingScreen]: BulkUpdateLoadingScreen,
  [ScreenKey.changePaymentMethodLoadingScreen]: ChangePaymentMethodLoadingScreen,
  [ScreenKey.changePaymentMethodSuccessScreen]: ChangePaymentMethodSuccessScreen,
};

export const ChangePaymentMethodPage = () => {
  const { paymentMethodId } = useParams<{ paymentMethodId: string }>();
  const [currentScreen, setCurrentScreen] = useState<CurrentScreen>({
    key: ScreenKey.paymentMethodList,
    data: { oldPaymentMethodId: paymentMethodId },
  });
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { userId, storeCode, routeBuilder } = useApplicationData();

  const contractId = searchParams.get(QueryParamName.subscriptionId);
  const Screen = Screens[currentScreen.key];

  useEffect(() => {
    trackYourProfilePageVisitedEvent({
      path: location.pathname,
    });
  }, []);

  const handleSetScreen = useCallback((screenKey: ScreenKey, data: ScreenData) => {
    const finalData = {
      ...currentScreen.data,
      ...data,
    };

    setCurrentScreen({ key: screenKey, data: finalData });
  }, []);

  const handleClose = useCallback(() => {
    trackChangePaymentMethodCancelEvent({
      userId,
      store: storeCode,
      bulk: !contractId,
    });

    if (contractId) {
      return location.href = routeBuilder.buildSubscriptionDetailsRoute({ subscriptionId: contractId });
    }

    if (paymentMethodId) {
      return navigate(routeBuilder.buildPaymentMethodDetailsRoute({ billingAccountId: paymentMethodId }));
    }

    navigate(routeBuilder.buildPaymentMethodListRoute());
  }, [contractId, paymentMethodId, storeCode]);

  const handleBack = useCallback(() => {
    handleClose();
  }, []);

  const withMobileBackButton = currentScreen.key === ScreenKey.paymentMethodList;

  return (
    <FlowPage withMobileBackButton={withMobileBackButton} onClose={handleClose} onBack={handleBack}>
      <DocumentTitle
        pageName={contractId ? PageName.changePaymentMethod : PageName.bulkPaymentMethod}
      />
      <Screen
        setScreen={handleSetScreen}
        currentScreen={currentScreen}
        onClose={handleClose}
        onBack={handleBack}
      />
    </FlowPage>
  );
};
