import { ApolloLink, NextLink, Operation } from '@apollo/client';
import { HeaderKey } from '../types';
import { logger } from '@/logger';

type OperationType = 'query' | 'mutation' | 'subscription';

function getOperationType(operation: Operation): OperationType | 'request' {
  const [definition] = operation.query.definitions;

  if (definition === undefined) {
    return 'request';
  }

  if (definition.kind !== 'OperationDefinition') {
    return 'request';
  }

  return definition.operation;
}

function getHeaderInformation(operation: Operation): Record<string, string> {
  const context = operation.getContext();
  const { headers } = context;

  return {
    ...(headers[HeaderKey.STORE] && {
      storeCode: headers[HeaderKey.STORE],
    }),
    ...(headers[HeaderKey.LOCALE] && {
      locale: headers[HeaderKey.LOCALE],
    }),
  };
}

export const loggerLink = new ApolloLink((operation: Operation, forward: NextLink) => {
  const { operationName } = operation;
  const operationType = getOperationType(operation);
  const headers = getHeaderInformation(operation);
  operation.setContext({ start: new Date() });

  logger.info(`Performed GraphQL ${operationType} ${operationName}`, {
    ...headers,
    operationName,
    operationType,
  });

  return forward(operation).map((result) => {
    if (!result.errors) {
      const elapsedTime = Date.now() - operation.getContext().start;

      logger.info(`Received GraphQL response for ${operationType} ${operationName}`, {
        ...headers,
        operationName,
        operationType,
        elapsedTime,
      });
    }
    return result;
  });
});
