import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import { createClient } from '@/gql/apolloClient';
import './index.css';
import App from './App';
import { initI18n } from './i18n';
import { getLocale } from './utils/cookies';
import { DEFAULT_LOCALE } from './pages/YourPaymentsPage/constants';

const graphqlClientInstance = createClient();

initI18n(getLocale() || DEFAULT_LOCALE);

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={graphqlClientInstance as any}>
      <App />
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
