import styled from 'styled-components';

export const StatContainer = styled.div<{ clickable?: string }>`
  width: 252px;
  height: 104px;
  cursor:  ${({ clickable }) => clickable ? 'pointer' : 'unset'};

  @media ${({ theme }) => theme.screenSizes.tabletMax} {
    width: 100%;
    height: 108px;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

