import React from 'react';
import { useNavigate } from 'react-router-dom';

interface LinkProps {
  to: string;
  children: JSX.Element | string;
  onClick?: () => void;
}

export const Link = ({ to, children, onClick }: LinkProps) => {
  const navigate = useNavigate();
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    if (onClick) onClick();
    navigate(to);
  };

  return (
    <a href={to} onClick={handleClick}>
      {children}
    </a>
  );
};
