import { gql } from '@apollo/client';

export const PAYMENT_OPTIONS = gql`
  query PaymentOptions {
    paymentOptions {
      id
      type
      ixopay {
        publicKey
      }
    }
  }
`;
