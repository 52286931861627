import React from 'react';
import { CircleLoader } from '@/components/CircleLoader';
import { useTranslation } from 'react-i18next';
import { Offset } from '@/components/Offset';
import { Group } from '@/components/Group';
import { Text } from '@/components/Text';
import { Actions, Button, Content, Loader } from './styles';
import { tk } from '@/i18n/translationKeys';
import { useTheme } from 'styled-components';

interface DeleteDialogProps {
  onConfirm: () => void;
  onCancel: () => void;
  inProgress?: boolean;
  text: string;
}

export const DeleteDialog = ({ onConfirm, onCancel, inProgress, text }: DeleteDialogProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <>
      <Content invisible={inProgress}>
        <Offset bottom={4}>
          <Text typography="Subheadline" color={theme.colors.text.darkerGrey}>
            {t(tk.deletePaymentMethodConfirmationDialogTitle)}
          </Text>
        </Offset>

        <Offset bottom={8}>
          <Text typography="Paragraph" color={theme.colors.text.darkGrey}>
            {text}
          </Text>
        </Offset>

        <Actions>
          <Button onClick={onCancel}>
            {t(tk.deletePaymentMethodConfirmationDialogCancelButtonText)}
          </Button>
          <Button color="red" onClick={onConfirm}>
            {t(tk.deletePaymentMethodConfirmationDialogConfirmButtonText)}
          </Button>
        </Actions>
      </Content>

      {inProgress ? (
        <Loader>
          <Group centered vertical gap={3}>
            <CircleLoader />

            <Text typography="Paragraph" color={theme.colors.text.darkGrey}>
              {t(tk.deletePaymentMethodConfirmationDialogLoadingText)}
            </Text>
          </Group>
        </Loader>
      ) : null}
    </>
  );
};
