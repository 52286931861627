import { AxiosRequestConfig } from 'axios';
import {
  getAccessToken,
  getUserId,
  getLocale,
  getStore,
  getImpersonatedUserId,
  getImpersonatedUserCompanyId,
  getDeviceId,
} from '../utils/cookies';
import { PaymentHeaders } from './headers';

function headersInterceptor(config: AxiosRequestConfig): AxiosRequestConfig {
  try {
    const accessToken = getAccessToken();
    const userId = getUserId();
    const deviceIdCookie = getDeviceId();
    const locale = getLocale();
    const storeCode = getStore();
    const impersonatedUserId = getImpersonatedUserId();
    const impersonatedUserCompanyId = getImpersonatedUserCompanyId();

    const headers = {
      ...config.headers,
      ...(userId && { [PaymentHeaders.USER_ID]: userId }),
      ...(accessToken && { [PaymentHeaders.AUTHORIZATION]: `Bearer ${accessToken}` }),
      ...(deviceIdCookie && { [PaymentHeaders.DEVICE_ID]: deviceIdCookie }),
      ...(locale && { [PaymentHeaders.LOCALE]: locale }),
      ...(storeCode && { [PaymentHeaders.STORE_CODE]: storeCode }),
      ...(impersonatedUserId && { [PaymentHeaders.IMPERSONATED_USER_ID]: impersonatedUserId }),
      ...(impersonatedUserCompanyId && {
        [PaymentHeaders.IMPERSONATED_USER_COMPANY_ID]: impersonatedUserCompanyId,
      }),
    };

    //TODO: Fix this type
    //@ts-ignore
    return { ...config, headers };
  } catch (error) {
    return config;
  }
}

export { headersInterceptor };
