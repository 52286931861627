import React from 'react';
import { useTheme } from 'styled-components';

export const ByInvoiceIcon = ({ width = 36 }: { width: number }) => {
  const theme = useTheme();
  const defaultColor = theme.colors.text.darkerGrey;
  const finalWidth = width / 3; // the icon takes one third of normal icon width

  return (
    <svg width={finalWidth} viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 3.82843V5H10.1716L9 3.82843ZM8 0L14 6V17C14 17.5523 13.5523 18 13 18H1C0.447716 18 0 17.5523 0 17V1C0 0.447716 0.447715 0 1 0H8ZM7 2V5C7 6.10457 7.89543 7 9 7H12V16H2V2H7Z"
        fill={defaultColor}
      />
    </svg>
  );
};
