import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLifecycles, useUpdateEffect } from 'react-use';
import { attachWebViewAPI } from './attachWebViewAPI';
import { addEventListener, fireEvent, removeEventListener } from './eventHandlers';
import { WebViewEventName } from './types';
import { loadBrowserEnv } from '@/config';
import { useApplicationData } from '@/providers/applicationData';
import { getLocale, setAccessToken, setRefreshToken } from '@/utils/cookies';

const { HOST_BASE_URL } = loadBrowserEnv();

export function setAuthenticationCookies({
  accessToken,
  refreshToken,
  expiresInSeconds,
}: {
  accessToken: string;
  refreshToken: string;
  expiresInSeconds: number;
}): void {
  const accessTokenExpirationDate = new Date(Date.now() + expiresInSeconds * 1000);

  setAccessToken(accessToken, {
    expires: accessTokenExpirationDate,
  });
  setRefreshToken(refreshToken, {
    expires: accessTokenExpirationDate,
  });
}

export const WebViewConnector: FC<{}> = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { i18n } = useTranslation();
  const { activeStore } = useApplicationData();
  const { language } = i18n;

  useUpdateEffect(() => {
    fireEvent(WebViewEventName.ROUTE_CHANGED, { path: pathname });
  }, [pathname]);

  const handleAuthTokens = useCallback(({ authToken, refreshToken, maxAge }: any) => {
    // TODO fix any type
    setAuthenticationCookies({
      accessToken: authToken,
      refreshToken,
      expiresInSeconds: maxAge,
    });
  }, []);

  const handleAppNavigation = ({ path }: { path: string }) => {
    const isInternal = path.includes('payment-methods');
    const fullPath = `/${activeStore.code}`;

    if (isInternal) {
      navigate(fullPath);
    } else {
      window.location.href = `${HOST_BASE_URL}/${fullPath}`;
    }
  };

  useLifecycles(
    () => {
      attachWebViewAPI();

      addEventListener(WebViewEventName.DEVICE_NAVIGATION_CLICK, handleAppNavigation);
      addEventListener(WebViewEventName.USER_LOGIN, handleAuthTokens);
      //addEventListener(WebViewEventName.USER_LOGOUT, handleLogout);
      addEventListener(WebViewEventName.TOKENS_UPDATED, handleAuthTokens);

      if (activeStore) {
        fireEvent(WebViewEventName.GET_STORE_SUCCESS, {
          action: {
            store: {
              storeId: activeStore.store_id,
              storeCode: activeStore.code,
              activeLanguage: language,
            },
          },
        });
      }
    },
    () => {
      removeEventListener(WebViewEventName.DEVICE_NAVIGATION_CLICK, handleAppNavigation);
      removeEventListener(WebViewEventName.USER_LOGIN, handleAuthTokens);
      //removeEventListener(WebViewEventName.USER_LOGOUT, handleLogout);
      removeEventListener(WebViewEventName.TOKENS_UPDATED, handleAuthTokens);
    }
  );

  return null;
};
