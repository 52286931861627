import React from 'react';
import { useTheme } from 'styled-components';

export const RefreshIcon = ({ color }: { color?: string }) => {
  const theme = useTheme();
  const defaultColor = theme.colors.text.darkerGrey;

  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.99533 0.5C11.273 0.5 13.3291 1.42755 14.8183 2.92195L17.2403 0.5V7.71431H10.0259L13.3497 4.39057C12.2315 3.27236 10.701 2.56123 8.99533 2.56123C5.57884 2.56123 2.81163 5.32843 2.81163 8.74492H0.760709C0.760709 4.1896 4.44001 0.5 8.99533 0.5Z"
        fill={defaultColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.00469 17.5039C6.72703 17.5039 4.67095 16.5763 3.18171 15.082L0.759766 17.5039V10.2896H7.97407L4.65034 13.6133C5.76856 14.7315 7.29902 15.4427 9.00469 15.4427C12.4212 15.4427 15.1884 12.6755 15.1884 9.25898H17.2393C17.2393 13.8143 13.56 17.5039 9.00469 17.5039Z"
        fill={defaultColor}
      />
    </svg>
  );
};
