import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Toast } from '@/components/Toast';
import { Text } from '@/components/Text';
import { Group } from '@/components/Group';
import { Offset } from '@/components/Offset';
import { CircleLoader } from '@/components/CircleLoader';
import {
  BULK_UPDATE_LOCAL_STORAGE_KEY_PREFIX,
  BULK_UPDATE_POLLING_ENABLED_FOR,
  BULK_UPDATE_POLLING_INTERVAL,
} from '@/pages/ChangePaymentMethodPage/constants';
import { useApplicationData } from '@/providers/applicationData';
import { ScreenCommon } from '@/pages/ChangePaymentMethodPage/types';
import { tk } from '@/i18n/translationKeys';
import { Height } from '../../ChangePaymentMethodPage.styles';
import { useQuery } from '@apollo/client';
import { ToastText } from '@/pages/ChangePaymentMethodPage/screens/BulkUpdateLoadingScreen/ToastText';
import { setItemWithExpiry } from '@/utils/localStorage';
import {
  getPaymentMethodDisplayType,
  getPrimaryText,
  paymentMethodDisplayTextsMap,
} from '@/utils/paymentMethods';
import { FeatureName, LogPrefix, logger } from '@/logger';
import { PAYMENT_METHOD_OPERATION } from '@/gql/queries/paymentMethods';
import { PaymentMethodOperationStatus, PaymentMethodOperationStatusQuery, PaymentMethodOperationStatusQueryVariables } from '@/generated/types';
import { PaymentMethodWithContracts } from '@/types/paymentMethods';
import { useTheme } from 'styled-components';

const twoHours = 2 * 60 * 60;

export const BulkUpdateLoadingScreen = ({ currentScreen }: ScreenCommon) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { routeBuilder } = useApplicationData();
  const { operationId, oldPaymentMethod, oldPaymentMethodId } = currentScreen.data;

  const { data, stopPolling } = useQuery<
    PaymentMethodOperationStatusQuery, 
    PaymentMethodOperationStatusQueryVariables
  >(PAYMENT_METHOD_OPERATION, {
    pollInterval: BULK_UPDATE_POLLING_INTERVAL,
    variables: { paymentMethodOperationId: operationId as string },
    errorPolicy: 'none',
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
  });

  const operationSuccess = data?.paymentMethodOperation?.status === PaymentMethodOperationStatus.SUCCESS;
  const operationError = data?.paymentMethodOperation?.status === PaymentMethodOperationStatus.ERROR;

  useEffect(() => {
    const { newPaymentMethod, deleteMode } = currentScreen.data;

    if (!newPaymentMethod) {
      return;
    }

    if (operationError) {
      stopPolling();

      navigate(routeBuilder.buildPaymentMethodDetailsRoute({ billingAccountId: oldPaymentMethodId }));
      logger.error(LogPrefix.Failure, { feature: FeatureName.BulkUpdate });

      toast(
        <Toast
          type="failure"
          mobile={false}
          title={t(tk.bulkUpdateErrorToastTitle)}
          text={
            <ToastText
              paymentMethod={oldPaymentMethod as PaymentMethodWithContracts}
              prefixText={t(tk.bulkUpdateErrorToastTextPrefix)}
            />
          }
          button={{
            text: t(tk.bulkUpdateErrorToastButtonText),
            onClick: () =>
              navigate(
                routeBuilder.buildPaymentMethodBulkUpdateRoute({
                  paymentMethodId: oldPaymentMethodId as string,
                })
              ),
          }}
        />
      );

      return;
    }

    if (operationSuccess) {
      stopPolling();
      const toastPrefixText = t(tk.bulkUpdateSuccessToastText);

      logger.info(LogPrefix.Success, { feature: FeatureName.BulkUpdate });

      toast(
        <Toast
          type="success"
          mobile={false}
          title={t(tk.paymentMethodBulkSuccessToastTitle)}
          text={<ToastText paymentMethod={newPaymentMethod} prefixText={toastPrefixText} />}
          button={{
            text: t(tk.paymentMethodBulkSuccessToastButtonText),
            onClick: () => navigate(routeBuilder.buildPaymentMethodDetailsRoute({ billingAccountId: newPaymentMethod.id }))
          }}
        />
      );

      if (deleteMode) {
        logger.info(LogPrefix.Success, { feature: FeatureName.DeletePaymentMethod });
        toast(
          <Toast
            type="success"
            mobile={false}
            title={t(tk.deletePaymentMethodSuccessToastTitle)}
            text={t(tk.deletePaymentMethodSuccessToastGenericText)}
          />
        );
        navigate(routeBuilder.buildPaymentMethodListRoute());
      } else {
        navigate(routeBuilder.buildPaymentMethodDetailsRoute({ billingAccountId: oldPaymentMethodId }));
      }
    }
  }, [operationError, operationSuccess, currentScreen]);

  useEffect(() => {
    const timeoutId = setTimeout(async () => {
      stopPolling();
      const { newPaymentMethod, oldPaymentMethod, deleteMode } = currentScreen.data;
      const paymentMethodToDisplayInNotification = deleteMode ? oldPaymentMethod : newPaymentMethod;

      if (!paymentMethodToDisplayInNotification) {
        return;
      }

      const primaryText = getPrimaryText({
        paymentMethod: paymentMethodToDisplayInNotification,
        t,
      });
      const displayType = getPaymentMethodDisplayType(paymentMethodToDisplayInNotification);
      const noticeTypeText = paymentMethodDisplayTextsMap[displayType];
      const localStorageData = {
        deleteMode,
        paymentMethodHighlight: `${noticeTypeText}: ${primaryText}.`,
      };

      logger.error(LogPrefix.Failure, { feature: FeatureName.BulkUpdate });

      setItemWithExpiry(
        `${BULK_UPDATE_LOCAL_STORAGE_KEY_PREFIX}${oldPaymentMethodId}`,
        localStorageData,
        twoHours
      );

      navigate(routeBuilder.buildPaymentMethodDetailsRoute({ billingAccountId: oldPaymentMethodId }));
    }, BULK_UPDATE_POLLING_ENABLED_FOR);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <Height units={104}>
      <Offset top={36}>
        <Group centered vertical gap={8} mobileGap={8}>
          <CircleLoader />
          <Text
            centered
            typography="Paragraph"
            color={theme.colors.text.baseGrey}
            data-testid="fake-loading-screen-text"
          >
            {t(tk.commonLoadingText)}
          </Text>
        </Group>
      </Offset>
    </Height>
  );
};
