import React from 'react';

import { SpinnerCircle, SpinnerContainer } from './CircleLoader.styles';

type SpinnerPropsType = {
  className?: string;
  style?: object;
};

export const Spinner = ({ className, style }: SpinnerPropsType): JSX.Element => (
  <SpinnerContainer className={className} style={style}>
    <SpinnerCircle />
  </SpinnerContainer>
);
