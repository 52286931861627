import React from 'react';
import { Root, WrapperRootProps } from './Wrapper.styles';

interface WrapperProps extends WrapperRootProps {
  children: React.ReactElement | Array<React.ReactElement>;
}

export const Wrapper = (props: WrapperProps) => {
  const { children, ...rootProps } = props;
  return <Root {...rootProps}>{children}</Root>;
};
