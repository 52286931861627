import { useEffect, useState, RefObject } from 'react';
import { useInterval } from 'react-use';

type InfiniteScrollHookDependencies = {
  loading: boolean;
  fetchNextPage: () => void;
  hasNextPage: boolean;
  scrollableElementRef: RefObject<HTMLElement> | RefObject<Window>;
  interval?: number;
  threshold?: number;
  skip?: boolean;
};

export function useInfiniteScroll({
  skip = false,
  loading,
  interval = 500,
  threshold = 300,
  hasNextPage,
  fetchNextPage,
  scrollableElementRef,
}: InfiniteScrollHookDependencies): void {
  const [shouldCheck, setShouldCheck] = useState(false);

  useEffect(() => {
    setShouldCheck(!loading && hasNextPage && !skip);
  }, [loading, hasNextPage, skip]);

  useInterval(
    () => {
      const element = scrollableElementRef?.current;
      let isNothingToScroll = false;
      let scrolledToBottom = 0;
      let triggerFetchArea = 0;

      if (element && element instanceof Window) {
        isNothingToScroll = document.body.scrollHeight <= element.innerHeight;
        scrolledToBottom = element.innerHeight + element.scrollY;
        triggerFetchArea = document.body.scrollHeight - threshold;
      } else if (element) {
        isNothingToScroll = element.scrollHeight === element.clientHeight;
        scrolledToBottom = element.clientHeight + element.scrollTop;
        triggerFetchArea = element.scrollHeight - threshold;
      }

      if (scrolledToBottom > triggerFetchArea || isNothingToScroll) {
        fetchNextPage();
      }
    },
    shouldCheck ? interval : null
  );
}
