import { gql } from '@apollo/client';

const LINKED_CONTRACTS_FRAGMENT = gql`
  fragment LinkedContractsFragment on FlexDetails {
    ... on Flex {
      id
      billingTerms {
        nextPeriod {
          amountDue {
            inCents
            currency
          }
          starts
        }
      }
      goods {
        ... on FlexDevice {
          product {
            name
            imageUrl
            category {
              images {
                iconSvgPath
              }
            }
          }
        }
      }
    }
    ... on SubscriptionDetails {
      id
      note {
        ... on NextPaymentNote {
          date
        }
      }
      product {
        name
        imageUrl
        category {
          images {
            iconSvgPath
          }
        }
      }
      price {
        inCents
        currency
      }
    }
  }
`;

const PAYMENT_METHOD_FRAGMENT = gql`
  ${LINKED_CONTRACTS_FRAGMENT}

  fragment PaymentMethodFragment on PaymentMethod2 {
    status
    ... on CardPaymentMethod {
      id
      billingAccountId
      status
      brand
      lastFourDigits
      holderName
      expiryYear
      expiryMonth
      type
      linkedContracts {
        ...LinkedContractsFragment
      }
    }
    ... on PaypalPaymentMethod {
      id
      billingAccountId
      status
      accountName
      linkedContracts {
        ...LinkedContractsFragment
      }
    }
    ... on SepaPaymentMethod {
      id
      billingAccountId
      status
      createdAt
      linkedContracts {
        ...LinkedContractsFragment
      }
    }
    ... on ByInvoicePaymentMethod {
      id
      billingAccountId
      status
      viban
      linkedContracts {
        ...LinkedContractsFragment
      }
    }
  }
`;

export const PAYMENT_METHODS_WITH_CONTRACTS = gql`
  ${PAYMENT_METHOD_FRAGMENT}

  query PaymentMethodsWithContracts($filter: PaymentMethodsFilterType) {
    paymentMethods(filter: $filter) {
      ...PaymentMethodFragment
    }
  }
`;

export const SINGLE_PAYMENT_METHOD_WITH_CONTRACTS = gql`
  ${PAYMENT_METHOD_FRAGMENT}

  query PaymentMethodWithContracts($paymentMethodId: ID!) {
    paymentMethod(id: $paymentMethodId) {
      deletionLock {
        reason
      }
      ...PaymentMethodFragment
    }
  }
`;

export const SINGLE_PAYMENT_METHOD_STATUS = gql`
  query PaymentMethodStatus($paymentMethodId: ID!) {
    paymentMethod(id: $paymentMethodId) {
      status
      registrationErrorCode
    }
  }
`;

export const PAYMENT_METHOD_OPERATION = gql`
  query PaymentMethodOperationStatus($paymentMethodOperationId: ID!) {
    paymentMethodOperation(id: $paymentMethodOperationId) {
      id
      status
    }
  }
`;
