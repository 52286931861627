import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CircleLoader } from '@/components/CircleLoader';
import { ScreenCommon, ScreenKey } from '@/pages/ChangePaymentMethodPage/types';
import { Toast } from '@/components/Toast';
import { Text } from '@/components/Text';
import { Group } from '@/components/Group';
import { Offset } from '@/components/Offset';
import { Height } from '../../ChangePaymentMethodPage.styles';
import { tk } from '@/i18n/translationKeys';
import {
  CHANGE_PAYMENT_METHOD_POLLING_INTERVAL,
  CHANGE_PAYMENT_POLLING_ENABLED_FOR,
} from '../../constants';
import { useQuery } from '@apollo/client';
import {
  PaymentMethodOperationStatus,
  PaymentMethodOperationStatusQuery,
  PaymentMethodOperationStatusQueryVariables,
} from '@/generated/types';
import { FeatureName, LogPrefix, logger } from '@/logger';
import { toast } from 'react-toastify';
import { ToastText } from '../BulkUpdateLoadingScreen/ToastText';
import { useApplicationData } from '@/providers/applicationData';
import {
  PAYMENT_METHOD_OPERATION,
  SINGLE_PAYMENT_METHOD_WITH_CONTRACTS,
} from '@/gql/queries/paymentMethods';
import { QueryParamName } from '@/constants';
import { PaymentMethodWithContracts } from '@/types/paymentMethods';
import { useTheme } from 'styled-components';

export const ChangePaymentMethodLoadingScreen = ({ currentScreen, setScreen }: ScreenCommon) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { routeBuilder } = useApplicationData();
  const { operationId, oldPaymentMethod } = currentScreen.data;
  const subscriptionId = searchParams.get(QueryParamName.subscriptionId);
  const theme = useTheme();
  const { data, stopPolling } = useQuery<
    PaymentMethodOperationStatusQuery,
    PaymentMethodOperationStatusQueryVariables
  >(PAYMENT_METHOD_OPERATION, {
    pollInterval: CHANGE_PAYMENT_METHOD_POLLING_INTERVAL,
    variables: { paymentMethodOperationId: operationId as string },
    errorPolicy: 'none',
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
  });

  const operationSuccess =
    data?.paymentMethodOperation?.status === PaymentMethodOperationStatus.SUCCESS;
  const operationError =
    data?.paymentMethodOperation?.status === PaymentMethodOperationStatus.ERROR;

  const { data: paymentMethodData } = useQuery(SINGLE_PAYMENT_METHOD_WITH_CONTRACTS, {
    variables: { paymentMethodId: currentScreen.data.newPaymentMethodId },
    errorPolicy: 'none',
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    skip: !operationSuccess,
  });

  const newPaymentMethod = paymentMethodData?.paymentMethod;

  useEffect(() => {
    if (operationError) {
      stopPolling();

      navigate(
        routeBuilder.buildPaymentMethodDetailsRoute({ billingAccountId: oldPaymentMethod?.id })
      );
      logger.error(LogPrefix.Failure, { feature: FeatureName.ChangePaymentMethod });

      toast(
        <Toast
          type="failure"
          mobile={false}
          title={t(tk.bulkUpdateErrorToastTitle)}
          text={
            <ToastText
              paymentMethod={oldPaymentMethod as PaymentMethodWithContracts}
              prefixText={t(tk.bulkUpdateErrorToastTextPrefix)}
            />
          }
          button={{
            text: t(tk.bulkUpdateErrorToastButtonText),
            onClick: () =>
              navigate(
                routeBuilder.buildChangePaymentMethodRoute({
                  subscriptionId: subscriptionId as string,
                })
              ),
          }}
        />
      );

      return;
    }

    if (paymentMethodData) {
      stopPolling();
      const toastPrefixText = t(tk.bulkUpdateSuccessToastText);

      logger.info(LogPrefix.Success, { feature: FeatureName.ChangePaymentMethod });

      toast(
        <Toast
          type="success"
          mobile={false}
          title={t(tk.paymentMethodBulkSuccessToastTitle)}
          text={<ToastText paymentMethod={newPaymentMethod} prefixText={toastPrefixText} />}
          button={{
            text: t(tk.paymentMethodBulkSuccessToastButtonText),
            onClick: () =>
              navigate(
                routeBuilder.buildPaymentMethodDetailsRoute({
                  billingAccountId: newPaymentMethod.id,
                })
              ),
          }}
        />
      );

      return setScreen(ScreenKey.changePaymentMethodSuccessScreen, { newPaymentMethod });
    }
  }, [paymentMethodData, operationError]);

  useEffect(() => {
    const timeoutId = setTimeout(async () => {
      stopPolling();

      logger.error(LogPrefix.Failure, { feature: FeatureName.ChangePaymentMethod });
    }, CHANGE_PAYMENT_POLLING_ENABLED_FOR);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <Height units={104}>
      <Offset top={36}>
        <Group centered vertical gap={8} mobileGap={8}>
          <CircleLoader />
          <Text
            centered
            typography="Paragraph"
            color={theme.colors.text.baseGrey}
            data-testid="fake-loading-screen-text"
          >
            {t(tk.commonLoadingText)}
          </Text>
        </Group>
      </Offset>
    </Height>
  );
};
