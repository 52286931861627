import React, { useEffect } from 'react';
import { CircleLoader } from '@/components/CircleLoader';
import { useTranslation } from 'react-i18next';
import { Text } from '@/components/Text';
import { Group } from '@/components/Group';
import { Offset } from '@/components/Offset';
import { tk } from '@/i18n/translationKeys';
import { Actions, Height } from '../../AddPaymentMethodPage.styles';
import { ScreenCommon } from '../../types';
import { BrandPaypalIcon } from '@/icons/BrandPaypalIcon';
import { useScreenMatch } from '@/hooks/useScreenMatch';
import { PaymentOptionType } from '@/generated/types';
import { useCreatePaymentMethod } from '../../useCreatePaymentMethod';
import { useTheme } from 'styled-components';

export const PaypalRedirectionScreen = ({ paymentOptions }: ScreenCommon) => {
  const { t } = useTranslation();
  const { isMobile } = useScreenMatch();
  const theme = useTheme();
  const [createPaymentMethod] = useCreatePaymentMethod();

  useEffect(() => {
    createPaymentMethod({ option: PaymentOptionType.PAYPAL });
  }, [paymentOptions]);

  return (
    <Height units={68} mobile={isMobile}>
      <Offset top={36}>
        <Group centered vertical gap={8} mobileGap={8}>
          <CircleLoader />
          <Text typography="Paragraph" color={theme.colors.text.baseGrey}>
            {t(tk.paypalRedirectionText)}
          </Text>
        </Group>
      </Offset>

      <Actions mobile={isMobile}>
        <BrandPaypalIcon width={64} />
      </Actions>
    </Height>
  );
};
