import React from 'react';

export const InvalidIconOutlined = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.00065 12.3333C9.94591 12.3333 12.334 9.94518 12.334 6.99992C12.334 4.05466 9.94591 1.66659 7.00065 1.66659C4.05539 1.66659 1.66732 4.05466 1.66732 6.99992C1.66732 9.94518 4.05539 12.3333 7.00065 12.3333ZM7.00065 13.6666C3.31901 13.6666 0.333984 10.6816 0.333984 6.99992C0.333984 3.31828 3.31901 0.333252 7.00065 0.333252C10.6823 0.333252 13.6673 3.31828 13.6673 6.99992C13.6673 10.6816 10.6823 13.6666 7.00065 13.6666Z"
        fill="#EF432D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.33398 10.3333C6.33398 9.96506 6.63246 9.66659 7.00065 9.66659C7.36884 9.66659 7.66732 9.96506 7.66732 10.3333C7.66732 10.7014 7.36884 10.9999 7.00065 10.9999C6.63246 10.9999 6.33398 10.7014 6.33398 10.3333Z"
        fill="#EF432D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.33398 3.66659C6.33398 3.2984 6.63246 2.99992 7.00065 2.99992C7.36884 2.99992 7.66732 3.2984 7.66732 3.66659V8.33325C7.66732 8.70144 7.36884 8.99992 7.00065 8.99992C6.63246 8.99992 6.33398 8.70144 6.33398 8.33325V3.66659Z"
        fill="#EF432D"
      />
    </svg>
  );
};
