import qs from 'qs';
import { logger } from '@/logger';
import { loadBrowserEnv } from '@/config';
import {
  getLocale,
  getStore,
  removeAccessToken,
  removeRefreshToken,
  removeUserId,
} from '@/utils/cookies';
import { StoreCode } from '@/generated/types';

const browserEnv = loadBrowserEnv();
const { NODE_ENV, LOCAL_AUTH_AUTO_LOGIN } = browserEnv;

export const DEFAULT_LOCALE = 'de';

function getStoreCode(pathname?: string): StoreCode | undefined {
  const pathnameSource = pathname ?? window?.location?.pathname ?? '';

  if (!pathnameSource.startsWith('/')) {
    throw new TypeError('getStoreCode: pathname parameter is not valid');
  }

  const [storeCode] = pathnameSource.split('/').filter(Boolean);

  return storeCode as StoreCode;
}

export function forceLogout() {
  removeAccessToken();
  removeRefreshToken();
  removeUserId();

  const callback = window.location.pathname;
  const querystring = qs.stringify({ callback });
  const storeCodeFromUrl = getStoreCode();
  const storeCode = storeCodeFromUrl ?? getStore() ?? StoreCode.DE;
  const language = getLocale() || DEFAULT_LOCALE;
  const href = `${browserEnv.HOST_BASE_URL}/${storeCode}-${language}/auth/login?${querystring}`;

  if (NODE_ENV === 'development' && LOCAL_AUTH_AUTO_LOGIN === 'true') {
    return;
  }

  logger.info('redirected to auth', { href });
  window.location.href = href;
}
