import { getRefreshToken, setAccessToken, setRefreshToken } from '@/utils/cookies';
import { loadBrowserEnv } from '@/config';
import { addEventListenerWithPromise, fireEvent, isInWebView, WebViewEventName } from '../webView';
import { logger } from '@/logger';

const browserEnv = loadBrowserEnv();

export function isFunction(value: any): boolean {
  return typeof value === 'function';
}

export function isPromise<Payload = any>(promise: any): promise is Promise<Payload> {
  return isFunction(promise?.then) && isFunction(promise?.catch) && isFunction(promise?.finally);
}

export class PromiseManager {
  private static instance: Promise<RefreshTokenResult> | null = null;

  static get promise(): Promise<RefreshTokenResult> | null {
    return this.instance;
  }

  static set promise(value: Promise<RefreshTokenResult> | null) {
    if (!isPromise(value) && value !== null) {
      throw new TypeError(`Type of passed value "${typeof value}" is not supported`);
    }

    if (value === null) {
      this.instance = null;
    } else {
      this.instance = value.finally(() => {
        // Auto-cleaning up global promise instance after promise is resolved or rejected
        this.instance = null;
      });
    }
  }
}

export interface RefreshTokenResult {
  accessToken: string;
  refreshToken: string;
  expiresInSeconds: number;
}

function callRefreshQuery(refreshToken: string): Promise<RefreshTokenResult> {
  logger.info('Performed API call for refreshAccessToken');

  return fetch(`${browserEnv.API_BASE_URL}/api/v1/oauth/token`, {
    method: 'POST',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      grant_type: 'refresh_token',
      refresh_token: refreshToken,
    }),
  })
    .then((response) => response.json())
    .then((response) => {
      const {
        access_token: newAccessToken,
        refresh_token: newRefreshToken,
        expires_in: expiresInSeconds,
      } = response;
      if (newAccessToken && newRefreshToken) {
        logger.info('Received API response for refreshAccessToken');
        return {
          accessToken: newAccessToken,
          refreshToken: newRefreshToken,
          expiresInSeconds,
        };
      }

      logger.error(`API Error: refreshAccessToken: Unauthorized ${response.statusText}`);
      throw new Error('Unauthorized');
    });
}

function updateTokensAndNotifyApp({
  accessToken,
  refreshToken,
  expiresInSeconds,
  isAppBasedRefreshEnabled = false,
}: RefreshTokenResult & { isAppBasedRefreshEnabled?: boolean }) {
  setAccessToken(accessToken, { expires: expiresInSeconds });
  setRefreshToken(refreshToken, { expires: expiresInSeconds });

  if (!isAppBasedRefreshEnabled) {
    // Send updated tokens to the app
    fireEvent(WebViewEventName.AUTH_TOKEN_COOKIE, {
      authToken: accessToken,
      refreshToken,
    });
  }
}

async function refreshAccessTokenManually() {
  const refreshToken = getRefreshToken() ?? '';

  if (!refreshToken) {
    throw new Error('Unauthorized: missing refresh token');
  }

  const result = await callRefreshQuery(refreshToken);

  updateTokensAndNotifyApp(result);

  return result;
}

type NativeAuthStateResult = {
  nativeAuthActive: boolean;
};

function checkNativeRefreshAvailability(timeout: number): Promise<boolean> {
  const promise = addEventListenerWithPromise<NativeAuthStateResult>(
    WebViewEventName.NATIVE_AUTH_STATE_UPDATED,
    { timeout }
  )
    .then(({ nativeAuthActive }: any) => nativeAuthActive)
    .catch(() => false);

  fireEvent(WebViewEventName.REQUEST_NATIVE_AUTH_STATE);

  return promise;
}

export function refreshAccessToken(options?: {
  refreshTokenTimeout: number;
  nativeRefreshAvailabilityTimeout: number;
}): Promise<RefreshTokenResult> {
  const { refreshTokenTimeout, nativeRefreshAvailabilityTimeout } = options ?? {
    refreshTokenTimeout: 30000,
    nativeRefreshAvailabilityTimeout: 500,
  };

  if (PromiseManager.promise) {
    return PromiseManager.promise;
  }

  if (!isInWebView()) {
    PromiseManager.promise = refreshAccessTokenManually();
    return PromiseManager.promise;
  }

  PromiseManager.promise = checkNativeRefreshAvailability(nativeRefreshAvailabilityTimeout).then(
    (isAppBasedRefreshEnabled) => {
      if (!isAppBasedRefreshEnabled) {
        return refreshAccessTokenManually();
      }

      const nativeRefreshPromise = addEventListenerWithPromise<{
        authToken: string;
        refreshToken: string;
        maxAge: number;
      }>(WebViewEventName.TOKENS_UPDATED, {
        timeout: refreshTokenTimeout,
      })
        .then(({ refreshToken, authToken: accessToken, maxAge: expiresInSeconds }: any) => ({
          accessToken,
          refreshToken,
          expiresInSeconds,
        }))
        .then((result: any) => {
          updateTokensAndNotifyApp({ ...result, isAppBasedRefreshEnabled });
          return result;
        });

      fireEvent(WebViewEventName.REFRESH_TOKEN);

      return nativeRefreshPromise;
    }
  );

  return PromiseManager.promise;
}
