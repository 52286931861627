import React, { ReactNode } from 'react';
import { Root, DialogBody } from './ModalWindow.styles';

interface ModalWindowProps {
  onClose: () => void;
  mobile?: boolean;
  children: ReactNode | ReactNode[];
}

const overlayClassName = 'modalOverlay';

export const ModalWindow = ({ onClose, mobile, children }: ModalWindowProps) => {
  const handleClickOutside = ({ target }: React.MouseEvent<HTMLDivElement>) => {
    const overlayClick = (target as Element).classList.contains(overlayClassName);
    if (overlayClick) {
      onClose();
    }
  };

  return (
    <Root className={overlayClassName} onClick={handleClickOutside}>
      <DialogBody mobile={mobile}>{children}</DialogBody>
    </Root>
  );
};
