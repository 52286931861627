import { PaymentsUnion } from '@/generated/types';

const stackIconPath =
  'M11.5145 1.12584C11.8166 0.958052 12.1838 0.958052 12.4858 1.12584L21.4858 6.12584C21.8033 6.30221 22.0002 6.63683 22.0002 7C22.0002 7.36317 21.8033 7.69779 21.4858 7.87416L12.4858 12.8742C12.1838 13.0419 11.8166 13.0419 11.5145 12.8742L2.51454 7.87416C2.19707 7.69779 2.00018 7.36317 2.00018 7C2.00018 6.63683 2.19707 6.30221 2.51454 6.12584L11.5145 1.12584ZM5.05931 7L12.0002 10.856L18.9411 7L12.0002 3.14396L5.05931 7ZM2.12602 11.0144C2.39424 10.5316 3.00304 10.3576 3.48583 10.6258L12.0002 15.356L20.5145 10.6258C20.9973 10.3576 21.6061 10.5316 21.8743 11.0144C22.1426 11.4971 21.9686 12.1059 21.4858 12.3742L12.4858 17.3742C12.1838 17.5419 11.8166 17.5419 11.5145 17.3742L2.51454 12.3742C2.03176 12.1059 1.85781 11.4971 2.12602 11.0144ZM2.12602 15.5144C2.39424 15.0316 3.00304 14.8576 3.48583 15.1258L12.0002 19.856L20.5145 15.1258C20.9973 14.8576 21.6061 15.0316 21.8743 15.5144C22.1426 15.9971 21.9686 16.6059 21.4858 16.8742L12.4858 21.8742C12.1838 22.0419 11.8166 22.0419 11.5145 21.8742L2.51454 16.8742C2.03176 16.6059 1.85781 15.9971 2.12602 15.5144Z';
const boxIconPath =
  'M500,79L917,265V735L500,921L83,735V265L500,79zM167,356V681L458,811V485L167,356zM542,485V811L833,681V356L542,485zM772,292L500,171L415,208L688,329L772,292zM313,254L228,292L500,413L585,375L313,254z';
const mixIconPath =
  'M19,47V448H126V215H129L218,445H283L372,216H375V448H482V47H346L253,274H248L155,47H19zM535,448H643V147H535V448zM589,116C618,116,642,94,642,67C642,39,618,17,589,17C560,17,536,39,536,67C536,94,560,116,589,116zM791,147H683L763,298L678,448H786L835,353L886,448H993L908,298L989,147H882L835,241L791,147z';
const fallbackIconPath =
  'M12 1.90576L22 6.35021V17.65L12 22.0944L2 17.65V6.35021L12 1.90576ZM4 8.53884V16.3502L11 19.4613V11.65L4 8.53884ZM13 11.65V19.4613L20 16.3502V8.53884L13 11.65ZM18.5378 7.00008L12 4.0944L9.96221 5.00008L16.5 7.90576L18.5378 7.00008ZM7.5 6.0944L5.46221 7.00008L12 9.90576L14.0378 9.00008L7.5 6.0944Z';

export const getSvgPath = (payment: PaymentsUnion): string => {
  if (payment.__typename === 'GroupPayment') {
    return stackIconPath;
  }

  if (payment.__typename === 'OrderPayment') {
    return boxIconPath;
  }

  if (payment.__typename === 'PurchasePayment' || payment.__typename === 'SubscriptionPayment') {
    const { contract, subscriptionDetails } = payment ?? {};

    if (contract?.__typename === 'Mix') {
      return mixIconPath;
    }

    if (contract?.__typename === 'Flex') {
      return contract?.goods?.[0]?.product?.category?.iconSvgPath ?? fallbackIconPath;
    }

    return subscriptionDetails?.product?.category?.iconSvgPath ?? fallbackIconPath;
  }

  return fallbackIconPath;
};
