import { YourProfileQueryQuery } from '@/generated/types';
import { GET_USER_PROFILE_QUERY } from './user';
import { createClient } from '@/gql/apolloClient';

const graphqlGatewayClient = createClient();

export const getUserProfile = async (): Promise<Partial<YourProfileQueryQuery['user']>> => {
  const profile = await graphqlGatewayClient.query<YourProfileQueryQuery>({
    query: GET_USER_PROFILE_QUERY,
    fetchPolicy: 'cache-first',
  });
  if (profile.errors) {
    return {};
  }
  return profile.data.user;
};
