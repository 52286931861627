import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SvgFilter, SvgCloseCrossSmall } from '@getgrover/ui';
import { useTranslation } from 'react-i18next';

import { tk } from '@/i18n/translationKeys';
import { useQueryParams } from '@/hooks/useQueryParams';
import { useApplicationData } from '@/providers/applicationData';
import { SubscriptionState } from '@/generated/types';
import { FilterTag } from '../FilterTag';
import { FilterTagOption, FilterType } from '../types';
import { useFilterOptions } from '../useFilterOptions';

import { FilterPaneRoot } from './FilterPane.styles';

interface FilterPaneProps {
  filterValueCount?: number;
}

export const FilterPane = ({ filterValueCount }: FilterPaneProps) => {
  const { t } = useTranslation();
  const queryParams = useQueryParams();
  const { routeBuilder } = useApplicationData();
  const navigate = useNavigate();
  const { device: deviceValue } = queryParams;
  const selectedTypes = Object.entries(queryParams)
    .filter(([_, value]) => Boolean(value))
    .map(([key]) => key);

  const [filterValue, setFilterValue] = useState<string>('');
  const [selectedFilters, setSelectedFilters] = useState<string[]>(selectedTypes);
  const [isDeviceOpen, setIsDeviceOpen] = useState<boolean>(false);

  const { filterOptions, deviceOptions, deviceOptionsLoading } = useFilterOptions({
    searchTerm: '',
    filter: { state: [SubscriptionState.active] },
    first: 1000,
  });

  const handleOpenChange = (type: FilterType) => {
    setFilterValue('');

    switch (type) {
      case FilterType.Device:
        setIsDeviceOpen(!isDeviceOpen);
        break;
      default:
        break;
    }
  };

  const handleFilterChange = (type: string) => {
    if (type === FilterType.Device && deviceValue) setIsDeviceOpen(!isDeviceOpen);

    setSelectedFilters((prevSelectedFilters) =>
      prevSelectedFilters.includes(type) ? prevSelectedFilters : [...prevSelectedFilters, type]
    );

    setFilterValue(type);
  };

  const handleValueChange = (value: string, type: FilterType) => {
    navigate(routeBuilder.buildFilterPaymentsRoute({ query: { ...queryParams, [type]: value } }));
  };

  const renderFilterValue = () => {
    return filterValueCount
      ? `${t(tk.yourPaymentsFilterChipTitle)} (${filterValueCount})`
      : `${t(tk.yourPaymentsFilterChipTitle)}`;
  };

  const renderSelectedValue = (typePrefix: string, options: FilterTagOption[], value: string) => {
    const selectedOption = options?.find((option) => option.value === value);

    if (!selectedOption) {
      return null;
    }

    return (
      <span>
        {typePrefix} <b>{selectedOption.label}</b>
      </span>
    );
  };

  const handleFilterTagCloseClick = (event: React.MouseEvent<HTMLElement>, type: FilterType) => {
    event.stopPropagation();
    const tempSelectedFilters = selectedFilters.filter((filter) => filter !== type);
    setSelectedFilters(tempSelectedFilters);
    setFilterValue('');

    const newQueryParams = { ...queryParams };
    delete newQueryParams[type];
    navigate(routeBuilder.buildFilterPaymentsRoute({ query: newQueryParams }));
  };

  return (
    <FilterPaneRoot>
      <FilterTag
        wrapperIcon={<SvgFilter />}
        placeholder={renderFilterValue()}
        onValueChange={handleFilterChange}
        value={filterValue}
        options={filterOptions}
        selectedValueDisplay={<span>{renderFilterValue()}</span>}
        contentWrapperMaxWidth="168px"
      />
      {selectedFilters.includes(FilterType.Device) && !deviceOptionsLoading ? (
        <FilterTag
          wrapperIcon={<SvgCloseCrossSmall />}
          placeholder={`${t(tk.yourPaymentsFilterTitleDevice)}:`}
          open={isDeviceOpen}
          onOpenChange={() => handleOpenChange(FilterType.Device)}
          onValueChange={(value: string) => handleValueChange(value, FilterType.Device)}
          value={deviceValue}
          options={deviceOptions}
          selectedValueDisplay={renderSelectedValue(
            `${t(tk.yourPaymentsFilterTitleDevice)}:`,
            deviceOptions,
            deviceValue
          )}
          iconClickable
          onIconClick={(e: React.MouseEvent<HTMLElement>) =>
            handleFilterTagCloseClick(e, FilterType.Device)
          }
          optionsScrollable
          triggerPadding="0 0 0 8px"
          triggerBackgroundColor="transparent"
          contentWrapperMaxWidth="412px"
        />
      ) : null}
    </FilterPaneRoot>
  );
};
