import styled from 'styled-components';
import { ButtonV2 } from '@getgrover/ui';

export const Content = styled.div<{ invisible?: boolean }>`
  opacity: ${(props) => (props.invisible ? '0' : '1')};
`;

export const Loader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Button = styled(ButtonV2)`
  width: 100%;
  margin-right: 12px;

  &:last-child {
    color: #ffffff;
    margin-right: 0;
  }
`;

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
`;
