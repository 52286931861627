import styled from 'styled-components';

export const Height = styled.div<{ units?: number; mobile: boolean }>`
  height: ${({ mobile, units }) => (mobile ? '100%' : units ? `${4 * units}px` : 'inherit')};
`;

export const Actions = styled.div<{ mobile: boolean }>`
  position: ${({ mobile }) => (mobile ? 'fixed' : 'absolute')};
  padding: 0 16px;
  bottom: 0;
  left: 0;
  right: 0;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.background.white};
`;

export const TitleWrapper = styled.div`
  width: calc(100% - 48px);
`;

export const ExternalLink = styled.a`
  text-decoration: underline;
  cursor: pointer;
`;

export const NoticeText = styled.span`
  ${(props) => props.theme.typography.SmallParagraph}
`;

export const Hidden = styled.div<{ visible: boolean }>`
  position: ${({ visible }) => visible ? 'static' : 'absolute'};
  visibility: ${({ visible }) => visible ? 'visible' : 'hidden'};
`;