import React from 'react';
import { useTheme } from 'styled-components';

export const PlusIcon = ({ color }: { color?: string }) => {
  const theme = useTheme();
  const fillColor = color || theme.colors.text.darkerGrey;

  return (
    <div>
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8 1C8 0.447715 7.55228 0 7 0C6.44772 0 6 0.447715 6 1L6 6L1 6C0.447715 6 0 6.44772 0 7C0 7.55228 0.447715 8 1 8L6 8L6 13C6 13.5523 6.44771 14 7 14C7.55228 14 8 13.5523 8 13V8L13 8C13.5523 8 14 7.55228 14 7C14 6.44772 13.5523 6 13 6L8 6V1Z"
          fill={fillColor}
        />
      </svg>
    </div>
  );
};
