import React from 'react';
import { TypographyItemName } from '@getgrover/ui/dist/types/theme/types';
import { Text } from '@/components/Text';
import { Offset } from '@/components/Offset';
import { NoticeRoot, Status } from './Notice.styles';
import { useTheme } from 'styled-components';

interface NoticeProps {
  icon?: React.ReactElement;
  text: string | React.ReactElement;
  status?: Status;
  height?: string;
  typography?: keyof typeof TypographyItemName;
}

export const Notice = ({ icon, text, status = 'default', height, typography }: NoticeProps) => {
  const theme = useTheme();

  return (
    <NoticeRoot status={status} height={height} data-testid="notice">
      {icon ? <Offset right={3}>{icon}</Offset> : null}

      <Text
        typography={typography || 'SmallParagraph'}
        color={status === 'error' ? theme.colors.status.baseRed : theme.colors.text.darkerGrey}
      >
        {text}
      </Text>
    </NoticeRoot>
  );
};
