import React from 'react';

export const BrandSepaIcon = ({ width = 36 }: { width: number }) => {
  return (
    <svg
      width={width}
      height="28"
      viewBox="0 0 72 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.02185 30.0204L3.70925 29.9535C3.75023 30.1849 3.83435 30.3546 3.96076 30.4635C4.08717 30.5719 4.25804 30.6261 4.4725 30.6261C4.70004 30.6261 4.87134 30.5776 4.98685 30.4814C5.10193 30.3847 5.1599 30.2719 5.1599 30.1424C5.1599 30.0598 5.13549 29.9889 5.08711 29.9303C5.03829 29.8726 4.95373 29.8219 4.83298 29.7786C4.75016 29.7497 4.56186 29.6986 4.2672 29.6255C3.88884 29.5311 3.62295 29.4156 3.47038 29.2783C3.25549 29.0854 3.14826 28.8497 3.14826 28.572C3.14826 28.3935 3.19882 28.226 3.29995 28.0708C3.40064 27.9147 3.54623 27.7966 3.73671 27.7148C3.92633 27.6334 4.15604 27.5928 4.42499 27.5928C4.86393 27.5928 5.19434 27.6894 5.41621 27.8827C5.63808 28.0756 5.7549 28.3336 5.76579 28.6559L5.05965 28.687C5.02914 28.5064 4.96462 28.3769 4.86524 28.2978C4.76586 28.219 4.61635 28.1792 4.41758 28.1792C4.21271 28.1792 4.05186 28.2217 3.93592 28.3065C3.86094 28.3603 3.82346 28.4333 3.82346 28.5243C3.82346 28.607 3.85833 28.6782 3.92851 28.7373C4.01743 28.8125 4.23407 28.8904 4.57755 28.9717C4.92103 29.053 5.17516 29.1374 5.33993 29.2245C5.5047 29.3115 5.63328 29.4305 5.72656 29.5809C5.81941 29.7322 5.86605 29.9185 5.86605 30.1403C5.86605 30.3414 5.81026 30.5295 5.6991 30.7053C5.58751 30.8807 5.43016 31.011 5.2266 31.0967C5.02303 31.182 4.76934 31.2249 4.46553 31.2249C4.0231 31.2249 3.68354 31.1221 3.44641 30.917C3.20928 30.7119 3.06806 30.4132 3.02185 30.0204Z"
        fill="#10298E"
      />
      <path
        d="M6.60303 27.6523H7.27343V28.2747H6.60303V27.6523ZM6.60303 28.6193H7.27343V31.162H6.60303V28.6193Z"
        fill="#10298E"
      />
      <path
        d="M10.4089 31.1625H9.73851V29.8649C9.73851 29.5903 9.72413 29.4127 9.69536 29.3322C9.66703 29.2518 9.62039 29.1888 9.55588 29.1442C9.49136 29.0996 9.41377 29.0773 9.32311 29.0773C9.20716 29.0773 9.10298 29.1092 9.01057 29.173C8.91816 29.2369 8.85496 29.3213 8.82096 29.4267C8.78652 29.5321 8.76952 29.7267 8.76952 30.011V31.1625H8.09912V28.6198H8.72201V28.9933C8.94301 28.7059 9.22155 28.5625 9.55718 28.5625C9.70495 28.5625 9.84008 28.5892 9.96256 28.6425C10.0851 28.6963 10.1779 28.7646 10.2407 28.8472C10.3034 28.9303 10.347 29.0248 10.3719 29.1297C10.3967 29.2351 10.4089 29.386 10.4089 29.5824V31.1625Z"
        fill="#10298E"
      />
      <path
        d="M11.1711 31.3309L11.937 31.4245C11.9496 31.5138 11.9793 31.575 12.0255 31.6087C12.0891 31.6568 12.1889 31.6804 12.3262 31.6804C12.501 31.6804 12.6322 31.6541 12.7199 31.6017C12.7787 31.5662 12.8232 31.5098 12.8532 31.4315C12.8737 31.3756 12.8842 31.2728 12.8842 31.1228V30.7515C12.6841 31.0261 12.4309 31.1634 12.1257 31.1634C11.7853 31.1634 11.5155 31.0187 11.3167 30.73C11.1607 30.5018 11.0826 30.2179 11.0826 29.8777C11.0826 29.4517 11.1851 29.1259 11.3895 28.9011C11.5939 28.6759 11.8481 28.5635 12.1519 28.5635C12.4653 28.5635 12.7238 28.7012 12.9273 28.9776V28.6208H13.555V30.9024C13.555 31.2024 13.5302 31.4267 13.4809 31.575C13.4317 31.7237 13.3624 31.84 13.2734 31.9249C13.1841 32.0093 13.0651 32.0753 12.9164 32.1234C12.7678 32.1711 12.5795 32.1951 12.3524 32.1951C11.9226 32.1951 11.6179 32.1212 11.4383 31.9738C11.2587 31.826 11.1689 31.6388 11.1689 31.4123L11.1711 31.3309ZM11.77 29.8396C11.77 30.109 11.8219 30.3067 11.9265 30.4322C12.0303 30.5573 12.1589 30.6198 12.3119 30.6198C12.4753 30.6198 12.6139 30.5555 12.7268 30.4274C12.8397 30.2988 12.8964 30.1086 12.8964 29.8562C12.8964 29.593 12.8423 29.3975 12.7342 29.2698C12.6257 29.1421 12.4888 29.0782 12.3236 29.0782C12.1628 29.0782 12.0303 29.1408 11.9265 29.2658C11.8219 29.3914 11.77 29.5825 11.77 29.8396Z"
        fill="#10298E"
      />
      <path d="M14.3717 27.6523H15.0421V31.162H14.3717V27.6523Z" fill="#10298E" />
      <path
        d="M17.3432 30.3544L18.0115 30.4668C17.9256 30.7125 17.7896 30.8997 17.6043 31.0283C17.4191 31.1569 17.1872 31.2212 16.9091 31.2212C16.4684 31.2212 16.1423 31.0764 15.9305 30.7878C15.7636 30.5564 15.6803 30.2643 15.6803 29.9113C15.6803 29.4902 15.7897 29.16 16.0094 28.9212C16.2291 28.6829 16.5063 28.5635 16.842 28.5635C17.219 28.5635 17.5167 28.6886 17.7347 28.9383C17.9526 29.188 18.0568 29.5707 18.0472 30.0863H16.3673C16.3721 30.2857 16.4261 30.441 16.5294 30.552C16.6327 30.6627 16.7617 30.7182 16.9161 30.7182C17.0211 30.7182 17.1096 30.6894 17.1811 30.6321C17.2526 30.5748 17.3066 30.4821 17.3432 30.3544ZM17.3816 29.6743C17.3768 29.4797 17.3267 29.3314 17.2312 29.2304C17.1357 29.129 17.0194 29.0782 16.8825 29.0782C16.7365 29.0782 16.6153 29.132 16.5198 29.2387C16.4244 29.3454 16.3777 29.4911 16.379 29.6743H17.3816Z"
        fill="#10298E"
      />
      <path
        d="M20.2472 31.162V27.6523H22.8407V28.2458H20.9533V29.0243H22.7095V29.6156H20.9533V30.5707H22.9079V31.162H20.2472Z"
        fill="#10298E"
      />
      <path
        d="M25.3146 31.1618V30.7813C25.2221 30.9169 25.101 31.0236 24.9506 31.1019C24.8002 31.1802 24.6415 31.2191 24.4746 31.2191C24.3042 31.2191 24.1516 31.1819 24.0165 31.1067C23.8813 31.0315 23.7833 30.9265 23.7231 30.7905C23.6625 30.655 23.6324 30.4673 23.6324 30.2281V28.6191H24.3028V29.7877C24.3028 30.145 24.3151 30.3641 24.3399 30.445C24.3643 30.5255 24.4092 30.5894 24.4746 30.6366C24.54 30.6834 24.6228 30.707 24.7226 30.707C24.8373 30.707 24.9401 30.6755 25.0308 30.6125C25.1215 30.5496 25.1834 30.4708 25.2169 30.3777C25.25 30.2841 25.267 30.0558 25.267 29.6919V28.6191H25.9374V31.1618H25.3146Z"
        fill="#10298E"
      />
      <path
        d="M27.4215 31.1625H26.7511V28.6198H27.374V28.9815C27.4803 28.8105 27.5762 28.6981 27.6612 28.6438C27.7462 28.5896 27.843 28.5625 27.9511 28.5625C28.1041 28.5625 28.251 28.6049 28.3927 28.6893L28.1852 29.2758C28.0719 29.2028 27.9672 29.166 27.87 29.166C27.7763 29.166 27.6965 29.1918 27.6316 29.2439C27.5662 29.2955 27.5148 29.3895 27.4777 29.5251C27.4402 29.6607 27.4215 29.9449 27.4215 30.377V31.1625Z"
        fill="#10298E"
      />
      <path
        d="M28.6748 29.8553C28.6748 29.6318 28.7297 29.4157 28.8396 29.2067C28.9494 28.9972 29.1046 28.8376 29.306 28.7278C29.5069 28.6176 29.7318 28.5625 29.9799 28.5625C30.3635 28.5625 30.6777 28.6876 30.9227 28.9373C31.1672 29.187 31.2902 29.5023 31.2902 29.8841C31.2902 30.2686 31.1664 30.5874 30.9188 30.8402C30.6716 31.0934 30.3604 31.2198 29.9847 31.2198C29.7523 31.2198 29.5309 31.1673 29.3204 31.0619C29.1094 30.9565 28.9494 30.8021 28.8396 30.5988C28.7297 30.395 28.6748 30.1474 28.6748 29.8553ZM29.3622 29.8911C29.3622 30.1435 29.4215 30.3363 29.5409 30.4706C29.6604 30.6044 29.8073 30.6718 29.9825 30.6718C30.1573 30.6718 30.3042 30.6044 30.4227 30.4706C30.5409 30.3363 30.6006 30.1417 30.6006 29.8863C30.6006 29.6375 30.5409 29.4459 30.4227 29.3117C30.3042 29.1778 30.1573 29.1109 29.9825 29.1109C29.8073 29.1109 29.6604 29.1778 29.5409 29.3117C29.4215 29.4459 29.3622 29.6388 29.3622 29.8911Z"
        fill="#10298E"
      />
      <path
        d="M33.4685 31.162V27.6523H34.6018C35.0316 27.6523 35.3115 27.6698 35.4418 27.7048C35.6423 27.7577 35.8101 27.8723 35.9452 28.0486C36.0804 28.2248 36.1479 28.4527 36.1479 28.7321C36.1479 28.9473 36.1091 29.1284 36.0311 29.2753C35.9531 29.4223 35.8541 29.5377 35.7343 29.6217C35.614 29.7052 35.4919 29.7608 35.3677 29.7879C35.1994 29.8211 34.9549 29.8382 34.6354 29.8382H34.1746V31.162H33.4685ZM34.1746 28.2458V29.2421H34.5613C34.8398 29.2421 35.0259 29.2237 35.1197 29.187C35.2134 29.1502 35.287 29.0925 35.3402 29.0147C35.3938 28.9364 35.4204 28.8454 35.4204 28.7418C35.4204 28.6141 35.3829 28.5087 35.3084 28.4256C35.2334 28.3425 35.1388 28.2904 35.0242 28.2699C34.9401 28.2541 34.7705 28.2458 34.516 28.2458H34.1746Z"
        fill="#10298E"
      />
      <path
        d="M37.3738 29.3956L36.7657 29.2854C36.8337 29.0396 36.9518 28.8577 37.1188 28.7396C37.2857 28.6215 37.5338 28.5625 37.8633 28.5625C38.1619 28.5625 38.3846 28.5979 38.5311 28.6692C38.6775 28.7401 38.7804 28.8302 38.8401 28.9395C38.8998 29.0488 38.9295 29.2496 38.9295 29.5417L38.9225 30.3267C38.9225 30.5506 38.9334 30.7151 38.9547 30.8214C38.9761 30.9272 39.0162 31.0413 39.0755 31.1625H38.4121C38.3942 31.1179 38.3728 31.0514 38.3471 30.9639C38.3362 30.9241 38.3284 30.8975 38.3236 30.8848C38.2089 30.9963 38.0865 31.0803 37.9561 31.1362C37.8258 31.1922 37.6867 31.2198 37.5385 31.2198C37.2775 31.2198 37.0721 31.1489 36.9218 31.0068C36.7714 30.8647 36.6964 30.6853 36.6964 30.468C36.6964 30.3245 36.7304 30.1964 36.7988 30.084C36.8668 29.9716 36.9627 29.8854 37.0865 29.8251C37.2095 29.7656 37.3873 29.7131 37.6196 29.6685C37.933 29.6095 38.1501 29.5544 38.2708 29.5032V29.4363C38.2708 29.3069 38.2395 29.215 38.1754 29.1599C38.1117 29.1048 37.9919 29.0772 37.8153 29.0772C37.6959 29.0772 37.6026 29.1009 37.5359 29.1481C37.4692 29.1949 37.4152 29.2775 37.3738 29.3956ZM38.2708 29.9414C38.185 29.9703 38.0494 30.0044 37.8633 30.0446C37.6772 30.0844 37.5551 30.1234 37.498 30.1618C37.4104 30.224 37.3668 30.3031 37.3668 30.3989C37.3668 30.4929 37.4017 30.5743 37.4719 30.6429C37.5416 30.7116 37.6305 30.7457 37.7391 30.7457C37.8598 30.7457 37.9749 30.7059 38.0847 30.6263C38.1658 30.5655 38.2194 30.4912 38.2451 30.4037C38.2621 30.346 38.2708 30.2366 38.2708 30.0757V29.9414Z"
        fill="#10298E"
      />
      <path
        d="M39.4181 28.6201H40.1316L40.738 30.4255L41.3295 28.6201H42.0238L41.1289 31.067L40.969 31.5096C40.9101 31.6583 40.8539 31.7716 40.8012 31.8499C40.7475 31.9277 40.6865 31.9916 40.6172 32.0401C40.5479 32.0887 40.4629 32.1267 40.3618 32.1538C40.2607 32.1809 40.1469 32.1945 40.0192 32.1945C39.8906 32.1945 39.7642 32.1809 39.6404 32.1538L39.5802 31.6273C39.6853 31.6478 39.7799 31.6583 39.8644 31.6583C40.0201 31.6583 40.1356 31.6124 40.2101 31.5206C40.2851 31.4287 40.3422 31.3119 40.3823 31.1698L39.4181 28.6201Z"
        fill="#10298E"
      />
      <path
        d="M42.5504 28.6198H43.1685V28.967C43.3895 28.6972 43.6528 28.5625 43.9583 28.5625C44.1205 28.5625 44.2613 28.5957 44.3807 28.6627C44.4997 28.73 44.5978 28.8315 44.6741 28.967C44.7852 28.8315 44.9055 28.73 45.0345 28.6627C45.1631 28.5957 45.3009 28.5625 45.4473 28.5625C45.6335 28.5625 45.7908 28.6001 45.9194 28.6758C46.0484 28.7519 46.1448 28.863 46.2084 29.0099C46.2542 29.1188 46.2777 29.2942 46.2777 29.5369V31.1625H45.6069V29.7092C45.6069 29.4569 45.5842 29.2942 45.538 29.2207C45.4757 29.1249 45.3802 29.0772 45.2516 29.0772C45.1575 29.0772 45.0694 29.1057 44.9866 29.1634C44.9038 29.2207 44.8445 29.3051 44.8079 29.4157C44.7708 29.5268 44.753 29.7018 44.753 29.9414V31.1625H44.0821V29.7691C44.0821 29.5216 44.0704 29.362 44.0464 29.2902C44.0228 29.2185 43.9858 29.1647 43.9357 29.1297C43.8855 29.0947 43.8175 29.0772 43.7317 29.0772C43.6279 29.0772 43.5351 29.1048 43.4523 29.1608C43.3699 29.2168 43.3102 29.2972 43.2749 29.4026C43.2387 29.508 43.2208 29.683 43.2208 29.927V31.1625H42.5504V28.6198Z"
        fill="#10298E"
      />
      <path
        d="M48.5579 30.3544L49.2261 30.4668C49.1403 30.7125 49.0043 30.8997 48.819 31.0283C48.6337 31.1569 48.4018 31.2212 48.1237 31.2212C47.6826 31.2212 47.357 31.0764 47.1452 30.7878C46.9782 30.5564 46.8945 30.2643 46.8945 29.9113C46.8945 29.4902 47.0044 29.16 47.2241 28.9212C47.4438 28.6829 47.721 28.5635 48.0566 28.5635C48.4337 28.5635 48.7314 28.6886 48.9493 28.9383C49.1668 29.188 49.2715 29.5707 49.2619 30.0863H47.5819C47.5867 30.2857 47.6408 30.441 47.7441 30.552C47.8478 30.6627 47.9764 30.7182 48.1307 30.7182C48.2358 30.7182 48.3243 30.6894 48.3957 30.6321C48.4672 30.5748 48.5213 30.4821 48.5579 30.3544ZM48.5963 29.6743C48.5915 29.4797 48.5413 29.3314 48.4459 29.2304C48.3504 29.129 48.234 29.0782 48.0972 29.0782C47.9511 29.0782 47.83 29.132 47.7345 29.2387C47.639 29.3454 47.592 29.4911 47.5937 29.6743H48.5963Z"
        fill="#10298E"
      />
      <path
        d="M52.2577 31.1625H51.5873V29.8649C51.5873 29.5903 51.5729 29.4127 51.5441 29.3322C51.5153 29.2518 51.4691 29.1888 51.4046 29.1442C51.3401 29.0996 51.2625 29.0773 51.1719 29.0773C51.0559 29.0773 50.9517 29.1092 50.8593 29.173C50.7669 29.2369 50.7037 29.3213 50.6697 29.4267C50.6357 29.5321 50.6183 29.7267 50.6183 30.011V31.1625H49.9479V28.6198H50.5708V28.9933C50.7918 28.7059 51.0703 28.5625 51.4059 28.5625C51.5537 28.5625 51.6888 28.5892 51.8113 28.6425C51.9338 28.6963 52.0262 28.7646 52.0894 28.8472C52.1522 28.9303 52.1962 29.0248 52.2206 29.1297C52.2455 29.2351 52.2577 29.386 52.2577 29.5824V31.1625Z"
        fill="#10298E"
      />
      <path
        d="M54.2485 28.6205V29.1567H53.7903V30.1814C53.7903 30.3887 53.7947 30.5098 53.8034 30.5439L53.8631 30.6292C53.8941 30.6515 53.9316 30.6624 53.9765 30.6624C54.0384 30.6624 54.1281 30.641 54.2458 30.5982L54.3029 31.1199C54.1473 31.1868 53.9708 31.2205 53.7733 31.2205C53.6526 31.2205 53.5436 31.1999 53.4468 31.1597C53.3496 31.1186 53.2786 31.0661 53.2333 31.0014C53.1879 30.9367 53.1561 30.8492 53.1387 30.7394C53.1243 30.6611 53.1173 30.5032 53.1173 30.2653V29.1567H52.8096V28.6205H53.1173V28.1154L53.7903 27.7227V28.6205H54.2485Z"
        fill="#10298E"
      />
      <path
        d="M54.6243 30.4379L55.2973 30.3351C55.3256 30.4659 55.3836 30.5652 55.4712 30.633C55.5588 30.7008 55.6813 30.7349 55.8387 30.7349C56.0122 30.7349 56.1425 30.7029 56.2301 30.6391C56.2889 30.5945 56.3186 30.5346 56.3186 30.4593C56.3186 30.4082 56.3025 30.3662 56.2706 30.3325C56.2375 30.3006 56.1621 30.2713 56.0466 30.2442C55.5052 30.1243 55.1626 30.015 55.0179 29.9162C54.8174 29.7788 54.7171 29.5882 54.7171 29.3437C54.7171 29.1237 54.8039 28.9383 54.9773 28.7883C55.1504 28.6383 55.4193 28.5635 55.7842 28.5635C56.1307 28.5635 56.3883 28.6199 56.557 28.7332C56.7253 28.8469 56.8417 29.0144 56.9053 29.2361L56.2728 29.3533C56.2458 29.2545 56.1948 29.1788 56.1189 29.1259C56.0435 29.0734 55.9359 29.0472 55.7955 29.0472C55.619 29.0472 55.493 29.0717 55.4163 29.1211C55.3653 29.1561 55.34 29.2016 55.34 29.2575C55.34 29.3056 55.3622 29.3463 55.4071 29.3796C55.4673 29.4242 55.6761 29.4876 56.0331 29.5689C56.3901 29.6503 56.6394 29.75 56.7815 29.8681C56.921 29.9879 56.9912 30.1545 56.9912 30.3684C56.9912 30.6015 56.8944 30.8018 56.7004 30.9693C56.506 31.1368 56.2188 31.2208 55.8387 31.2208C55.4934 31.2208 55.2206 31.1503 55.0192 31.01C54.8178 30.8696 54.6862 30.6789 54.6243 30.4379Z"
        fill="#10298E"
      />
      <path
        d="M62.208 31.162H61.4396L61.1344 30.3647H59.7361L59.4471 31.162H58.6982L60.0604 27.6523H60.8075L62.208 31.162ZM60.9078 29.7734L60.4257 28.471L59.9532 29.7734H60.9078Z"
        fill="#10298E"
      />
      <path
        d="M63.3688 31.1625H62.6984V28.6198H63.3213V28.9815C63.4276 28.8105 63.5235 28.6981 63.6085 28.6438C63.6935 28.5896 63.7903 28.5625 63.8984 28.5625C64.0514 28.5625 64.1983 28.6049 64.3399 28.6893L64.1325 29.2758C64.0191 29.2028 63.9145 29.166 63.8173 29.166C63.7236 29.166 63.6438 29.1918 63.5789 29.2439C63.5135 29.2955 63.462 29.3895 63.4246 29.5251C63.3875 29.6607 63.3688 29.9449 63.3688 30.377V31.1625Z"
        fill="#10298E"
      />
      <path
        d="M66.2416 30.3544L66.9098 30.4668C66.824 30.7125 66.6884 30.8997 66.5032 31.0283C66.3175 31.1569 66.0856 31.2212 65.8075 31.2212C65.3668 31.2212 65.0407 31.0764 64.8289 30.7878C64.6619 30.5564 64.5782 30.2643 64.5782 29.9113C64.5782 29.4902 64.6881 29.16 64.9078 28.9212C65.127 28.6829 65.4051 28.5635 65.7403 28.5635C66.1174 28.5635 66.4151 28.6886 66.633 28.9383C66.851 29.188 66.9552 29.5707 66.9456 30.0863H65.2657C65.2704 30.2857 65.3245 30.441 65.4278 30.552C65.5311 30.6627 65.6601 30.7182 65.8144 30.7182C65.9195 30.7182 66.008 30.6894 66.0795 30.6321C66.1509 30.5748 66.205 30.4821 66.2416 30.3544ZM66.28 29.6743C66.2752 29.4797 66.225 29.3314 66.1296 29.2304C66.0341 29.129 65.9177 29.0782 65.7809 29.0782C65.6349 29.0782 65.5137 29.132 65.4182 29.2387C65.3228 29.3454 65.2761 29.4911 65.2774 29.6743H66.28Z"
        fill="#10298E"
      />
      <path
        d="M68.1392 29.3956L67.5312 29.2854C67.5992 29.0396 67.7173 28.8577 67.8842 28.7396C68.0512 28.6215 68.2992 28.5625 68.6287 28.5625C68.9273 28.5625 69.1501 28.5979 69.297 28.6692C69.443 28.7401 69.5459 28.8302 69.6056 28.9395C69.6653 29.0488 69.6949 29.2496 69.6949 29.5417L69.688 30.3267C69.688 30.5506 69.6989 30.7151 69.7202 30.8214C69.7416 30.9272 69.7817 31.0413 69.841 31.1625H69.1775C69.1597 31.1179 69.1383 31.0514 69.113 30.9639C69.1017 30.9241 69.0938 30.8975 69.089 30.8848C68.9744 30.9963 68.8519 31.0803 68.7216 31.1362C68.5913 31.1922 68.4522 31.2198 68.304 31.2198C68.0429 31.2198 67.8376 31.1489 67.6872 31.0068C67.5368 30.8647 67.4614 30.6853 67.4614 30.468C67.4614 30.3245 67.4959 30.1964 67.5643 30.084C67.6327 29.9716 67.7282 29.8854 67.852 29.8251C67.9749 29.7656 68.1527 29.7131 68.3851 29.6685C68.6985 29.6095 68.9156 29.5544 69.0363 29.5032V29.4363C69.0363 29.3069 69.0049 29.215 68.9408 29.1599C68.8772 29.1048 68.7573 29.0772 68.5808 29.0772C68.4618 29.0772 68.3685 29.1009 68.3014 29.1481C68.2347 29.1949 68.1806 29.2775 68.1392 29.3956ZM69.0363 29.9414C68.9504 29.9703 68.8149 30.0044 68.6287 30.0446C68.4426 30.0844 68.3206 30.1234 68.2635 30.1618C68.1759 30.224 68.1323 30.3031 68.1323 30.3989C68.1323 30.4929 68.1671 30.5743 68.2373 30.6429C68.3071 30.7116 68.396 30.7457 68.5045 30.7457C68.6253 30.7457 68.7408 30.7059 68.8502 30.6263C68.9317 30.5655 68.9849 30.4912 69.0106 30.4037C69.0276 30.346 69.0363 30.2366 69.0363 30.0757V29.9414Z"
        fill="#10298E"
      />
      <path
        d="M17.8857 13.8585H13.3014C13.3014 13.1037 13.213 12.5972 13.0369 12.3392C12.764 11.9578 12.0112 11.7667 10.7789 11.7667C9.58198 11.7667 8.78865 11.8765 8.40158 12.0965C8.01451 12.3173 7.82054 12.7971 7.82054 13.5358C7.82054 14.2045 7.99228 14.6453 8.3362 14.8561C8.58248 15.0061 8.90809 15.094 9.31347 15.1202L10.238 15.1858C12.2196 15.3175 13.4571 15.4106 13.9505 15.4636C15.5175 15.6219 16.6535 16.04 17.3583 16.7174C17.9128 17.2461 18.2436 17.9371 18.3491 18.79C18.411 19.3003 18.4415 19.8781 18.4415 20.5196C18.4415 21.9987 18.3007 23.0816 18.0196 23.7677C17.5091 25.0273 16.3623 25.8228 14.5782 26.1569C13.8306 26.2982 12.6873 26.3686 11.149 26.3686C8.5816 26.3686 6.7927 26.2155 5.7823 25.9072C4.54262 25.5298 3.72489 24.7649 3.32953 23.6134C3.10897 22.9722 3 21.9042 3 20.4094H7.58428C7.58428 20.576 7.58472 20.7038 7.58472 20.7917C7.58472 21.5894 7.81313 22.1019 8.26951 22.3307C8.58553 22.4881 8.96693 22.5708 9.41547 22.5808H11.1011C11.9611 22.5808 12.5099 22.5366 12.7466 22.4479C13.1681 22.2817 13.4449 22.014 13.5769 21.6436C13.6471 21.4153 13.682 21.1205 13.682 20.7593C13.682 19.9507 13.3878 19.4586 12.8002 19.282C12.58 19.2124 11.5583 19.1149 9.73367 18.992C8.2682 18.887 7.25039 18.786 6.68068 18.6881C5.1799 18.4064 4.17953 17.8269 3.68043 16.9474C3.24148 16.2005 3.02223 15.0699 3.02223 13.5572C3.02223 12.4057 3.14079 11.482 3.37792 10.7875C3.61504 10.0926 3.99732 9.56516 4.52475 9.20436C5.29759 8.65025 6.28663 8.32531 7.49013 8.22822C8.49181 8.14031 9.6029 8.0957 10.8251 8.0957C12.7492 8.0957 14.1205 8.20679 14.9378 8.42677C16.9329 8.9647 17.9302 10.4709 17.9302 12.9462C17.9302 13.1492 17.9154 13.4527 17.8857 13.8585Z"
        fill="#10298E"
      />
      <path
        d="M38.0442 26.37V8H45.2264H46.3536H47.2249C48.4842 8 49.4449 8.10277 50.1053 8.3092C51.6153 8.78371 52.6283 9.75592 53.1474 11.2245C53.415 11.9955 53.5493 13.1562 53.5493 14.7062C53.5493 16.5697 53.4007 17.9084 53.1064 18.724C52.5175 20.336 51.3058 21.2649 49.4755 21.5055C49.2601 21.5418 48.3443 21.5737 46.7276 21.5999L45.9099 21.627H42.9719V26.37H38.0442ZM42.9719 17.3656H46.0437C47.0179 17.3306 47.6103 17.2554 47.8234 17.1378C48.1146 16.9773 48.3103 16.6554 48.4075 16.173C48.4699 15.852 48.5008 15.3744 48.5008 14.7403C48.5008 13.9631 48.4389 13.3871 48.3147 13.011C48.1377 12.484 47.7131 12.1635 47.041 12.0467C46.9076 12.0292 46.5894 12.0196 46.0851 12.0196H42.9719V17.3656Z"
        fill="#10298E"
      />
      <path
        d="M64.0688 23.1953H57.4803L56.595 26.37H51.4968L56.9982 8H64.4315L70 26.37H65.0091L64.0688 23.1953ZM63.0903 19.6166L60.7813 11.6535L58.5409 19.6166H63.0903Z"
        fill="#10298E"
      />
      <path
        d="M31.2422 10.5842C33.2743 10.5842 35.0885 11.5236 36.2806 12.9948L37.3429 10.7097C35.6817 9.13442 33.4208 8.16309 30.927 8.16309C27.0441 8.16309 23.7226 10.516 22.3595 13.8467H20.2882L19.0651 16.4773H21.7297C21.7096 16.7214 21.6965 16.9654 21.6965 17.2147C21.6965 17.516 21.7122 17.8143 21.7419 18.1086H20.4028L19.1801 20.7401H22.4232C23.825 23.9864 27.1051 26.2654 30.927 26.2654C32.8532 26.2654 34.6404 25.6864 36.1202 24.6971V21.4722C34.9285 22.8336 33.1854 23.6939 31.2422 23.6939C28.9707 23.6939 26.9726 22.5187 25.8092 20.7401H32.5995L33.8226 18.1086H24.8101C24.7635 17.792 24.7386 17.4683 24.7386 17.1386C24.7386 16.9156 24.75 16.6951 24.7718 16.4773H34.5807L35.8033 13.8467H25.6196C26.7459 11.8966 28.8417 10.5842 31.2422 10.5842Z"
        fill="#FFBE00"
      />
    </svg>
  );
};
