import { gql } from '@apollo/client';

export const ADD_PAYMENT_METHOD_MUTATION = gql`
  mutation AddPaymentMethod($input: AddPaymentMethodInput!) {
    addPaymentMethod(input: $input) {
      id
      redirectUrl
    }
  }
`;

export const SET_PAYMENT_METHOD_MUTATION = gql`
  mutation SetPaymentMethod($input: SetPaymentMethodInput!) {
    setPaymentMethod(input: $input) {
      operation {
        id
      }
    }
  }
`;

export const REPLACE_PAYMENT_METHOD_FOR_ALL_CONTRACTS_MUTATION = gql`
  mutation ReplacePaymentMethod($input: ReplacePaymentMethodInput!) {
    replacePaymentMethod(input: $input) {
      operation {
        id
      }
    }
  }
`;

export const DELETE_PAYMENT_METHOD_MUTATION = gql`
  mutation DeletePaymentMethod($id: ID!) {
    deletePaymentMethod(id: $id) {
      operation {
        id
      }
    }
  }
`;

