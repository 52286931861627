import React from 'react';
import { useTheme } from 'styled-components';

export const CloseIcon = ({ color }: { color?: string }) => {
  const theme = useTheme();
  const defaultColor = theme.colors.text.darkerGrey;

  return (
    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.292893 1.03069C0.683417 0.656437 1.31658 0.656437 1.70711 1.03069L6 5.14471L10.2929 1.03069C10.6834 0.656437 11.3166 0.656437 11.7071 1.03069C12.0976 1.40494 12.0976 2.01172 11.7071 2.38598L7.41421 6.5L11.7071 10.614C12.0976 10.9883 12.0976 11.5951 11.7071 11.9693C11.3166 12.3436 10.6834 12.3436 10.2929 11.9693L6 7.85529L1.70711 11.9693C1.31658 12.3436 0.683417 12.3436 0.292893 11.9693C-0.0976311 11.5951 -0.0976311 10.9883 0.292893 10.614L4.58579 6.5L0.292893 2.38598C-0.0976311 2.01172 -0.0976311 1.40494 0.292893 1.03069Z"
        fill={color || defaultColor}
      />
    </svg>
  );
};
