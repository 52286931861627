import React from 'react';
import { Line, TextOverlay } from './Separator.styles';
import { useTheme } from 'styled-components';

type SeparatorProps = {
  text: string;
};

export const Separator = ({ text }: SeparatorProps) => {
  const theme = useTheme();

  return (
    <Line>
      <TextOverlay typography="SmallCapsHeadline" color={theme.colors.text.darkGrey}>
        {text}
      </TextOverlay>
    </Line>
  );
};
