export enum Platform {
  iOS = 'iOS',
  Android = 'Android',
  Web = 'Web',
  Unknown = 'Unknown',
}

export const getPlatform = (): Platform => {
  const useragent = window?.navigator?.userAgent ?? null;

  if (!useragent) {
    return Platform.Unknown;
  }

  if (useragent.includes('Grover') && useragent.includes('iOS')) {
    return Platform.iOS;
  }

  if (useragent.includes('Android')) {
    return Platform.Android;
  }

  return Platform.Web;
};

export const getRawUseragent = (): string => {
  return window?.navigator?.userAgent ?? 'unknown';
};
