import { useQuery } from '@apollo/client';
import { SINGLE_PAYMENT_METHOD_WITH_CONTRACTS } from '@/gql/queries';
import { SinglePaymentMethodWithContracts } from '@/types/paymentMethods';
import { ApolloError } from '@apollo/client/errors';
import { PaymentMethodWithContractsQuery, PaymentMethodWithContractsQueryVariables } from '@/generated/types';

interface UsePaymentMethodReturn {
  loading: boolean;
  error?: ApolloError;
  paymentMethod?: SinglePaymentMethodWithContracts | null;
}
export const usePaymentMethod = (paymentMethodId?: string): UsePaymentMethodReturn => {
  const { data, error, loading } = useQuery<PaymentMethodWithContractsQuery, PaymentMethodWithContractsQueryVariables>(
    SINGLE_PAYMENT_METHOD_WITH_CONTRACTS, 
    {
      variables: {
        paymentMethodId: paymentMethodId as string,
      },
      errorPolicy: 'none',
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache',
      skip: !paymentMethodId,
    }
  );

  return {
    paymentMethod: data?.paymentMethod,
    loading,
    error,
  };
};
