export enum HeaderKey {
  LOCALE = 'x-grover-language',
  STORE = 'x-grover-store',
  DEVICE_ID = 'x-grover-device-id',
  USER = 'x-grover-user',
  AUTH = 'authorization',
  CONTENT_TYPE = 'Content-Type',
  USER_AGENT_REQUEST = 'x-user-agent',
  IMPERSONATED_USER_ID = 'X-Grover-Impersonated-User-Id',
  IMPERSONATED_USER_COMPANY_ID = 'X-Grover-Impersonated-User-Company-Id',
}
