import React from 'react';
import { useTranslation } from 'react-i18next';
import { tk } from '@/i18n/translationKeys';
import { PaymentMethodWithContracts } from '@/types/paymentMethods';
import { Text } from '@/components/Text';
import { Group } from '@/components/Group';
import { Offset } from '@/components/Offset';
import { ChevronRightIcon } from '@/icons/ChevronRightIcon';
import { getExpiry } from '@/components/PaymentMethod/helpers';
import { PaymentMethodBrand } from '@/components/PaymentMethod/PaymentMethodBrand';
import {
  PaymentMethodListViewRoot,
  Content,
  IconWrapper,
  NoticeWrapper,
} from './PaymentMethodListView.styles';
import { BrandWrapper, SecondaryText, TextEllipsis } from '../PaymentMethod.styles';
import { getPrimaryText, getDate, getPaymentMethodDisplayType } from '@/utils/paymentMethods';
import { WarningIcon } from '@/icons/WarningIcon';
import { Notice } from '@/components/Notice';
import { PaymentMethodStatus } from '@/generated/types';
import { InvalidNote } from '../InvalidNote';
import { useTheme } from 'styled-components';

interface PaymentMethodListViewProps {
  paymentMethod: PaymentMethodWithContracts;
  beingDeleted?: boolean;
}

export const PaymentMethodListView = ({
  paymentMethod,
  beingDeleted,
}: PaymentMethodListViewProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { expiryStatus, expiryText } = getExpiry(paymentMethod, t);
  const numberOfSubs = paymentMethod.linkedContracts.length ?? 0;

  const invalid = paymentMethod.status === PaymentMethodStatus.INVALID;
  const dimmed = invalid || beingDeleted;
  const iconKey = getPaymentMethodDisplayType(paymentMethod);

  return (
    <PaymentMethodListViewRoot beingDeleted={beingDeleted}>
      <Offset right={4}>
        <BrandWrapper monochrome={dimmed}>
          <PaymentMethodBrand outlined iconKey={iconKey} size="normal" />
        </BrandWrapper>
      </Offset>

      <Content>
        <Group vertical gap={1} mobileGap={1}>
          <TextEllipsis
            color={dimmed ? theme.colors.text.lightGrey : theme.colors.text.darkerGrey}
            typography="Paragraph"
          >
            {getPrimaryText({ paymentMethod, t })}
          </TextEllipsis>
          {invalid ? (
            <InvalidNote />
          ) : (
            <SecondaryText typography="TinyParagraph" status={expiryStatus}>
              {paymentMethod.__typename === 'SepaPaymentMethod'
                ? `${t(tk.paymentMethodDateAddedText)}: ${getDate(paymentMethod.createdAt)}`
                : expiryText}
            </SecondaryText>
          )}
        </Group>

        {numberOfSubs ? (
          <Text color={theme.colors.text.darkerGrey} typography="TinyParagraph">
            {t(tk.paymentMethodNumberSubscriptionsText, { numberOfSubs })}
          </Text>
        ) : (
          <Text color={theme.colors.text.lightGrey} typography="TinyParagraph">
            {t(tk.paymentMethodNoSubscriptionsText)}
          </Text>
        )}
      </Content>

      <IconWrapper>
        <ChevronRightIcon />
      </IconWrapper>

      {beingDeleted ? (
        <NoticeWrapper>
          <Notice icon={<WarningIcon />} status="warn" text={t(tk.bulkUpdateBeingDeletedWarning)} />
        </NoticeWrapper>
      ) : null}
    </PaymentMethodListViewRoot>
  );
};
