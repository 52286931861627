import React from 'react';
import { useTheme } from 'styled-components';

export const InfoIcon = ({ color }: { color?: string }) => {
  const theme = useTheme();
  const defaultColor = theme.colors.text.darkerGrey;

  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.53479 9.38918C7.0883 9.50081 6.63585 9.22934 6.52423 8.78284C6.41261 8.33635 6.68407 7.8839 7.13057 7.77228L8.77908 7.36015C8.81752 7.34964 8.85707 7.34182 8.89752 7.33689C9.12239 7.30892 9.34216 7.37461 9.51222 7.5072C9.60709 7.58117 9.68648 7.67596 9.74266 7.78692C9.78561 7.87141 9.81458 7.96419 9.82652 8.06223C9.83155 8.10264 9.83355 8.14289 9.83268 8.18271V11.4974H10.666C11.1263 11.4974 11.4993 11.8705 11.4993 12.3307C11.4993 12.791 11.1263 13.1641 10.666 13.1641H7.33268C6.87244 13.1641 6.49935 12.791 6.49935 12.3307C6.49935 11.8705 6.87244 11.4974 7.33268 11.4974H8.16602V9.23138L7.53479 9.38918Z"
        fill={color || defaultColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.99949 6.77897C8.38382 6.77897 7.88472 6.27987 7.88472 5.6642C7.88472 5.04854 8.38382 4.54944 8.99949 4.54944C9.61516 4.54944 10.1143 5.04854 10.1143 5.6642C10.1143 6.27987 9.61516 6.77897 8.99949 6.77897Z"
        fill={color || defaultColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.99935 0.664062C13.6014 0.664062 17.3327 4.39535 17.3327 8.9974C17.3327 13.5994 13.6014 17.3307 8.99935 17.3307C4.3973 17.3307 0.666016 13.5994 0.666016 8.9974C0.666016 4.39534 4.3973 0.664062 8.99935 0.664062ZM8.99935 2.33073C5.31777 2.33073 2.33268 5.31582 2.33268 8.9974C2.33268 12.679 5.31777 15.6641 8.99935 15.6641C12.6809 15.6641 15.666 12.679 15.666 8.9974C15.666 5.31582 12.6809 2.33073 8.99935 2.33073Z"
        fill={color || defaultColor}
      />
    </svg>
  );
};
