import { useState, useCallback } from 'react';

/**
 * useError
 * This hook returns a function that throws an error.
 * It is helpful to use in async functions, because react Error Boundary
 * doesn't catch such errors.
 * @returns {(error: Error) => void } throwError
 */
export const useError = () => {
  const [, /* state */ setState] = useState();

  const throwError = useCallback((error: any) => {
    // TODO fix any type
    setState(() => {
      throw error;
    });
  }, []);

  return throwError;
};
