import React from 'react';

export const InvalidIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.3327 9.00008C17.3327 13.6021 13.6014 17.3334 8.99935 17.3334C4.3973 17.3334 0.666016 13.6021 0.666016 9.00008C0.666016 4.39803 4.3973 0.666748 8.99935 0.666748C13.6014 0.666748 17.3327 4.39803 17.3327 9.00008ZM8.99935 11.5001C9.45959 11.5001 9.83268 11.127 9.83268 10.6667V4.83342C9.83268 4.37318 9.45959 4.00008 8.99935 4.00008C8.53911 4.00008 8.16602 4.37318 8.16602 4.83342V10.6667C8.16602 11.127 8.53911 11.5001 8.99935 11.5001ZM8.99935 14.0001C9.45959 14.0001 9.83268 13.627 9.83268 13.1667C9.83268 12.7065 9.45959 12.3334 8.99935 12.3334C8.53911 12.3334 8.16602 12.7065 8.16602 13.1667C8.16602 13.627 8.53911 14.0001 8.99935 14.0001Z"
        fill="#F35757"
      />
    </svg>
  );
};
