import Cookies from 'js-cookie';
import { loadBrowserEnv } from '@/config';

const browserEnv = loadBrowserEnv();
const { LOCAL_AUTH_EMAIL, LOCAL_AUTH_PASSWORD } = browserEnv;

export const useLogin = () => {
  const login = async () => {
    const response = await fetch('https://apist.getgrover.com/api/v1/oauth/token', {
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        email: LOCAL_AUTH_EMAIL,
        password: LOCAL_AUTH_PASSWORD,
        grant_type: 'password',
        '2fa_code': '1234',
      }),
      method: 'POST',
      mode: 'cors',
      credentials: 'omit',
    });

    const data = await response.json();
    const accessToken = data?.access_token ?? null;
    const refreshToken = data?.refresh_token ?? null;

    if (accessToken) {
      Cookies.set('api_access_token', accessToken);
      Cookies.set('api_refresh_token', refreshToken);
      location.reload();
    }
  };

  return {
    login,
  };
};
