import { loadBrowserEnv } from '@/config';
import { LineItem, MixLineItem, OrderMode, PaymentsUnion } from '@/generated/types';
import { TFn } from '@/pages/YourPaymentsPage/types';
import { tk } from '@/i18n/translationKeys';

const browserEnv = loadBrowserEnv();

interface Link {
  url: string;
  title: string;
  description?: string;
}

const getLinkWithoutPrefix = (payment: PaymentsUnion, t: TFn): Link => {
  if (payment.__typename === 'SubscriptionPayment' || payment.__typename === 'PurchasePayment') {
    if (payment.contract?.__typename === 'Mix') {
      return {
        url: '/your-tech?summaryTab=mix-devices',
        title: t(tk.yourPaymentsLinkToSubscriptionText),
      };
    } else {
      const subscriptionId = payment.contract?.id ?? payment?.subscriptionDetails?.id;
      return {
        url: `/your-tech/${subscriptionId}`,
        title: t(tk.yourPaymentsLinkToSubscriptionText),
      };
    }
  }

  if (payment.__typename === 'OrderPayment') {
    const order = payment.order;
    if (order.__typename === 'Order') {
      const numberOfSubs = order.lineItems.reduce(
        (acc: number, item: LineItem) => acc + item.quantity,
        0
      );

      return {
        url: `/your-tech?orderId=${order.number}`,
        title: t(tk.yourPaymentsLinkToSubscriptionText),
        description: t(tk.yourPaymentsLinkToSubscriptionDescription, {
          count: numberOfSubs,
          numberOfSubs,
        }),
      };
    }

    if (order.__typename === 'MixCartOrder') {
      if (order.orderMode === OrderMode.FLEX) {
        // @ts-ignore lineItems are renamed to mixLineItems since graphql generation has conflicts
        const numberOfSubs = order.mixLineItems.reduce(
          (acc: number, item: MixLineItem) => acc + (item.quantity || 0),
          0
        );

        return {
          url: `/your-tech?orderId=${order.orderNumber}`,
          title: t(tk.yourPaymentsLinkToSubscriptionText),
          description: t(tk.yourPaymentsLinkToSubscriptionDescription, {
            count: numberOfSubs,
            numberOfSubs,
          }),
        };
      } else {
        return {
          url: '/your-tech?summaryTab=mix-devices',
          title: t(tk.yourPaymentsLinkToMixSubscriptionText),
          description: t(tk.yourPaymentsLinkToMixSubscriptionDescription),
        };
      }
    }
  }

  return {
    title: 'Go to your tech',
    url: '/your-tech',
  };
};

export const getLinkToSubscription = (payment: PaymentsUnion, storeCode: string, t: TFn): Link => {
  const link = getLinkWithoutPrefix(payment, t);

  return {
    ...link,
    url: `${browserEnv.HOST_BASE_URL}/${storeCode}${link.url}`,
  };
};
