import styled from 'styled-components';

export const PaymentMethodListViewRoot = styled.div<{ beingDeleted?: boolean }>`
  background: ${({ theme }) => theme.colors.background.white};
  position: relative;
  width: 100%;
  height: ${({ beingDeleted }) => (beingDeleted ? '124px' : '110px')};
  padding: 16px 32px 16px 16px;
  display: flex;
  border-radius: 8px;
  border: ${({ theme }) => `2px solid ${theme.colors.stroke.lighterGrey}`};
`;

export const Content = styled.div`
  height: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
`;

export const IconWrapper = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
`;

export const NoticeWrapper = styled.div`
  position: absolute;
  bottom: 16px;
  right: 16px;
  left: 16px;
`;
