import React, { ChangeEvent, FocusEvent, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { Text } from '@/components/Text';
import { Group } from '@/components/Group';
import { ErrorIcon } from './ErrorIcon';
import { InputWrapper, Label } from './Input.styles';

interface InputProps {
  label?: string;
  placeholder?: string;
  value: string;
  error?: string | null;
  name: string;
  renderInput?: () => JSX.Element;
  focus?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
}

export const Input = ({
  label,
  placeholder,
  value,
  onChange,
  error,
  name,
  renderInput,
  focus: forcedFocus,
  disabled,
  onClick,
  onBlur,
}: InputProps) => {
  const [focused, setFocused] = useState<boolean>(false);
  const theme = useTheme();

  const handleFocus = () => setFocused(true);
  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    setFocused(false);
    if (onBlur) onBlur(e);
  };

  useEffect(() => {
    setFocused(!!forcedFocus);
  }, [forcedFocus]);

  const handleClick = () => {
    if (!disabled && onClick) onClick();
  };

  return (
    <Group fullWidth vertical gap={2} mobileGap={2}>
      <InputWrapper
        disabled={!!disabled}
        hasError={!!error}
        focused={focused}
        onClick={handleClick}
      >
        {renderInput ? (
          renderInput()
        ) : (
          <input
            id={name}
            value={value}
            placeholder={focused ? placeholder : ''}
            onChange={onChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            name={name}
            disabled={!!disabled}
          />
        )}
        <Label htmlFor={name} shifted={focused || !!value}>
          {label}
        </Label>
      </InputWrapper>

      {error ? (
        <Group centered gap={2} mobileGap={2}>
          <ErrorIcon color={theme.colors.status.darkRed} />
          <Text typography="SmallParagraph" color={theme.colors.status.darkRed}>
            {error}
          </Text>
        </Group>
      ) : null}
    </Group>
  );
};
