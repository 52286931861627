import React from 'react';

import { Spinner } from './Spinner.web';
import { Container } from './CircleLoader.styles';

type CircleLoaderPropsType = {
  className?: string;
};

export const CircleLoader = ({ className }: CircleLoaderPropsType): JSX.Element => (
  <Container className={className}>
    <Spinner />
  </Container>
);
