import styled from 'styled-components';

export const Header = styled.header`
  margin-top: 24px;
  margin-bottom: 40px;

  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: auto;
  grid-row-gap: 16px;
`;
