import styled from 'styled-components';

export const PaymentsListRoot = styled.div<{ isMobile: boolean }>`
  max-width: 1080px;
  margin: ${({ isMobile }) => isMobile ? '0' : '24px auto'};
`;

export const ModalContentActions = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DownloadInvoiceDisabled = styled.div`
  width: 100%;
  height: 112px;
  display: flex;
  align-items: center;
  opacity: 0.5;
`;

export const IconCircle = styled.div`
  position: relative;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.hover.lighterGrey};
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: auto;
`;

export const ExternalLink = styled.a`
  text-decoration: underline;
  cursor: pointer;
  white-space: nowrap;
`;

export const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
