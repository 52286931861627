import { sendAnalyticsEvent } from '@devsbb/analytics-client';

type EventProperties = {
  userId: string;
  store: string;
  invalid?: boolean;
  paymentMethodType?:
    | 'CardPaymentMethod'
    | 'ByInvoicePaymentMethod'
    | 'PaypalPaymentMethod'
    | 'SepaPaymentMethod';
  queryParamFrom?: string | null;
};

export const trackPaymentMethodDetailsLinkToListClickEvent = (properties: EventProperties) => {
  sendAnalyticsEvent<EventProperties>('List Link Clicked', properties);
};

export const trackPaymentMethodDetailsDeleteButtonClickEvent = (properties: EventProperties) => {
  sendAnalyticsEvent<EventProperties>('Delete Button Clicked', properties);
};

export const trackPaymentMethodDetailsDeleteConfirmEvent = (properties: EventProperties) => {
  sendAnalyticsEvent<EventProperties>('Delete Confirmed', properties);
};

export const trackPaymentMethodDetailsDeleteCancelEvent = (properties: EventProperties) => {
  sendAnalyticsEvent<EventProperties>('Delete Cancelled', properties);
};

export const trackPaymentMethodDetailsDeletedEvent = (properties: EventProperties) => {
  sendAnalyticsEvent<EventProperties>('Payment Method Deleted', properties);
};

export const trackPaymentMethodDetailsDeleteFailedEvent = (properties: EventProperties) => {
  sendAnalyticsEvent<EventProperties>('Payment Method Delete Failed', properties);
};

export const trackPaymentMethodDetailsBulkUpdateLinkClickEvent = (properties: EventProperties) => {
  sendAnalyticsEvent<EventProperties>('Bulk Link Clicked', properties);
};
