import { gql } from '@apollo/client';

export const GET_USER_PROFILE_QUERY = gql`
  query YourProfileQuery {
    user {
      id
      email
      firstName
      lastName
      verificationState
      type
      defaultStore {
        code
        defaultCurrency
        defaultLocale
        name
      }
      company {
        groverWorkEnabled
      }
    }
  }
`;
