import { ApolloClient, InMemoryCache, from } from '@apollo/client';
import { headersLink, httpLink, loggerLink, errorLink, refreshTokenLink } from './links';

export const createClient = ({
  ssr = false,
  cookies = {},
}: { ssr?: boolean; cookies?: Record<string, string> } = {}) => {
  return new ApolloClient({
    cache: new InMemoryCache(),
    credentials: 'include',
    link: from([headersLink, refreshTokenLink, errorLink, loggerLink, httpLink]),
  });
};
