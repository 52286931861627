enum PaymentHeaders {
  API = 'x-grover-api',
  AUTHORIZATION = 'Authorization',
  DEVICE_ID = 'X-Grover-Device-ID',
  LOCALE = 'x-grover-language',
  ORDER_ID = 'x-grover-order',
  ORDER_GUEST_TOKEN = 'x-grover-order-token',
  PARTNER_CODE = 'x-grover-partner',
  STORE_CODE = 'X-Grover-Store',
  USER_AGENT = 'User-Agent',
  USER_ID = 'X-Grover-User',
  IMPERSONATED_USER_ID = 'X-Grover-Impersonated-User-Id',
  IMPERSONATED_USER_COMPANY_ID = 'X-Grover-Impersonated-User-Company-Id',
}

export { PaymentHeaders };
