import React, { memo } from 'react';
import { Text } from '@/components/Text';
import { Group } from '@/components/Group';
import { Offset } from '@/components/Offset';
import SuccessIcon from './icons/SuccessIcon';
import FailureIcon from './icons/FailureIcon';
import { Root } from './Toast.styles';
import { SonntagButtonV2 } from '@getgrover/ui';
import { useTheme } from 'styled-components';

interface ToastProps {
  type?: 'success' | 'failure';
  title: string;
  text: string | React.ReactElement;
  mobile?: boolean;
  button?: {
    text: string;
    onClick: () => void;
  };
}

const icons = {
  success: SuccessIcon,
  failure: FailureIcon,
};

const Toast = ({ type = 'success', title, text, mobile, button }: ToastProps) => {
  const Icon = icons[type];
  const theme = useTheme();

  return (
    <Root mobile={mobile} data-testid="toast">
      <Offset top={2}>
        <Icon />
      </Offset>
      <Offset left={4} right={2}>
        <Group vertical gap={1}>
          <Text
            typography="SuperTinyHeadline"
            color={theme.colors.text.darkerGrey}
            data-testid="toast-title"
          >
            {title}
          </Text>

          <Text
            typography="TinyParagraph"
            color={theme.colors.text.darkGrey}
            data-testid="toast-text"
          >
            {text}
          </Text>

          {button ? (
            <Offset top={4}>
              <SonntagButtonV2 size="small" outlined onClick={button.onClick}>
                {button.text}
              </SonntagButtonV2>
            </Offset>
          ) : null}
        </Group>
      </Offset>
    </Root>
  );
};

export default memo(Toast);
