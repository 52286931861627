import styled from 'styled-components';

const spaceUnit = 4;

export interface OffsetRootProps {
  inline?: boolean;
  left?: number;
  right?: number;
  top?: number;
  bottom?: number;
}

export const Root = styled.div<OffsetRootProps>`
  display: ${(props: OffsetRootProps) => (props.inline ? 'inline-block' : 'block')};
  margin-left: ${(props: OffsetRootProps) => (props.left ? spaceUnit * props.left : 0)}px;
  margin-right: ${(props: OffsetRootProps) => (props.right ? spaceUnit * props.right : 0)}px;
  margin-top: ${(props: OffsetRootProps) => (props.top ? spaceUnit * props.top : 0)}px;
  margin-bottom: ${(props: OffsetRootProps) => (props.bottom ? spaceUnit * props.bottom : 0)}px;
`;
