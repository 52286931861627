import React, { ReactNode, useState } from 'react';
import { DeviceTypes } from '@devsbb/header-ui';
import { ErrorBoundary } from '@/components/ErrorBoundary';
import { HeaderWrapper, PageContent, PageRoot } from './Page.styles';
import { sendAnalyticsEvent } from '@devsbb/analytics-client';
import { HeaderConsumer } from '@devsbb/header-schema';
import { useHeader, useHeaderSearch } from './hooks';
import { LocalDevAuthButton } from '@/components/LocalDevAuthButton';
import { loadBrowserEnv } from '@/config';
import { logger } from '@/logger';
import { getLocale, LOCALE, setLocale } from '@/utils/cookies';
import { forceLogout } from '@/gql/apolloClient/auth';
import { DropDownOption } from '@getgrover/ui';
import { useDebounce } from '@/hooks/useDebounce';
import { useScreenMatch } from '@/hooks/useScreenMatch';
import { useApplicationData } from '@/providers/applicationData';

const browserEnv = loadBrowserEnv();
const { LOCAL_AUTH_EMAIL, LOCAL_AUTH_PASSWORD, NODE_ENV, LOCAL_AUTH_AUTO_LOGIN } = browserEnv;
const localAutoLoginEnabled =
  LOCAL_AUTH_EMAIL &&
  LOCAL_AUTH_PASSWORD &&
  LOCAL_AUTH_AUTO_LOGIN === 'true' &&
  NODE_ENV === 'development';

interface PageProps {
  children: ReactNode;
}

export const Page = ({ children }: PageProps) => {
  const [searchQuery, setSearchQuery] = useState('');
  const debouncedQuery = useDebounce(searchQuery, 800);
  const { deviceType, isMobile } = useScreenMatch();
  const isGroverApp = Boolean(window?.ReactNativeWebView);

  const { storeCode } = useApplicationData();
  const activeLocale = getLocale();

  const handleNavigate = ({ url }: { url: string }) => {
    window.location.href = url;
  };

  const {
    loading: headerLoading,
    error,
    data: headerData,
  } = useHeader({ consumer: HeaderConsumer.payments, isGroverApp });
  const { loading: searchLoading, data: searchData } = useHeaderSearch({
    consumer: HeaderConsumer.payments,
    q: debouncedQuery,
  });

  const isUserTyping = searchQuery.trim() !== debouncedQuery.trim();
  const enabledSearchLoading =
    debouncedQuery !== '' ? searchLoading : isUserTyping || searchLoading;

  if (error) {
    return <div>Error! {localAutoLoginEnabled ? <LocalDevAuthButton /> : null}</div>;
  }

  const handleSupportClick = () => {
    const locale = getLocale();
    const url = `https://help.grover.com/${locale}`;
    logger.info('support link clicked', { href: url });
    window.open(url, '_self');
  };

  const onChangeLocale = (value: DropDownOption['value']) => {
    if (value !== activeLocale) {
      setLocale(value as LOCALE);
      window.location.reload();
    }
  };

  const onChangeStore = (value: DropDownOption['value']) => {
    if (value !== storeCode) {
      const currentHref = window.location.href;
      const newHref = currentHref.replace(storeCode, String(value));
      window.location.href = newHref;
    }
  };

  return (
    <PageRoot>
      <ErrorBoundary>
        <HeaderWrapper
          showHalfHeaderOnScroll={false}
          mobile={isMobile}
          header={{
            data: headerData?.header,
            loading: headerLoading,
          }}
          search={{
            data: searchData?.headerSearch,
            loading: enabledSearchLoading,
          }}
          query={searchQuery}
          onQueryChange={setSearchQuery}
          consumer={HeaderConsumer.payments}
          onNavigate={handleNavigate}
          deviceType={deviceType as unknown as DeviceTypes}
          onLogin={() => {
            /* do nothing */
          }}
          onSignup={() => {
            /* do nothing */
          }}
          onLocaleChange={onChangeLocale}
          onStoreChange={onChangeStore}
          onLogout={forceLogout}
          unreadMessages={0}
          sendAnalyticsFunctionFromConsumer={sendAnalyticsEvent}
          onSupportClick={handleSupportClick}
        />
        <PageContent>
          <ErrorBoundary>{children}</ErrorBoundary>
        </PageContent>
      </ErrorBoundary>
    </PageRoot>
  );
};
