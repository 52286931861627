import { tk } from '@/i18n/translationKeys';
import { formatDate } from '@/utils/date';
import { getLocale } from '@/utils/cookies';
import { TFn } from '@/pages/YourPaymentsPage/types';
import { PaymentsUnion } from '@/generated/types';


const getFormattedDate = (payment: PaymentsUnion, t: TFn, mobile?: boolean): string => {
  const locale = getLocale();

  const date = payment.paidDate;
  return formatDate({ date: date, locale, mobile });
};

export const getPaidDate = (payment: PaymentsUnion, t: TFn, mobile?: boolean): string => {
  if (!payment.paidDate) {
    return '';
  }

  const formattedDate = getFormattedDate(payment, t, mobile);
  return `${t(tk.yourPaymentsStatePaid)} ${formattedDate}`;
};
