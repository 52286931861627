import { PaymentMethodWithContracts } from '@/types/paymentMethods';
import dayjs from 'dayjs';
import { TFn } from '@/pages/YourPaymentsPage/types';
import { tk } from '@/i18n/translationKeys';

export enum ExpiryStatus {
  expired,
  expiringSoon,
  normal,
  transparent,
}

interface GetExpiryReturn {
  expiryStatus: ExpiryStatus;
  expiryText: string;
}

const EXPIRING_SOON_DAYS = 90;

const getStatus = (date: Date): ExpiryStatus => {
  const currentDate = new Date();
  const difference = dayjs(date).diff(currentDate, 'day');

  if (difference < 0) {
    return ExpiryStatus.expired;
  }

  if (difference < EXPIRING_SOON_DAYS) {
    return ExpiryStatus.expiringSoon;
  }

  return ExpiryStatus.normal;
};

const modifyText = (text: string, status: ExpiryStatus, t: TFn) => {
  if (status === ExpiryStatus.expiringSoon) {
    return `${text} (${t(tk.paymentMethodExpiringText)})`;
  }

  if (status === ExpiryStatus.expired) {
    return t(tk.paymentMethodExpiredText);
  }

  return text;
};

export const getExpiry = (paymentMethod: PaymentMethodWithContracts, t: TFn): GetExpiryReturn => {
  if (paymentMethod.__typename !== 'CardPaymentMethod')
    return {
      expiryStatus: ExpiryStatus.normal,
      expiryText: '',
    };

  const expiryMonth = paymentMethod.expiryMonth ?? '';
  const expiryYear = paymentMethod.expiryYear ?? '';

  // hack for addPm card form
  if (expiryYear.includes('•'))
    return {
      expiryStatus: ExpiryStatus.transparent,
      expiryText: expiryYear,
    };

  if (expiryYear === '20')
    return {
      expiryStatus: ExpiryStatus.normal,
      expiryText: `${t(tk.emptyCardExpiryDate)}`,
    };

  const shortYear = expiryYear.slice(expiryYear.length - 2, expiryYear.length);

  const expiryMonthInt = parseInt(expiryMonth, 10);
  const expiryYearInt = parseInt(expiryYear, 10);

  const expiryDate = new Date(expiryYearInt, expiryMonthInt - 1);
  const expiryStatus = getStatus(expiryDate);

  const expiryText = `${expiryMonth}/${shortYear}`;

  return {
    expiryStatus,
    expiryText: modifyText(expiryText, expiryStatus, t),
  };
};
