import { useQuery } from '@apollo/client';
import { PAYMENT_METHODS_WITH_CONTRACTS } from '@/gql/queries';
import { PaymentMethodsWithContractsQuery, PaymentMethodsWithContractsQueryVariables } from '@/generated/types';
import { CardPaymentMethodWithContracts, OtherPaymentMethodWithContracts } from '@/types/paymentMethods';

export const mapResponse = (data?: PaymentMethodsWithContractsQuery) => {
  const paymentMethods = data?.paymentMethods ?? [];
  const cards: CardPaymentMethodWithContracts[] = [];
  const otherPaymentMethods: OtherPaymentMethodWithContracts[] = [];

  paymentMethods.forEach((pm) => {
    if (pm.__typename === 'CardPaymentMethod') {
      cards.push(pm);
    } else {
      otherPaymentMethods.push(pm as OtherPaymentMethodWithContracts);
    }
  });

  cards.sort((a, b) => a.linkedContracts.length - b.linkedContracts.length);
  otherPaymentMethods.sort((a, b) => b.linkedContracts.length - a.linkedContracts.length);

  return {
    cards,
    otherPaymentMethods,
    paymentMethods,
  };
};

export const usePaymentMethods = () => {
  const { data, error, loading } = useQuery<PaymentMethodsWithContractsQuery, PaymentMethodsWithContractsQueryVariables>(
    PAYMENT_METHODS_WITH_CONTRACTS, 
    {
      errorPolicy: 'none',
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache',
      variables: {
        filter: {
          includeInvalid: true,
        },
      },
    }
  );

  const { cards, otherPaymentMethods, paymentMethods } = mapResponse(data);
  return { paymentMethods, cards, otherPaymentMethods, loading, error };
};
