import React from 'react';
import { useTranslation } from 'react-i18next';
import { tk } from '@/i18n/translationKeys';
import { getPrimaryText, getDate, getPaymentMethodDisplayType } from '@/utils/paymentMethods';
import { PaymentMethodWithContracts } from '@/types/paymentMethods';
import { Text } from '@/components/Text';
import { Offset } from '@/components/Offset';
import { PaymentMethodBrand } from '@/components/PaymentMethod/PaymentMethodBrand';
import { getExpiry } from '@/components/PaymentMethod/helpers';
import { GreenCheckIcon } from '@/icons/GreenCheckIcon';
import { BrandWrapper, SecondaryText } from '@/components/PaymentMethod/PaymentMethod.styles';
import {
  PaymentMethodSelectViewRoot,
  Unchecked,
  IconWrapper,
  TextWrapper,
} from './PaymentMethodSelectView.styles';
import { PaymentMethodStatus } from '@/generated/types';
import { InvalidNote } from '../InvalidNote';
import { useTheme } from 'styled-components';

interface PaymentMethodListViewProps {
  paymentMethod: PaymentMethodWithContracts;
  selected: boolean;
  onClick: (id: string) => void;
}

export const PaymentMethodSelectView = ({
  paymentMethod,
  selected,
  onClick,
}: PaymentMethodListViewProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const primaryText = getPrimaryText({ paymentMethod, t });
  const { expiryText, expiryStatus } = getExpiry(paymentMethod, t);

  const invalid = paymentMethod.status === PaymentMethodStatus.INVALID;

  const handleClick = () => {
    if (invalid) {
      return;
    }

    onClick(paymentMethod.id);
  };

  const iconKey = getPaymentMethodDisplayType(paymentMethod);

  return (
    <PaymentMethodSelectViewRoot
      selected={selected}
      onClick={handleClick}
      data-testid="payment-method-select-view"
    >
      <Offset right={4}>
        <BrandWrapper monochrome={invalid}>
          <PaymentMethodBrand outlined iconKey={iconKey} size="small" />
        </BrandWrapper>
      </Offset>

      <TextWrapper color={theme.colors.text.darkerGrey}>
        <Text
          typography="Paragraph"
          color={invalid ? theme.colors.text.lightGrey : theme.colors.text.darkerGrey}
        >
          {paymentMethod.__typename === 'ByInvoicePaymentMethod' ? t(primaryText) : primaryText}
        </Text>

        {invalid ? (
          <InvalidNote />
        ) : (
          <SecondaryText block typography="TinyParagraph" status={expiryStatus}>
            {paymentMethod.__typename === 'SepaPaymentMethod'
              ? `${t(tk.paymentMethodDateAddedText)}: ${getDate(paymentMethod.createdAt)}`
              : expiryText}
          </SecondaryText>
        )}
      </TextWrapper>

      {!invalid ? (
        <IconWrapper>
          {selected ? <GreenCheckIcon /> : <Unchecked data-testid="unchecked" />}
        </IconWrapper>
      ) : null}
    </PaymentMethodSelectViewRoot>
  );
};
