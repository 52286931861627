import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';

export const Root = styled.div<{ mobile?: boolean }>`
  width: ${(props) => (props.mobile ? '100%' : 'auto')};
  max-width: ${(props) => (props.mobile ? '100%' : '356px')};
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  & > svg {
    flex-shrink: 0;
  }
`;

export const StyledToastContainer = styled(ToastContainer)<{ mobile?: boolean }>`
  &&&.Toastify__toast-container {
    width: ${(props) => (props.mobile ? 'calc(100% - 16px)' : 'auto')};
    margin: ${(props) => (props.mobile ? '8px' : '0')};
  }
  .Toastify__toast {
    background: ${({ theme }) => theme.colors.background.white};
    padding: 16px;
    border-radius: 8px;
    margin-bottom: 8px;
  }
  .Toastify__close-button {
    transform: scale(1.2);
    opacity: 0.7;
    color: ${({ theme }) => theme.colors.text.darkerGrey};
  }
`;
