import React from 'react';

export const EmptyIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0736 0.552186L16.8691 2.1051C18.4695 2.53393 19.4193 4.17894 18.9904 5.77933L16.1434 16.4045C15.7146 18.0049 14.0696 18.9547 12.4692 18.5258L11.6956 18.3186C11.2076 19.3146 10.1839 20.0003 9 20.0003H3C1.34315 20.0003 0 18.6572 0 17.0003V6.00034C0 4.34348 1.34315 3.00034 3 3.00034H7.31175L7.39932 2.67351C7.82815 1.07311 9.47316 0.12336 11.0736 0.552186ZM9.64089 17.768L6.67364 16.9729C5.07324 16.5441 4.12349 14.8991 4.55231 13.2987L6.77585 5.00034H3C2.44772 5.00034 2 5.44805 2 6.00034V17.0003C2 17.5526 2.44772 18.0003 3 18.0003H9C9.24387 18.0003 9.46735 17.913 9.64089 17.768ZM9.33118 3.19114L6.48417 13.8163C6.34122 14.3498 6.65781 14.8981 7.19127 15.0411L12.9868 16.594C13.5203 16.7369 14.0686 16.4203 14.2116 15.8869L17.0586 5.2617C17.2015 4.72823 16.8849 4.17989 16.3515 4.03695L10.5559 2.48404C10.0225 2.3411 9.47412 2.65768 9.33118 3.19114Z"
        fill="#666666"
      />
    </svg>
  );
};
