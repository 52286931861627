import React from 'react';
import { useTheme } from 'styled-components';

export const PadlockIcon = ({ color }: { color?: string }) => {
  const theme = useTheme();
  const defaultColor = theme.colors.text.darkerGrey;

  return (
    <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.5 14.2324C10.0978 13.8866 10.5 13.2403 10.5 12.5C10.5 11.3954 9.60457 10.5 8.5 10.5C7.39543 10.5 6.5 11.3954 6.5 12.5C6.5 13.2403 6.9022 13.8866 7.5 14.2324V15.5C7.5 16.0523 7.94772 16.5 8.5 16.5C9.05229 16.5 9.5 16.0523 9.5 15.5V14.2324Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.5 0.5C5.73858 0.5 3.5 2.73858 3.5 5.5V7.5H1.5C0.947715 7.5 0.5 7.94771 0.5 8.5V18.5C0.5 19.0523 0.947715 19.5 1.5 19.5H15.5C16.0523 19.5 16.5 19.0523 16.5 18.5V8.5C16.5 7.94772 16.0523 7.5 15.5 7.5H13.5V5.5C13.5 2.73858 11.2614 0.5 8.5 0.5ZM11.5 7.5V5.5C11.5 3.84315 10.1569 2.5 8.5 2.5C6.84315 2.5 5.5 3.84315 5.5 5.5V7.5H11.5ZM2.5 9.5V17.5H14.5V9.5H2.5Z"
        fill="#333333"
      />
    </svg>
  );
};
