import React from 'react';
import { PaymentMethodWithContracts } from '@/types/paymentMethods';
import { PaymentMethodBrand } from '../PaymentMethodBrand';
import {
  PaymentMethodSingleViewRoot,
  PaymentMethodSingleViewLoadingRoot,
  GrownEllipsis,
  FlexBottomLine,
  BottomWrapper,
} from './PaymentMethodSingleView.styles';
import { Text } from '@/components/Text';
import { Group } from '@/components/Group';
import { Offset } from '@/components/Offset';
import { LoadingUnit } from '@/components/Loading';
import { getExpiry } from '@/components/PaymentMethod/helpers';
import { SecondaryText } from '../PaymentMethod.styles';
import { useTranslation } from 'react-i18next';
import { getPrimaryText, getDate, getPaymentMethodDisplayType } from '@/utils/paymentMethods';
import { tk } from '@/i18n/translationKeys';
import { PaymentMethodStatus } from '@/generated/types';
import { InvalidNote } from '../InvalidNote';
import { useApplicationData } from '@/providers/applicationData';
import { useTheme } from 'styled-components';

interface PaymentMethodCardProps {
  paymentMethod?: PaymentMethodWithContracts;
  editingView?: boolean;
  numberLength?: number;
  loading?: boolean;
  cardNumber?: string;
}

export const PaymentMethodSingleView = ({
  paymentMethod,
  editingView,
  numberLength,
  loading,
  cardNumber,
}: PaymentMethodCardProps) => {
  const { t } = useTranslation();
  const { user } = useApplicationData();
  const theme = useTheme();

  const invalid = paymentMethod?.status === PaymentMethodStatus.INVALID;

  if (loading) {
    return (
      <PaymentMethodSingleViewLoadingRoot>
        <LoadingUnit width={16} height={6.5} />

        <Group vertical gap={6} mobileGap={6}>
          <LoadingUnit width={24} height={6} />
          <LoadingUnit width={20} height={4.25} />
        </Group>
      </PaymentMethodSingleViewLoadingRoot>
    );
  }

  if (!paymentMethod) {
    throw new Error('Error in PaymentMethodSingleView, no paymentMethod');
  }

  const { expiryStatus, expiryText } = getExpiry(paymentMethod, t);

  const renderBottom = () => {
    if (paymentMethod.__typename === 'ByInvoicePaymentMethod') {
      return (
        <Text typography="TinyParagraph" color={theme.colors.text.darkerGrey}>
          {getPrimaryText({ paymentMethod, singleView: true, t })}
        </Text>
      );
    }

    if (paymentMethod.__typename === 'CardPaymentMethod') {
      return (
        <>
          <Offset bottom={6}>
            <Text typography="Paragraph" color={theme.colors.text.darkerGrey}>
              {getPrimaryText({
                paymentMethod,
                singleView: !editingView,
                editingView,
                numberLength,
                cardNumber,
                t,
              })}
            </Text>
          </Offset>
          <FlexBottomLine>
            <GrownEllipsis typography="TinyParagraph" color={theme.colors.text.darkerGrey}>
              {paymentMethod.holderName ? paymentMethod.holderName : t(tk.emptyCardHolderName)}
            </GrownEllipsis>
            {invalid ? (
              <InvalidNote />
            ) : (
              <SecondaryText typography="TinyParagraph" status={expiryStatus}>
                {expiryText}
              </SecondaryText>
            )}
          </FlexBottomLine>
        </>
      );
    }

    if (paymentMethod.__typename === 'SepaPaymentMethod') {
      return (
        <>
          <Offset bottom={6}>
            <Text typography="Paragraph" color={theme.colors.text.darkerGrey}>
              {getPrimaryText({ paymentMethod, t })}
            </Text>
          </Offset>
          <FlexBottomLine>
            <GrownEllipsis
              typography="TinyParagraph"
              color={theme.colors.text.darkerGrey}
              data-testid="sepa-payment-method-user-name"
            >
              {user.firstName} {user.lastName}
            </GrownEllipsis>
            {invalid ? (
              <InvalidNote />
            ) : (
              <SecondaryText
                typography="TinyParagraph"
                data-testid="sepa-payment-method-date-added"
              >
                {t(tk.paymentMethodDateAddedText)}: {getDate(paymentMethod.createdAt)}
              </SecondaryText>
            )}
          </FlexBottomLine>
        </>
      );
    }

    return (
      <FlexBottomLine>
        <GrownEllipsis typography="Paragraph" color={theme.colors.text.darkerGrey}>
          {getPrimaryText({ paymentMethod, t })}
        </GrownEllipsis>
        {invalid ? <InvalidNote /> : null}
      </FlexBottomLine>
    );
  };

  const renderBrand = () => {
    const iconKey = getPaymentMethodDisplayType(paymentMethod);

    if (paymentMethod.__typename === 'ByInvoicePaymentMethod') {
      return (
        <Group gap={2} mobileGap={2} centered>
          <PaymentMethodBrand iconKey={iconKey} size="normal" />
          <Text bold typography="TinyParagraph" color={theme.colors.text.darkerGrey}>
            {t(tk.paymentMethodPayByInvoiceBrand)}
          </Text>
        </Group>
      );
    }

    return (
      <PaymentMethodBrand
        iconKey={iconKey}
        size="large"
        cardNumber={cardNumber}
        editingView={editingView}
      />
    );
  };

  return (
    <PaymentMethodSingleViewRoot data-testid="payment-method-single-view">
      {renderBrand()}

      <BottomWrapper>{renderBottom()}</BottomWrapper>
    </PaymentMethodSingleViewRoot>
  );
};
