import styled from 'styled-components';

import {
  Icon as RadixIconWrapper,
  Content as RadixContentWrapper,
  Item as RadixItemWrapper,
  Trigger as RadixTrigger,
  Viewport as RadixViewport,
} from '@radix-ui/react-select';

export const TriggerWrapper = styled.div<{
  $border?: string;
  $backgroundColor?: string;
}>`
  display: flex;
  align-items: center;
  height: 32px;
  width: fit-content;
  padding: 6px 8px;
  background-color: ${({ $backgroundColor, theme }) =>
    $backgroundColor || theme.colors.background.white};
  border: ${({ $border, theme }) => $border || `1px solid ${theme.colors.stroke.lighterGrey}`};
  border-radius: ${({ theme }) => theme.borderRadius.full};
`;

export const Trigger = styled(RadixTrigger)<{
  $padding?: string;
  $backgroundColor?: string;
}>`
  ${({ theme }) => theme.typography['RegularParagraph']};
  color: ${({ theme }) => theme.colors.text.darkerGrey};
  background-color: ${({ $backgroundColor, theme }) =>
    $backgroundColor || theme.colors.background.superLightGrey};
  padding: ${({ $padding, theme }) => $padding || `4px ${theme.spacers['2M']}`};
  height: 32px;
  max-width: 268px;
  border-radius: ${({ theme }) => theme.borderRadius.full};
  display: inline-flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacers.M};
  outline: none;
  cursor: pointer;

  > span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  > * {
    pointer-events: none;
  }
`;

export const IconWrapper = styled(RadixIconWrapper)`
  cursor: pointer;
`;

export const ContentWrapper = styled(RadixContentWrapper)<{
  $maxWidth?: string;
}>`
  max-width: ${({ $maxWidth }) => $maxWidth || 'unset'};
  background-color: ${({ theme }) => theme.colors.background.white};
  border-radius: ${({ theme }) => theme.borderRadius.M};
  padding: 16px 16px 0 16px;
  box-shadow: 0px 4px 10px 0px #00000021;
`;

export const ContentViewport = styled(RadixViewport)`
  display: flex;
  flex-direction: column;
`;

export const ContentScrollable = styled.div<{
  $maxHeight: number;
}>`
  overflow-y: auto;
  scroll-behavior: smooth;
  max-height: ${({ $maxHeight }) => `${$maxHeight}px`};

  > * {
    display: flex;
    flex-direction: column;
  }
`;

export const ItemWrapper = styled(RadixItemWrapper)`
  ${({ theme }) => theme.typography['RegularParagraph']};
  color: ${({ theme }) => theme.colors.text.darkerGrey};
  background-color: ${({ theme }) => theme.colors.background.white};
  display: inline-flex;
  align-items: center;
  padding: ${({ theme }) => `0 ${theme.spacers['2M']}`};
  gap: ${({ theme }) => theme.spacers.M};
  margin-bottom: 16px;
  user-select: none;
  cursor: pointer;
  outline: none;

  > span {
    display: flex;
    align-items: center;
  }

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colors.background.superLightGrey};
  }
`;
