import React from 'react';
import { useTheme } from 'styled-components';

export const ChevronRightIcon = ({ color }: { color?: string }) => {
  const theme = useTheme();
  const defaultColor = theme.colors.text.darkerGrey;

  return (
    <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.09367 9.70711C0.70315 9.31658 0.70315 8.68342 1.09367 8.29289L4.38657 5L1.09367 1.70711C0.70315 1.31658 0.70315 0.683417 1.09367 0.292893C1.4842 -0.0976315 2.11736 -0.0976315 2.50789 0.292893L7.21499 5L2.50789 9.70711C2.11736 10.0976 1.4842 10.0976 1.09367 9.70711Z"
        fill={color || defaultColor}
      />
    </svg>
  );
};
