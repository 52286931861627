import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonV2 as Button } from '@getgrover/ui';
import Nessie from './assets/Nessie.svg';
import { Text } from '@/components/Text';
import { Container, Image, ErrorStyledText } from './styles';
import { tk } from '@/i18n/translationKeys';
import { logger } from '@/logger';
import { trackNotFoundPageShownEvent } from '@/analytics';
import { useApplicationData } from '@/providers/applicationData';
import { useTheme } from 'styled-components';

const hostBaseUrl = '/';

interface NotFoundProps {
  buttonHref?: string;
  buttonText?: string;
}

export const NotFound = ({ buttonHref, buttonText }: NotFoundProps): JSX.Element => {
  const { t } = useTranslation();
  const { userId, storeCode } = useApplicationData();
  const theme = useTheme();
  
  useEffect(() => {
    logger.error('404ErrorNotFoundShown');
    trackNotFoundPageShownEvent({ userId, store: storeCode });
  }, []);

  return (
    <Container data-testid="page-not-found" vertical gap={4} mobileGap={4}>
      <Image src={Nessie} />
      <Text centered typography="SmallCapsHeadline" color={theme.colors.text.darkerGrey}>
        {t(tk.pageNotFoundStatusText, { errorStatus: 404 })}
      </Text>
      <Text centered typography="Subheadline" color={theme.colors.text.darkerGrey}>
        {t(tk.pageNotFoundTitle)}
      </Text>
      <ErrorStyledText typography="Paragraph" color={theme.colors.text.darkerGrey}>
        {t(tk.pageNotFoundText)}
      </ErrorStyledText>

      <Button small href={buttonHref || hostBaseUrl}>
        <Text typography="SuperTinyHeadline">{buttonText || t(tk.pageNotFoundButtonText)}</Text>
      </Button>
    </Container>
  );
};
