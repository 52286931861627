import { LOCALE } from '@/utils/date';

export function formatPriceByLanguage(
  priceInCents: number,
  activeLanguage: LOCALE,
  currencySymbol: string
): string {
  if (activeLanguage === 'en') {
    const formattedPrice = new Intl.NumberFormat('en', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(priceInCents / 100);
    return `${currencySymbol}${formattedPrice}`;
  }
  return `${new Intl.NumberFormat('de', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(priceInCents / 100)}${' '}${currencySymbol}`;
}
