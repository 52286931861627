import { useState } from 'react';
import { useQuery } from '@apollo/client';

import { PAYMENTS_DETAILS_QUERY, SUBSCRIPTION_PAYMENTS_DETAILS_QUERY } from '@/gql/queries';
import { mapPaymentsDetailsQueryResponseToTopPane } from '../mappers';
import { PaymentsDetailsType, SubscriptionDetails } from '../types';

type UsePaymentsDetails = (args: {
  userId: string;
  subscriptionId?: string;
}) => PaymentsDetailsType;

export const usePaymentsDetails: UsePaymentsDetails = ({ userId, subscriptionId }) => {
  const [paymentsDetails, setPaymentsDetails] = useState<PaymentsDetailsType>({
    error: false,
    loading: true,
    formattedPrice: null,
    subscriptionTitle: null,
  });

  const getSubscriptionTitle = (subscriptionDetails: SubscriptionDetails): string => {
    if (subscriptionDetails?.__typename === 'Flex') {
      return subscriptionDetails.contractName;
    }

    if (subscriptionDetails?.__typename === 'SubscriptionDetails') {
      return (subscriptionDetails.name as string) || '';
    }

    return '';
  };

  const handleQueryCompletion = (partialDetails: Partial<PaymentsDetailsType>) => {
    setPaymentsDetails((prevDetails) => ({
      ...prevDetails,
      ...partialDetails,
    }));
  };

  useQuery(PAYMENTS_DETAILS_QUERY, {
    variables: { userId: userId as string },
    fetchPolicy: 'network-only',
    skip: !userId,
    onCompleted: (paymentsDetailsQueryResponse) => {
      if (!paymentsDetailsQueryResponse.user) {
        return handleQueryCompletion({
          error: true,
          loading: false,
          formattedPrice: null,
          subscriptionTitle: null,
        });
      }

      handleQueryCompletion({
        error: false,
        loading: false,
        formattedPrice: mapPaymentsDetailsQueryResponseToTopPane(paymentsDetailsQueryResponse),
      });
    },
    onError: () => {
      handleQueryCompletion({
        error: true,
        loading: false,
        formattedPrice: null,
        subscriptionTitle: null,
      });
    },
  });

  useQuery(SUBSCRIPTION_PAYMENTS_DETAILS_QUERY, {
    variables: { id: subscriptionId as string },
    fetchPolicy: 'network-only',
    skip: !subscriptionId,
    onCompleted: ({ subscriptionDetails }) => {
      if (!subscriptionDetails) {
        return handleQueryCompletion({
          error: true,
          loading: false,
          formattedPrice: null,
          subscriptionTitle: null,
        });
      }

      handleQueryCompletion({
        error: false,
        loading: false,
        subscriptionTitle: getSubscriptionTitle(subscriptionDetails),
      });
    },
    onError: () => {
      handleQueryCompletion({
        error: true,
        loading: false,
        formattedPrice: null,
        subscriptionTitle: null,
      });
    },
  });

  return paymentsDetails;
};
