import styled from 'styled-components';

const BOTTOM_NAV_HEIGHT = 64;

export const DesktopContainer = styled.main`
  position: relative;
  align-self: center;
  display: flex;
  flex-direction: column;
  width: 460px;
`;

export const MobileContainer = styled.main`
  position: relative;
  align-self: center;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  padding: 0 8px ${24 + BOTTOM_NAV_HEIGHT}px 8px;
`;
