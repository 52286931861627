import React from 'react';
import { useScreenMatch } from '@/hooks/useScreenMatch';

interface DisplayIfProps {
  mobile?: boolean;
  tablet?: boolean;
  desktop?: boolean;
  largeDesktop?: boolean;
  wideScreen?: boolean;
  children: React.ReactNode | React.ReactNode[];
}

export const DisplayIf = (props: DisplayIfProps) => {
  const { children, ...deviceTypesProps } = props;
  const { deviceType } = useScreenMatch();

  if (deviceTypesProps[deviceType]) {
    return <>{children}</>;
  }

  return null;
};
