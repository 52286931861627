import axios, { AxiosInstance } from 'axios';
import { loadBrowserEnv } from '@/config';
import { errorHandlerInterceptor } from './errorHandlerInterceptor';
import { headersInterceptor } from './headersInterceptor';
import { requestInterceptor } from './requestInterceptor';
import { responseInterceptor } from './responseInterceptor';

const browserEnv = loadBrowserEnv();

function createAxiosInstance(baseURL: string): AxiosInstance {
  const headers = {
    'Content-Type': 'application/json',
  };

  const configs = {
    baseURL,
    headers,
  };

  return axios.create(configs);
}

const axiosRequest = createAxiosInstance(browserEnv.API_BASE_URL);

/**
 * Request Interceptors
 */
axiosRequest.interceptors.request.use(headersInterceptor as any);
axiosRequest.interceptors.request.use(requestInterceptor as any);

/**
 * Response Interceptors
 */
axiosRequest.interceptors.response.use(responseInterceptor, errorHandlerInterceptor);

export { axiosRequest };

declare enum Currency {
  EUR = 'EUR',
  USD = 'USD',
  GBP = 'GBP',
}
export type GroverStore = {
  checkout_text: string;
  code: string;
  country_code: string;
  country_id: number;
  current: string;
  default_currency: Currency;
  default_language: string;
  enabled_on_footer: boolean;
  languages: Array<string>;
  logo_url: string;
  name: string;
  new_checkout_enabled: boolean;
  offline: boolean;
  shipping_cost: number;
  store_id: number;
  store_type: 'main' | 'retail' | 'legacy' | 'grover_pay' | 'business';
  url: string;
};
export const getActiveStore = async (store: string): Promise<GroverStore> => {
  const { data } = await axiosRequest.get(`/api/v1/stores/${store}`);
  const activeStore: GroverStore = data.store;
  return activeStore;
};
