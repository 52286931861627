import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import de from './translations/de.json';
import es from './translations/es.json';
import en from './translations/en.json';
import nl from './translations/nl.json';

export const initI18n = (locale: string) => {
  i18n.use(initReactI18next).init({
    resources: {
      en: {
        translation: en,
      },
      de: {
        translation: de,
      },
      nl: {
        translation: nl,
      },
      es: {
        translation: es,
      },
    },
    lng: locale,
    fallbackLng: 'de',
    interpolation: {
      escapeValue: false,
    },
  });
};
