import { PaymentOption, TransactionErrorCode } from '@/generated/types';

export enum ScreenKey {
  paymentMethodOptions,
  addPaymentMethodError,
  paypalRedirection,
  sepaRedirection,
  createdPaymentMethodPolling,
}

export interface ScreenData {
  customErrorCode?: TransactionErrorCode | AddPaymentMethodOtherError;
}

export enum AddPaymentMethodOtherError {
  WAS_NOT_ACTIVATED_DURING_POLLING_TIME = 'WAS_NOT_ACTIVATED_DURING_POLLING_TIME',
  IXOPAY_TOKEN_CREATION_FAILED = 'IXOPAY_TOKEN_CREATION_FAILED',
}

export interface CurrentScreen {
  key: ScreenKey;
  data: ScreenData;
}

export interface ScreenCommon {
  currentScreen: CurrentScreen;
  setScreen: (screenKey: ScreenKey, screenData: ScreenData) => void;
  onClose: () => void;
  onBack: () => void;
  paymentOptions: PaymentOption[];
}
