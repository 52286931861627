import { User } from '@/generated/types';
import { DEFAULT_CURRENCY, DEFAULT_LOCALE } from '@/pages/YourPaymentsPage/constants';
import { CURRENCIES_ASSOCIATION } from '@/constants';
import { formatPriceByLanguage } from '@/utils/money';
import { getLocale } from '@/utils/cookies';

export const mapPaymentsDetailsQueryResponseToTopPane = (
  paymentsDetailsQueryResponse: { user: User },
): string => {
  const { monthlyTotal, activeSubscriptionValue } = paymentsDetailsQueryResponse.user;
  const inCents = monthlyTotal.inCents + activeSubscriptionValue.inCents;
  const currency = activeSubscriptionValue.currency ?? DEFAULT_CURRENCY;
  const currencySymbol = CURRENCIES_ASSOCIATION[currency];
  const locale = getLocale() || DEFAULT_LOCALE;

  const formattedPrice = formatPriceByLanguage(inCents, locale, currencySymbol) as string;

  return formattedPrice;
};
