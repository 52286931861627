import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Toast } from '@/components/Toast';
import { Text } from '@/components/Text';
import { Group } from '@/components/Group';
import { Offset } from '@/components/Offset';
import { CircleLoader } from '@/components/CircleLoader';
import {
  CREATE_PM_POLL_INTERVAL,
  CREATE_PM_POLLING_ENABLED_FOR,
} from '@/pages/AddPaymentMethodPage/constants';
import { useApplicationData } from '@/providers/applicationData';
import { Height } from '../../AddPaymentMethodPage.styles';
import { useScreenMatch } from '@/hooks/useScreenMatch';
import { tk } from '@/i18n/translationKeys';
import { useQuery } from '@apollo/client';
import { SINGLE_PAYMENT_METHOD_STATUS } from '@/gql/queries';
import { FeatureName, LogPrefix, logger } from '@/logger';
import { PaymentMethodStatus } from '@/generated/types';
import { AddPaymentMethodOtherError, ScreenCommon, ScreenKey } from '../../types';
import { QueryParamName } from '@/constants';
import { trackAddPaymentMethodSuccessEvent } from '@/analytics';
import { useTheme } from 'styled-components';

// When we create a payment method we do:
// - call to our api to create an actual PM then
// - redirect to paypal/3d secure/something else on provider side then
// - customer confirms adding PM to grover on provider side then
// - provider notifies grover that customer confirmed then
// - redirect back to the url the customer were redirected from (bulk/change/list)
// --------------------------
// After back redirection race condition is happening:
// - frontend fetching list of PM on bulk/change/list page,
// - but grover (wallet service) didn't process yet the request from payment provider and didn't mark the newly
//   created PM as ACTIVE. As a result we fetch the list of PM with omited NOT ACTIVE PMs.
// --------------------------
// To solve the problem we have some intermediate step on AddPaymentMethodPage
// it's this screen with polling until PM is ACTIVE, after we're redirected from payment provider back to AddPaymentMethodPage.
export const CreatedPaymentMethodPollingScreen = ({ setScreen }: ScreenCommon) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { storeCode, platform, rawUseragent, userId, routeBuilder } = useApplicationData();
  const { isMobile } = useScreenMatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const bulkUpdatePaymentMethodId = searchParams.get(QueryParamName.bulkId);
  const mode = searchParams.get(QueryParamName.mode);
  const changePaymentMethodSubscriptionId = searchParams.get(QueryParamName.subscriptionId);
  const createdPaymentMethodId = searchParams.get(QueryParamName.paymentMethodId);

  const {
    data: pollingData,
    error: pollingError,
    stopPolling,
  } = useQuery(SINGLE_PAYMENT_METHOD_STATUS, {
    variables: {
      paymentMethodId: createdPaymentMethodId,
    },
    pollInterval: CREATE_PM_POLL_INTERVAL,
    skip: !createdPaymentMethodId,
  });

  useEffect(() => {
    if (!pollingData) {
      return;
    }

    const { status, registrationErrorCode, __typename } = pollingData.paymentMethod;

    if (registrationErrorCode && status === PaymentMethodStatus.ERROR) {
      return setScreen(ScreenKey.addPaymentMethodError, { customErrorCode: registrationErrorCode });
    }

    if (status !== PaymentMethodStatus.ACTIVE) {
      return;
    }

    stopPolling();

    logger.info(LogPrefix.Success, { feature: FeatureName.AddPaymentMethod, type: __typename });
    logger.info(LogPrefix.Success, {
      feature: 'AddPM_Sample_10_test',
      type: __typename,
      sampleRate: 10,
    });
    trackAddPaymentMethodSuccessEvent({
      userId,
      store: storeCode,
      legacy: false,
      platform,
      rawUseragent,
    });

    toast(
      <Toast
        type="success"
        mobile={false}
        title={t(tk.addPaymentMethodSuccessTitle)}
        text={t(tk.addPaymentMethodSuccessText)}
      />
    );

    const query: Record<string, string> = {};
    if (createdPaymentMethodId) query[QueryParamName.paymentMethodId] = createdPaymentMethodId;
    if (mode) query[QueryParamName.mode] = mode;

    if (bulkUpdatePaymentMethodId) {
      return navigate(
        routeBuilder.buildPaymentMethodBulkUpdateRoute({
          paymentMethodId: bulkUpdatePaymentMethodId,
          query: query,
        })
      );
    }

    if (changePaymentMethodSubscriptionId) {
      return navigate(
        routeBuilder.buildChangePaymentMethodRoute({
          subscriptionId: changePaymentMethodSubscriptionId,
          query: query,
        })
      );
    }

    return navigate(routeBuilder.buildPaymentMethodListRoute());
  }, [pollingData]);

  useEffect(() => {
    const timeoutId = setTimeout(async () => {
      stopPolling();

      setScreen(ScreenKey.addPaymentMethodError, {
        customErrorCode: AddPaymentMethodOtherError.WAS_NOT_ACTIVATED_DURING_POLLING_TIME,
      });
    }, CREATE_PM_POLLING_ENABLED_FOR);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <Height units={68} mobile={isMobile}>
      <Offset top={36}>
        <Group centered vertical gap={8} mobileGap={8}>
          <CircleLoader />
          <Text
            centered
            typography="Paragraph"
            color={theme.colors.text.baseGrey}
            data-testid="loading-screen-text"
          >
            {t(tk.commonLoadingText)}
          </Text>
        </Group>
      </Offset>
    </Height>
  );
};
