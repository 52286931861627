import { ErrorResponse, onError } from '@apollo/client/link/error';
import { logger } from '@/logger';
import { HeaderKey } from '../types';

const handleApolloErrors = ({
  graphQLErrors,
  networkError,
  operation,
}: Partial<ErrorResponse>): void => {
  const userId = operation?.getContext()?.headers[HeaderKey.USER];
  const storeCode = operation?.getContext()?.headers[HeaderKey.STORE];
  if (graphQLErrors) {
    graphQLErrors.forEach((graphQLError) => {
      logger.error(
        `ApolloError: an error ocurrend for ${operation?.operationName} - ${graphQLError.message}`,
        {
          operationName: operation?.operationName,
          userId,
          storeCode,
          serviceName: graphQLError?.extensions?.serviceName as string | undefined,
          code: graphQLError?.extensions?.code as string | undefined,
          error: graphQLError,
        }
      );
    });
  }

  if (networkError) {
    const errorCode = (networkError as any)?.result?.errors?.[0]?.extensions?.code;

    if (errorCode === 'UNAUTHENTICATED') {
      logger.error(`ApolloError: networkAuthError: ${networkError.message}`, {
        userId,
        storeCode,
        error: networkError,
      });
    } else {
      logger.error(`ApolloError: networkError: ${networkError.message}`, {
        userId,
        storeCode,
        error: networkError,
      });
    }
  }
};

export const errorLink = onError(({ graphQLErrors, networkError, operation }) =>
  handleApolloErrors({ graphQLErrors, networkError, operation })
);
