export enum WebViewEventType {
  listen = 'listen',
  remove = 'remove',
  fire = 'fire',
}

export enum WebViewEventName {
  TOGGLE_ACCOUNT_MENU = 'toggleAccountMenu',
  CLOSE_ACCOUNT_MENU = 'closeAccountMenu',
  AUTH_TOKEN_COOKIE = 'AUTH_TOKEN_COOKIE',
  USER_ID_COOKIE = 'USER_ID_COOKIE',
  TOGGLE_OVERLAY = 'TOGGLE_OVERLAY',
  REMOVE_OVERLAY = 'REMOVE_OVERLAY',
  ROUTE_CHANGED = 'routeChanged',
  TOGGLE_FILTER_LIST = 'TOGGLE_FILTER_LIST',
  DEVICE_NAVIGATION_CLICK = 'DEVICE_NAVIGATION_CLICK',
  SIGN_UP = 'auth/SIGN_UP',
  USER_FINAL_SIGNED_UP = 'USER_FINAL_SIGNED_UP',
  TOGGLE_INTERCOM = 'TOGGLE_INTERCOM',
  SET_COOKIES = 'SET_COOKIES',
  REMOVE_ORDER_COOKIES = 'REMOVE_ORDER_COOKIES',
  ONFIDO_LOADED = 'ONFIDO_LOADED',
  GET_RETURN_LABEL = 'GET_RETURN_LABEL',
  WEBVIEW_LOADED = 'WEBVIEW_LOADED',
  SHARE = 'SHARE',
  HTTP_ERROR_TRIGGERED = 'HTTP_ERROR_TRIGGERED',
  SET_APP_DATA = 'SET_APP_DATA',
  WEB_VIEW_LOADED = 'WEB_VIEW_LOADED',
  STORE_CHANGED = 'STORE_CHANGED',
  GET_USER = 'auth/GET_USER',
  USER_LOGIN = 'USER_LOGIN',
  USER_LOGOUT = 'auth/USER_LOGOUT',
  TOKENS_UPDATED = 'TOKENS_UPDATED',
  RATING_CLICKED = 'RATING_CLICKED',
  RATING_SUCCESS = 'RATING_SUCCESS',
  NAVIGATE_TO_CARD = 'NAVIGATE_TO_CARD',
  SET_ACTIVE_STORE_LANGUAGE = 'SET_ACTIVE_STORE_LANGUAGE',
  SHUTDOWN_INTERCOM = 'intercom/SHUTDOWN',
  GET_STORE_SUCCESS = 'store/GET_STORE_SUCCESS',
  OPEN_CHECKOUT = 'OPEN_CHECKOUT',
  OPEN_CHECKOUT_AUTH = 'OPEN_CHECKOUT_AUTH',
  USER_LOGGED_IN_VIA_APP = 'USER_LOGGED_IN_VIA_APP',
  DOWNLOAD_INVOICE = 'DOWNLOAD_INVOICE',
  REFRESH_TOKEN = 'REFRESH_TOKEN',
  REQUEST_NATIVE_AUTH_STATE = 'REQUEST_NATIVE_AUTH_STATE',
  NATIVE_AUTH_STATE_UPDATED = 'NATIVE_AUTH_STATE_UPDATED',
  CLIPBOARD_WRITE = 'CLIPBOARD_WRITE',
}

export type EventHandler = (args?: any) => void;

export type EventArgs = Record<string, any>;

export type EventPayload = [
  type: WebViewEventType,
  eventName: WebViewEventName,
  payload: EventHandler | EventArgs | string | undefined
];

export type EventListenerWithPromiseOptions = {
  timeout: number;
};

declare global {
  interface Window {
    InWebViewAPI?: {
      attached: boolean;
      getEventHandlersCount: (event: WebViewEventName) => number;
      push: (payload: EventPayload) => void;
    };
    InWebViewAPITest?: any[];
    ReactNativeWebView: string;
  }
}
