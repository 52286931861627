import { PaymentsUnion } from '@/generated/types';
import { TFn } from '@/pages/YourPaymentsPage/types';
import { tk } from '@/i18n/translationKeys';

export const getTitle = (payment: PaymentsUnion, t: TFn): string => {
  switch (payment.__typename) {
    case 'OrderPayment': {
      const prefix = t(tk.yourPaymentsOrderPaymentTitle);

      const { order } = payment;

      if (order.__typename === 'MixCartOrder') {
        return `${prefix} ${order.orderNumber}`;
      }

      if (order.__typename === 'Order') {
        return `${prefix} ${order.number}`;
      }

      return prefix;
    }
    case 'GroupPayment': {
      return t(tk.yourPaymentsGroupPaymentTitle);
    }
    case 'PurchasePayment':
    case 'SubscriptionPayment': {
      const { contract, subscriptionDetails } = payment ?? {};

      if (contract?.__typename === 'Mix') {
        return t(tk.yourPaymentsMixPaymentTitle);
      }

      if (contract?.__typename === 'Flex') {
        return contract.name;
      }

      return subscriptionDetails?.name ?? '';
    }
    default: {
      return '';
    }
  }
};
