export const CURRENCIES_ASSOCIATION: Record<string, string> = {
  EUR: '€',
  USD: '$',
  GBP: '£',
};

export const DEFAULT_STORE_CODE = 'de';

export enum QueryParamName {
  subscriptionId = 'subscription-id',
  paymentMethodId = 'pm-id',
  paymentMethodStatus = 'p-status',
  bulkId = 'bulk-id',
  mode = 'mode',
  from = 'from',
}
