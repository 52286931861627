import React from 'react';

const SuccessIcon = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9998 26.8333C6.63583 26.8333 0.666504 20.864 0.666504 13.5C0.666504 6.13599 6.63583 0.166656 13.9998 0.166656C21.3638 0.166656 27.3332 6.13599 27.3332 13.5C27.3332 20.864 21.3638 26.8333 13.9998 26.8333ZM20.276 11.7761C20.7967 11.2554 20.7967 10.4112 20.276 9.89051C19.7553 9.36982 18.9111 9.36982 18.3904 9.89051L12.6665 15.6144L9.60931 12.5572C9.08861 12.0365 8.24439 12.0365 7.7237 12.5572C7.203 13.0779 7.203 13.9221 7.7237 14.4428L12.6665 19.3856L20.276 11.7761Z"
        fill="#53B023"
        width="100%"
        height="100%"
      />
    </svg>
  );
};

export default SuccessIcon;
