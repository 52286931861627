import React from 'react';
import { useTheme } from 'styled-components';

export const ArrowLeftIcon = ({ color }: { color?: string }) => {
  const theme = useTheme();
  const defaultColor = theme.colors.text.darkerGrey;

  return (
    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.12132 13.7071C8.51184 13.3166 8.51184 12.6834 8.12132 12.2929L3.82842 8L16.4142 7.99999C16.9665 7.99999 17.4142 7.55228 17.4142 6.99999C17.4142 6.44771 16.9665 5.99999 16.4142 5.99999L3.82842 6L8.12131 1.7071C8.51184 1.31658 8.51184 0.683414 8.12131 0.29289C7.73079 -0.0976348 7.09762 -0.0976346 6.7071 0.29289L-3.0598e-06 7L6.70711 13.7071C7.09763 14.0976 7.7308 14.0976 8.12132 13.7071Z"
        fill={color || defaultColor}
      />
    </svg>
  );
};
