import React, { useEffect } from 'react';
import Markdown from 'markdown-to-jsx';
import { useError } from '@/hooks/useError';
import { Group } from '@/components/Group';
import { Offset } from '@/components/Offset';
import { PageContainer } from '@/components/PageContainer';
import { DeleteButton } from '@/pages/PaymentMethodDetailsPage/DeleteButton';
import { useApplicationData } from '@/providers/applicationData';
import { useParams } from 'react-router-dom';
import { NavigationBar } from '@/components/NavigationBar';
import { DEFAULT_LOCALE } from '@/pages/YourPaymentsPage/constants';
import { getLocale } from '@/utils/cookies';
import { Page } from '@/components/Page';
import { usePaymentMethod } from '@/pages/PaymentMethodDetailsPage/usePaymentMethod';
import { CardWrapper, ExternalLink } from './styles';
import { PaymentMethodSingleView } from '@/components/PaymentMethod';
import { Notice } from '@/components/Notice';
import { WarningIcon } from '@/icons/WarningIcon';
import { getItemWithExpiry } from '@/utils/localStorage';
import { BULK_UPDATE_LOCAL_STORAGE_KEY_PREFIX } from '@/pages/ChangePaymentMethodPage/constants';
import { NoticeText } from '@/pages/PaymentMethodDetailsPage/NoticeText';
import { useTranslation } from 'react-i18next';
import { tk } from '@/i18n/translationKeys';
import {
  trackYourProfilePageVisitedEvent,
  trackPaymentMethodDetailsLinkToListClickEvent,
} from '@/analytics';
import { PaymentMethodDeletionLockReason, PaymentMethodStatus } from '@/generated/types';
import { InvalidIconOutlined } from '@/icons/InvalidIconOutlined';
import { DocumentTitle, PageName } from '@/components/DocumentTitle';
import { LinkedSubscriptions } from './LinkedSubscriptions';
import { NotFound } from '@/components/ErrorPages';

export const PaymentMethodDetailsPage = () => {
  const { t } = useTranslation();
  const { paymentMethodId } = useParams<{ paymentMethodId: string }>();
  const throwError = useError();
  const { userId, activeStore, storeCode, isConsolidatedBilling, routeBuilder } = useApplicationData();
  const { paymentMethod, loading, error } = usePaymentMethod(paymentMethodId);

  useEffect(() => {
    const locale = getLocale() || DEFAULT_LOCALE;

    trackYourProfilePageVisitedEvent({
      path: location.pathname,
      store: storeCode,
      store_id: activeStore.store_id,
      locale,
    });
  }, []);

  useEffect(() => {
    if (error) {
      throwError(error);
    }
  }, [error, throwError]);

  const sendNavigationBarClickAnalyticsEvent = () => {
    trackPaymentMethodDetailsLinkToListClickEvent({
      userId,
      store: storeCode,
    });
  };

  if (loading) {
    return (
      <Page>
        <PageContainer>
          <NavigationBar
            url={routeBuilder.buildPaymentMethodListRoute()}
            external={false}
            onClick={sendNavigationBarClickAnalyticsEvent}
            text={t(tk.paymentMethodListLinkText)}
          />
        </PageContainer>

        <CardWrapper>
          <PaymentMethodSingleView loading />
        </CardWrapper>
      </Page>
    );
  }

  if (!paymentMethod) {
    return (
      <Page>
        <NotFound 
          buttonHref={routeBuilder.buildPaymentMethodListRoute()}
          buttonText={t(tk.paymentMethodNotFoundButtonText)}
        />
      </Page>
    );
  }

  const hasLinkedContracts = !!paymentMethod.linkedContracts.length;
  const localStorageKey = `${BULK_UPDATE_LOCAL_STORAGE_KEY_PREFIX}${paymentMethodId}`;
  const localStorageData = getItemWithExpiry(localStorageKey);
  const inBulkUpdate = hasLinkedContracts && localStorageData;
  const hasProcessingOrders =
    paymentMethod.deletionLock?.reason === PaymentMethodDeletionLockReason.NEW_ORDER;
  const isInvalid = paymentMethod?.status === PaymentMethodStatus.INVALID;

  if (localStorageData && !hasLinkedContracts) {
    localStorage.removeItem(localStorageKey);
  }

  const renderTopWarning = () => {
    if (isInvalid) {
      return (
        <Offset top={-3} bottom={6}>
          <Notice
            icon={<InvalidIconOutlined />}
            status="error"
            text={
              <Markdown
                options={{
                  overrides: {
                    a: {
                      component: ExternalLink,
                    },
                  },
                }}
              >
                {t(tk.paymentMethodInvalidNoticeText)}
              </Markdown>
            }
          />
        </Offset>
      );
    }

    if (inBulkUpdate) {
      return (
        <Offset top={-3} bottom={6}>
          <Notice
            icon={<WarningIcon />}
            status="warn"
            text={
              <NoticeText
                highlightedText={localStorageData?.paymentMethodHighlight}
                deleteMode={localStorageData?.deleteMode}
              />
            }
          />
        </Offset>
      );
    }

    if (hasProcessingOrders) {
      return (
        <Offset top={-3} bottom={6}>
          <Notice
            icon={<WarningIcon />}
            status="warn"
            text={t(tk.paymentMethodDeletionLockReasonText)}
          />
        </Offset>
      );
    }

    return null;
  };

  const deleteDisabled =
    inBulkUpdate ||
    isConsolidatedBilling ||
    hasProcessingOrders ||
    paymentMethod.__typename === 'ByInvoicePaymentMethod';

  const renderBottomWarning = () => {
    if (deleteDisabled) {
      return (
        <Offset top={4}>
          <Notice
            icon={<WarningIcon />}
            status="warn"
            text={
              <>
                {`${t(tk.deletePaymentMethodWarningText)} `}
                <ExternalLink>{t(tk.deletePaymentMethodSupportLinkText)}</ExternalLink>
              </>
            }
          />
        </Offset>
      );
    }

    return null;
  };

  const topWarning = renderTopWarning();
  const bottomWarning = topWarning ? null : renderBottomWarning();

  return (
    <Page>
      <DocumentTitle pageName={PageName.paymentMethodDetails} />
      <PageContainer>
        <NavigationBar
          url={routeBuilder.buildPaymentMethodListRoute()}
          external={false}
          onClick={sendNavigationBarClickAnalyticsEvent}
          text={t(tk.paymentMethodListLinkText)}
        />

        <CardWrapper>
          <PaymentMethodSingleView paymentMethod={paymentMethod} />
        </CardWrapper>

        {topWarning}

        <Group vertical gap={4} mobileGap={4}>
          <LinkedSubscriptions paymentMethod={paymentMethod} inProgress={inBulkUpdate} />
          <DeleteButton
            paymentMethod={paymentMethod}
            userId={userId}
            disabled={deleteDisabled}
            hasLinkedContracts={hasLinkedContracts}
          />
        </Group>

        {bottomWarning}
      </PageContainer>
    </Page>
  );
};
