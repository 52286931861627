import styled from 'styled-components';

const spaceUnit = 4;

export const ContainerRoot = styled.div<{
  fullWidth?: boolean;
  noPadding?: boolean;
  heightInUnits?: number;
}>`
  border: 2px solid ${({ theme }) => theme.colors.stroke.lighterGrey};
  border-radius: 8px;
  padding: ${({ noPadding }) => (noPadding ? 0 : '16px')};
  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
  overflow: auto;
  position: relative;
  ${({ heightInUnits }) => (heightInUnits ? `height: ${heightInUnits * spaceUnit}px` : '')}
`;
