import styled from 'styled-components';

export const Root = styled.div<{ isMobile: boolean }>`
  position: relative;
  width: 100%;
  margin: 0 auto;
  max-width: ${({ isMobile }) => isMobile ? '720px' : '1080px'};
`;

export const BackButton = styled.div`
  margin-right: 20px;
  cursor: pointer;
`;
