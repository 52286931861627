import styled from 'styled-components';

export const Root = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10; // do we have z-index table???
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  padding: 16px;
`;

export const DialogBody = styled.div<{ mobile?: boolean }>`
  background: ${({ theme }) => theme.colors.background.white};
  padding: ${(props) => (props.mobile ? '32px 16px' : '24px 16px')};
  border-radius: 8px;
  width: 380px;
  max-width: 100%;
  box-sizing: border-box;
  position: relative;
`;
