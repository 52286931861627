/* eslint-disable */
import load from 'loadjs';
import { loadBrowserEnv } from '@/config';
import { logger } from '@/logger';
import { PaymentJsModel } from './types';

const browserEnv = loadBrowserEnv();

const IXOPAY_PAYMENT_LIBRARY_URL = browserEnv.IXOPAY_PAYMENT_LIBRARY_URL;
const IXOPAY_PAYMENT_LIBRARY_VERSION = browserEnv.IXOPAY_PAYMENT_LIBRARY_VERSION;

const pollingInterval = 750; // seconds -> ms
const pollingTries = 35;

const IXOPAY_ID = 'ixopayID';

export class GroverPaymentSDK {
  gatewayLibraryInstance: null | PaymentJsModel = null;

  // WL-2458 in order to make all work we load the library and it renders iframe on top of our form
  loadPaymentLibrary(): Promise<PaymentJsModel | void> {
    return new Promise((resolve, reject) => {
      const errorCb = (error: any) => {
        logger.error(
          `(loadPaymentLibrary): Error while loading payment library - ${error.message}`,
          {
            error,
            operationName: 'loadPaymentLibrary',
          }
        );
        reject(error);
      };

      // WL-2458 after downloading we have PaymentJs available in window so init it right away
      const successCb = () => {
        this.gatewayLibraryInstance = new (window as any).PaymentJs(IXOPAY_PAYMENT_LIBRARY_VERSION);
        logger.info('(loadPaymentLibrary): IXOPAY library loaded successfully');
        resolve(this.gatewayLibraryInstance as PaymentJsModel);
      };
      const addAttribute = (_: string, scriptElement: HTMLElement) => {
        scriptElement.dataset.main = 'payment-js';
      };

      if (load.isDefined(IXOPAY_ID)) {
        resolve(this.gatewayLibraryInstance as PaymentJsModel);
      }

      load([IXOPAY_PAYMENT_LIBRARY_URL], IXOPAY_ID, {
        error: errorCb,
        success: successCb,
        before: addAttribute,
      });
    });
  }

  getPaymentLibrary() {
    return this.gatewayLibraryInstance as PaymentJsModel;
  }
}
