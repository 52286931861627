import { sendAnalyticsEvent } from '@devsbb/analytics-client';

type EventProperties = {
  userId?: string;
  initialLoad?: boolean;
  store?: string;
};

export const trackErrorScreenShowedEvent = (properties: EventProperties) => {
  sendAnalyticsEvent<EventProperties>('Error Screen Showed', properties);
};

export const trackErrorScreenReloadButtonClickEvent = (properties: EventProperties) => {
  sendAnalyticsEvent<EventProperties>('Error Screen Reload Button Clicked', properties);
};

export const trackNotFoundPageShownEvent = (properties: EventProperties) => {
  sendAnalyticsEvent<EventProperties>('Not Found Page Shown', properties);
};
