import styled from 'styled-components';
import { Group, GroupProps } from '@/components/Group';

export const Actions = styled(Group)<{ mobile?: boolean }>`
  position: ${({ mobile }) => (mobile ? 'fixed' : 'absolute')};
  background: ${({ theme }) => theme.colors.background.white};
  bottom: 0;
  padding: 16px 0 32px 0;
  left: 16px;
  right: 16px;
`;

export const FullWidthGroup = styled(Group)<GroupProps>`
  width: 100%;
`;

export const ShadowWrapper = styled.div<{ shadowed: boolean }>`
  box-shadow: ${({ shadowed, theme }) =>
    shadowed ? `0 4px 10px -10px ${theme.colors.text.darkerGrey}` : 'none'};
  z-index: 1;
  position: relative;
  margin: 0 -16px 12px;
  padding: 0 16px 8px 16px;
  transition: box-shadow 0.15s ease-in-out;
`;

export const Grower = styled.div`
  flex-grow: 1;
  position: relative;
  overflow-y: scroll;
`;

export const PaymentsGroup = styled(Group)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
