import React from 'react';
import { useTranslation } from 'react-i18next';

import { tk } from '@/i18n/translationKeys';
import { useScreenMatch } from '@/hooks/useScreenMatch';
import { Text } from '@/components/Text';
import { PaymentState } from '@/generated/types';

import { TagRoot } from './StatusTag.styles';
import { DefaultTheme, useTheme } from 'styled-components';

interface StatusTagProps {
  status: PaymentState;
  typename: string;
}

type ColorText = {
  backgroundColor: keyof DefaultTheme['colors'] | string;
  textColor: keyof DefaultTheme['colors'] | string;
  text: string;
};

export const StatusTag = ({ status, typename }: StatusTagProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { isMobile } = useScreenMatch();

  const getColorText = (statusText: string, typename: string): ColorText => {
    if (typename === 'OrderPayment' && statusText === PaymentState.FAILED) {
      return {
        backgroundColor: theme.colors.background.superLightGrey,
        textColor: theme.colors.text.darkGrey,
        text: t(tk.yourPaymentsStateCanceled),
      };
    }

    switch (statusText) {
      case PaymentState.DUE:
        return {
          backgroundColor: theme.colors.background.lightYellow,
          textColor: theme.colors.status.darkYellow,
          text: t(tk.yourPaymentsStateDue),
        };
      case PaymentState.FAILED:
        return {
          backgroundColor: theme.colors.background.lightRed,
          textColor: theme.colors.status.baseRed,
          text: t(tk.yourPaymentsStateFailed),
        };
      case PaymentState.PAID:
        return {
          backgroundColor: theme.colors.background.lightGreen,
          textColor: theme.colors.status.darkGreen,
          text: t(tk.yourPaymentsStatePaid),
        };
      case PaymentState.SCHEDULED:
        return {
          backgroundColor: theme.colors.background.lightYellow,
          textColor: theme.colors.status.darkYellow,
          text: t(tk.yourPaymentsStateScheduled),
        };
      default:
        return {
          backgroundColor: theme.colors.background.lightYellow,
          textColor: theme.colors.status.darkYellow,
          text: '',
        };
    }
  };

  const { backgroundColor, textColor, text } = getColorText(status, typename);

  return (
    <TagRoot backgroundColor={backgroundColor} textColor={textColor} isMobile={isMobile}>
      <Text typography={isMobile ? 'TinyParagraph' : 'Paragraph'} centered>
        {text}
      </Text>
    </TagRoot>
  );
};
