import { TransactionErrorCode } from '@/generated/types';
import { tk } from '@/i18n/translationKeys';

export const translationsMap: Record<TransactionErrorCode, { title: string; text: string }> = {
  [TransactionErrorCode.OPERATION_FAILED]: {
    title: tk.addPaymentMethodErrorGenericTitle,
    text: tk.addPaymentMethodErrorGenericText,
  },
  [TransactionErrorCode.PAYPAL_ACCOUNT_CLOSED]: {
    title: tk.addPaymentMethodErrorGenericTitle,
    text: tk.addPaymentMethodErrorGenericText,
  },
  [TransactionErrorCode.PROVIDER_COMMUNICATION_PROBLEM]: {
    title: tk.addPaymentMethodErrorGenericTitle,
    text: tk.addPaymentMethodErrorGenericText,
  },
  [TransactionErrorCode.SEPA_INVALID_BIC]: {
    title: tk.addPaymentMethodErrorGenericTitle,
    text: tk.addPaymentMethodErrorGenericText,
  },
  [TransactionErrorCode.SEPA_INVALID_IBAN]: {
    title: tk.addPaymentMethodErrorGenericTitle,
    text: tk.addPaymentMethodErrorGenericText,
  },
  [TransactionErrorCode.BANKCARD_FRAUDULENT]: {
    title: tk.addPaymentMethodErrorFraudulentTitle,
    text: tk.addPaymentMethodErrorFraudulentText,
  },
  [TransactionErrorCode.BANKCARD_3DS_DECLINED]: {
    title: tk.addPaymentMethodError3DSVerificationTitle,
    text: tk.addPaymentMethodError3DSVerificationText,
  },
  [TransactionErrorCode.BANKCARD_3DS_FAILED]: {
    title: tk.addPaymentMethodError3DSVerificationTitle,
    text: tk.addPaymentMethodError3DSVerificationText,
  },
  [TransactionErrorCode.BANKCARD_INSUFFICIENT_FUNDS]: {
    title: tk.addPaymentMethodErrorBankDeclinedTransactionTitle,
    text: tk.addPaymentMethodErrorBankDeclinedTransactionText,
  },
  [TransactionErrorCode.BANKCARD_UNSUPPORTED]: {
    title: tk.addPaymentMethodErrorBlockedByRiskTitle,
    text: tk.addPaymentMethodErrorBlockedByRiskText,
  },
  [TransactionErrorCode.BLOCKED_BY_RISK_CHECK]: {
    title: tk.addPaymentMethodErrorBlockedByRiskTitle,
    text: tk.addPaymentMethodErrorBlockedByRiskText,
  },
  [TransactionErrorCode.BANKCARD_EXPIRED]: {
    title: tk.addPaymentMethodErrorCardExpiredTitle,
    text: tk.addPaymentMethodErrorCardExpiredText,
  },
  [TransactionErrorCode.BANKCARD_CLAIMED_AS_LOST]: {
    title: tk.addPaymentMethodErrorLostOrStolenTitle,
    text: tk.addPaymentMethodErrorLostOrStolenText,
  },
  [TransactionErrorCode.BANKCARD_CLAIMED_AS_STOLEN]: {
    title: tk.addPaymentMethodErrorLostOrStolenTitle,
    text: tk.addPaymentMethodErrorLostOrStolenText,
  },
  [TransactionErrorCode.BANKCARD_INVALID]: {
    title: tk.addPaymentMethodErrorLostOrStolenTitle,
    text: tk.addPaymentMethodErrorLostOrStolenText,
  },
  [TransactionErrorCode.TRANSACTION_CANCELLED]: {
    title: tk.addPaymentMethodErrorGenericTitle,
    text: tk.addPaymentMethodErrorGenericText,
  },
  [TransactionErrorCode.TRANSACTION_CANCELLED_BY_USER]: {
    title: tk.addPaymentMethodErrorGenericTitle,
    text: tk.addPaymentMethodErrorGenericText,
  },
  [TransactionErrorCode.TRANSACTION_DECLINED]: {
    title: tk.addPaymentMethodErrorGenericTitle,
    text: tk.addPaymentMethodErrorGenericText,
  },
  [TransactionErrorCode.TRANSACTION_EXPIRED]: {
    title: tk.addPaymentMethodErrorGenericTitle,
    text: tk.addPaymentMethodErrorGenericText,
  },
  [TransactionErrorCode.INVALID_CAPTURE_AMOUNT]: {
    title: tk.addPaymentMethodErrorGenericTitle,
    text: tk.addPaymentMethodErrorGenericText,
  },
  [TransactionErrorCode.INVALID_REFUND_AMOUNT]: {
    title: tk.addPaymentMethodErrorGenericTitle,
    text: tk.addPaymentMethodErrorGenericText,
  },
  [TransactionErrorCode.TRANSACTION_NOT_REFUNDABLE]: {
    title: tk.addPaymentMethodErrorGenericTitle,
    text: tk.addPaymentMethodErrorGenericText,
  },
};
