import { LogsEvent, datadogLogs } from '@datadog/browser-logs';
import { User } from '@datadog/browser-core';
import { datadogRum } from '@datadog/browser-rum';
import { loadBrowserEnv } from '@/config';

const SERVICE_NAME = 'payments-ui';
const browserEnv = loadBrowserEnv();

const PAYMENTS_DATADOG_ENV = browserEnv.PAYMENTS_DATADOG_ENV;
const PAYMENTS_DATADOG_CLIENT_TOKEN = browserEnv.PAYMENTS_DATADOG_CLIENT_TOKEN;
const PAYMENTS_DATADOG_RUM_CLIENT_TOKEN = browserEnv.PAYMENTS_DATADOG_RUM_CLIENT_TOKEN;
const PAYMENTS_DATADOG_RUM_APPLICATION_ID = browserEnv.PAYMENTS_DATADOG_RUM_APPLICATION_ID;

export const initDatadog = () => {
  datadogLogs.init({
    site: 'datadoghq.eu',
    clientToken: PAYMENTS_DATADOG_CLIENT_TOKEN,
    service: SERVICE_NAME,
    forwardConsoleLogs: ['warn', 'error'],
    sampleRate: 100,
    env: PAYMENTS_DATADOG_ENV,
    beforeSend: (log: LogsEvent) => {
      if (!['staging', 'production'].includes(PAYMENTS_DATADOG_ENV)) {
        console.log(log.message, log);
        return false;
      }

      return true;
    },
  });

  datadogRum.init({
    applicationId: PAYMENTS_DATADOG_RUM_APPLICATION_ID,
    clientToken: PAYMENTS_DATADOG_RUM_CLIENT_TOKEN,
    site: 'datadoghq.eu',
    service: 'payments-ui',
    env: PAYMENTS_DATADOG_ENV,
    sampleRate: 100,
    premiumSampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: 'mask',
    beforeSend: () => {
      if (!['staging', 'production'].includes(PAYMENTS_DATADOG_ENV)) {
        return false;
      }

      return true;
    },
  });

  // remove this line to disable session recording
  datadogRum.startSessionReplayRecording();
};

export const setDatadogUser = (user: User) => {
  datadogLogs.setUser(user);
  datadogRum.setUser(user);
};

export const clearDatadogUser = () => {
  datadogLogs.clearUser();
  datadogRum.clearUser();
};
