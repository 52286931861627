import React from 'react';
import { useTranslation } from 'react-i18next';

import { tk } from '@/i18n/translationKeys';
import { SonntagButtonV2 } from '@getgrover/ui';
import { useQueryParams } from '@/hooks/useQueryParams';
import { useApplicationData } from '@/providers/applicationData';
import { Text } from '@/components/Text';
import { Wrapper } from '@/components/Wrapper';
import { CloseIcon } from '@/icons/CloseIcon';
import { StatBlock } from '../StatBlock';
import { usePaymentsDetails } from './usePaymentsDetails';

import { TopPaneRoot, ButtonContent } from './TopPane.styles';
import { useTheme } from 'styled-components';

export const TopPane = () => {
  const { userId } = useApplicationData();
  const { subscriptionId } = useQueryParams();
  const { t } = useTranslation();
  const theme = useTheme();

  const { error, loading, formattedPrice, subscriptionTitle } = usePaymentsDetails({
    userId,
    subscriptionId,
  });

  if (error) {
    throw Error('PaymentsDetails Error');
  }

  const handleCloseSubscriptionClick = () => {
    window.location.href = location.pathname;
  };

  return (
    <TopPaneRoot>
      <StatBlock
        title={t(tk.yourPaymentsPageMonthlyTotalTitle)}
        primaryText={formattedPrice}
        secondaryText={t(tk.yourPaymentsPageForYourTech)}
        paymentsDetailsLoading={loading}
      />

      {subscriptionId && subscriptionTitle ? (
        <Wrapper margin="24px 0">
          <SonntagButtonV2 size="small" onClick={handleCloseSubscriptionClick}>
            <ButtonContent>
              <CloseIcon />
              <Text noWrap color={theme.colors.text.darkerGrey} typography="Paragraph">
                {subscriptionTitle}
              </Text>
            </ButtonContent>
          </SonntagButtonV2>
        </Wrapper>
      ) : null}
    </TopPaneRoot>
  );
};
