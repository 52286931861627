import { sendAnalyticsEvent } from '@devsbb/analytics-client';

type EventProperties = {
  userId: string;
  store: string;
  subscriptionId?: string;
};

export const trackYourPaymentsPagePaymentItemClickEvent = (properties: EventProperties) => {
  sendAnalyticsEvent<EventProperties>('Payments Item Clicked', properties);
};

export const trackYourPaymentsPageInvoiceDownloadClickEvent = (properties: EventProperties) => {
  sendAnalyticsEvent<EventProperties>('Download Invoice Button Clicked', properties);
};

export const trackYourPaymentsPageSubscriptionLinkClickEvent = (properties: EventProperties) => {
  sendAnalyticsEvent<EventProperties>('Subscription Link Clicked', properties);
};

export const trackYourPaymentsPagePaymentMethodsLinkClickEvent = (properties: EventProperties) => {
  sendAnalyticsEvent<EventProperties>('Payment Methods Link Clicked', properties);
};
