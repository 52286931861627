import styled from 'styled-components';

export type Status = 'default' | 'warn' | 'error';
export const NoticeRoot = styled.div<{ status: Status; height?: string }>`
  min-height: ${({ height }) => (height ? height : '44px')};
  padding: 12px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  background-color: ${({ theme, status }) => {
    switch (status) {
      case 'error':
        return theme.colors.background.lightRed;
      case 'warn':
        return theme.colors.background.lightYellow;
      case 'default':
        return theme.colors.hover.lighterGrey;
    }
  }};
`;
