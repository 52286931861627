import { PaymentJsModelError } from './types';

export const IntegrationLibErrorTypes = {
  general: 'general',
  invalidCardNumber: 'invalid-card-number',
  invalidCardCvv: 'invalid-card-cvv',
  invalidCardExpiration: 'invalid-card-expiration',
  cardExprired: 'card-expired',
  invalidCardholder: 'invalid-cardholder',
};

export const mapIntegrationLibError = (error: PaymentJsModelError) => {
  const { attribute, key } = error;

  switch ([attribute, key].join('.')) {
    case 'integration_key.errors.configuration':
    case 'integration_key.errors.system':
      return IntegrationLibErrorTypes.general;

    case 'number.errors.blank':
    case 'number.errors.invalid':
      return IntegrationLibErrorTypes.invalidCardNumber;

    case 'cvv.errors.blank':
    case 'cvv.errors.invalid':
      return IntegrationLibErrorTypes.invalidCardCvv;

    case 'month.errors.blank':
    case 'month.errors.invalid':
    case 'year.errors.blank':
    case 'year.errors.invalid':
      return IntegrationLibErrorTypes.invalidCardExpiration;

    case 'year.errors.expired':
      return IntegrationLibErrorTypes.cardExprired;

    case 'card_holder.errors.blank':
      return IntegrationLibErrorTypes.invalidCardholder;

    default:
      return IntegrationLibErrorTypes.general;
  }
};
