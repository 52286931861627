import { EventArgs, EventHandler, EventPayload, WebViewEventName, WebViewEventType } from './types';
import { logger } from '@/logger';

export function attachWebViewAPI(): void {
  try {
    if (window.InWebViewAPI?.attached) {
      console.warn('InWebViewAPI was already attached, can not be attached twice.');
      return;
    }

    const queuedCommands = window.InWebViewAPI ?? [];
    const eventHandlers: { [key in WebViewEventName]?: EventHandler[] } = {};

    function handleListen(eventName: WebViewEventName, eventHandler: EventHandler) {
      if (Array.isArray(eventHandlers[eventName])) {
        eventHandlers[eventName]!.push(eventHandler);
      } else {
        eventHandlers[eventName] = [eventHandler];
      }
    }

    function handleRemove(eventName: WebViewEventName, eventHandler: EventHandler) {
      if (!Array.isArray(eventHandlers[eventName])) {
        return;
      }

      const index = eventHandlers[eventName]!.indexOf(eventHandler);
      if (index !== -1) {
        eventHandlers[eventName]!.splice(index, 1);
      }
    }

    function handleFire(eventName: WebViewEventName, args: EventArgs) {
      if (Array.isArray(eventHandlers[eventName])) {
        eventHandlers[eventName]!.forEach((eventHandler) => {
          eventHandler(...[args]);
        });
      }
    }

    // For unit tests purpose
    function getEventHandlersCount(eventName: WebViewEventName) {
      return eventHandlers?.[eventName]?.length ?? 0;
    }

    window.InWebViewAPI = {
      attached: true,
      getEventHandlersCount,
      push: (payload: EventPayload) => {
        const [type, name, argsOrHandler] = payload;

        switch (type) {
          case WebViewEventType.listen:
            handleListen(name, argsOrHandler as EventHandler);
            break;
          case WebViewEventType.remove:
            handleRemove(name, argsOrHandler as EventHandler);
            break;
          case WebViewEventType.fire:
            handleFire(name, argsOrHandler as EventArgs);
            break;
          default:
        }
      },
    };

    window.InWebViewAPITest = [];

    if (Array.isArray(queuedCommands)) {
      while (queuedCommands.length > 0) {
        window.InWebViewAPITest.push('test');
        window.InWebViewAPI.push(queuedCommands.pop() as unknown as EventPayload);
      }
    }
  } catch (error) {
    logger.error('In Web View API', { error });
  }
}
