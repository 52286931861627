import { Text } from '@/components/Text';
import styled from 'styled-components';

export const PaymentMethodSelectViewRoot = styled.div<{ selected: boolean }>`
  border: ${({ theme }) => `2px solid ${theme.colors.stroke.lighterGrey}`};
  background: ${({ theme, selected }) =>
    selected ? theme.colors.background.lightGreen : theme.colors.background.white};
  width: 100%;
  height: 60px;
  min-height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  padding: 8px 16px;
  cursor: pointer;
`;

export const Unchecked = styled.div`
  width: 24px;
  height: 24px;
  border: 2px solid ${({ theme }) => theme.colors.stroke.lighterGrey};
  border-radius: 12px;
`;

export const IconWrapper = styled.div`
  flex-shrink: 0;
  margin-left: 16px;
`;

export const TextWrapper = styled(Text)`
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
`;
