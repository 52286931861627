import { Outlet, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { ApplicationDataProvider, ApplicationDataContextType } from '@/providers/applicationData';
import { getLocale, setLocale, setUserId, setUserType, setStore } from '@/utils/cookies';
import { Page500Light } from '@/components/ErrorPages';
import { DEFAULT_LOCALE } from '@/pages/YourPaymentsPage/constants';
import { WebViewConnector } from '@/modules/webView';
import { ApplicationLaunchResult, logger, setDatadogUser } from '@/logger';
import { LocalDevAuthButton } from '@/components/LocalDevAuthButton';
import { loadBrowserEnv } from '@/config';
import { fetchFlagsAndMap } from '@/flags/fetchAndMap';
import { UserType } from '@/generated/types';
import yourPaymentsPreloaderHtml from '../ssrPreloaders/your-payments-preloader.html?raw';
import changePaymentMethodPreloaderHtml from '../ssrPreloaders/change-payment-method-preloader.html?raw';
import paymentMethodListPreloaderHtml from '../ssrPreloaders/payment-method-list-preloader.html?raw';
import { getActiveStore } from '@/axiosRequest';
import { getUserProfile } from '@/gql/queries';
import { getPlatform, getRawUseragent } from '@/utils/useragent';
import { useTranslation } from 'react-i18next';
import { enrichAnalyticsUser } from '@devsbb/analytics-client';
import RouteBuilder from './routeBuilders'; 
import { appVersion } from '@/version';

const browserEnv = loadBrowserEnv();
const { LOCAL_AUTH_EMAIL, LOCAL_AUTH_PASSWORD, NODE_ENV, LOCAL_AUTH_AUTO_LOGIN } = browserEnv;
const localAutoLoginEnabled =
  LOCAL_AUTH_EMAIL &&
  LOCAL_AUTH_PASSWORD &&
  LOCAL_AUTH_AUTO_LOGIN === 'true' &&
  NODE_ENV === 'development';

export const ApplicationWrapper = () => {
  const { i18n } = useTranslation();
  const isGroverApp = Boolean(window?.ReactNativeWebView);
  const { store } = useParams<{ store: string }>();
  const [applicationData, setApplicationData] = useState<ApplicationDataContextType | null>(null);
  const [error, setError] = useState(false);
  const [authError, setAuthError] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const prepareApplicationData = async () => {
      try {
        const results = await Promise.all([getActiveStore(store as string), getUserProfile()]);

        const activeStore = results[0];
        const user = results[1] || {};
        const isConsolidatedBilling = false; //user?.groupBilling?.enabled;
        const billingDay = 0; // user?.groupBilling?.chosenBillingDay;

        const localeFromCookie = getLocale();
        // if no locale in cookie i18n was initialised with default locale
        // in this case we switch to default_language for store and set cookie
        if (!localeFromCookie) {
          const locale = activeStore?.default_language || DEFAULT_LOCALE;
          setLocale(locale);
          i18n.changeLanguage(locale);
        }

        const { flags, analyticsFlags } = await fetchFlagsAndMap({
          userId: Number(user.id),
          storeCode: activeStore.code,
          userType: user.type as UserType,
        });

        const rawUseragent = getRawUseragent();
        const platform = getPlatform();

        const routeBuilder = new RouteBuilder(activeStore.code);

        setApplicationData({
          userId: user.id as string,
          storeCode: activeStore.code,
          countryCode: activeStore.country_code,
          activeStore,
          user,
          isConsolidatedBilling,
          billingDay,
          flags,
          rawUseragent,
          platform,
          routeBuilder,
        });

        if (user.id) {
          setUserId(user.id);
        }

        if (user.type) {
          setUserType(user.type);
        }

        if (activeStore?.code) {
          setStore(activeStore?.code);
        }

        try {
          await enrichAnalyticsUser({
            id: user.id as string,
            store_code: activeStore?.code,
            consolidated_billing: !!isConsolidatedBilling,
            featureFlags: analyticsFlags,
          });
        } catch (error) {
          logger.error('Cannot enrich analytics user', { error, userId: user.id });
        }

        await setDatadogUser({
          id: user.id as string,
          platform,
          rawUseragent,
          storeCode: activeStore?.code,
          featureFlags: flags,
          locale: getLocale(),
          version: appVersion,
        });

        logger.info('Application Launched', {
          applicationLaunchResult: ApplicationLaunchResult.Success,
          flavour: isGroverApp ? 'app' : 'web',
          store: activeStore.code,
          platform,
          rawUseragent,
          locale: getLocale(),
          version: appVersion,
        });
        setLoading(false);
      } catch (e: unknown) {
        const UNATHORIZED_ERROR_MESSAGE = 'Unauthorized: missing refresh token';
        const errorMessage = (e as any).message;

        if (errorMessage === UNATHORIZED_ERROR_MESSAGE) {
          logger.error('Application Launched', {
            applicationLaunchResult: ApplicationLaunchResult.Auth,
          });
          setAuthError(true);
        } else {
          logger.error('Application Launched', {
            applicationLaunchResult: ApplicationLaunchResult.Error,
            error: e,
            message: errorMessage,
          });
          setError(true);
          setLoading(false);
        }
      }
    };

    prepareApplicationData();
  }, []);

  if (authError) {
    if (localAutoLoginEnabled) {
      return <div>Error! {localAutoLoginEnabled ? <LocalDevAuthButton /> : null}</div>;
    }

    return null;
  }

  if (error) {
    return <Page500Light />;
  }

  // Preloaders TBD
  // should not render Routes (Outlet) before user, flags and store are provided
  if (loading) {
    if (location.pathname.endsWith('your-payments')) {
      return <div dangerouslySetInnerHTML={{ __html: yourPaymentsPreloaderHtml }} />;
    }

    if (location.href.includes('your-profile/payments/payment-methods/change?subscription-id')) {
      return <div dangerouslySetInnerHTML={{ __html: changePaymentMethodPreloaderHtml }} />;
    }

    if (location.pathname.endsWith('your-profile/payments/payment-methods')) {
      return <div dangerouslySetInnerHTML={{ __html: paymentMethodListPreloaderHtml }} />;
    }

    return null;
  }

  return (
    <ApplicationDataProvider value={applicationData}>
      <WebViewConnector />
      <Outlet />
    </ApplicationDataProvider>
  );
};
