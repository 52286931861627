import React from 'react';
import { Text } from '@/components/Text';
import { EmptyScreenRoot } from './EmptyScreen.styles';
import { useTheme } from 'styled-components';

interface EmptyScreenProps {
  title: string;
  subtitle: string;
  maxWidth?: string;
}

export const EmptyScreen = ({ title, subtitle, maxWidth }: EmptyScreenProps) => {
  const theme = useTheme();
  return (
    <EmptyScreenRoot maxWidth={maxWidth}>
      <Text typography="SuperTinyHeadline" color={theme.colors.text.baseGrey}>
        {title}
      </Text>
      <Text color={theme.colors.text.baseGrey}>{subtitle}</Text>
    </EmptyScreenRoot>
  );
};
