import React, { useCallback, useEffect, useState } from 'react';
import { FlowPage } from '@/components/FlowPage';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useApplicationData } from '@/providers/applicationData';
import { PAYMENT_OPTIONS } from '@/gql/queries';
import { PaymentMethodOptionsScreen } from './screens/PaymentMethodOptionsScreen';
import { AddPaymentMethodErrorScreen } from './screens/AddPaymentMethodErrorScreen';
import { PaypalRedirectionScreen } from './screens/PaypalRedirectionScreen';
import { SepaRedirectionScreen } from './screens/SepaRedirectionScreen';
import { CreatedPaymentMethodPollingScreen } from './screens/CreatedPaymentMethodPollingScreen';
import { CurrentScreen, ScreenCommon, ScreenData, ScreenKey } from './types';
import { useQuery } from '@apollo/client';
import { PaymentOption } from '@/generated/types';
import { trackYourProfilePageVisitedEvent } from '@/analytics';
import { getLocale } from '@/utils/cookies';
import { DEFAULT_LOCALE } from '../YourPaymentsPage/constants';
import { QueryParamName } from '@/constants';
import { DocumentTitle, PageName } from '@/components/DocumentTitle';

const Screens: Record<ScreenKey, React.FunctionComponent<ScreenCommon>> = {
  [ScreenKey.paymentMethodOptions]: PaymentMethodOptionsScreen,
  [ScreenKey.createdPaymentMethodPolling]: CreatedPaymentMethodPollingScreen,
  [ScreenKey.addPaymentMethodError]: AddPaymentMethodErrorScreen,
  [ScreenKey.paypalRedirection]: PaypalRedirectionScreen,
  [ScreenKey.sepaRedirection]: SepaRedirectionScreen,
};

export const AddPaymentMethodPage = () => {
  const navigate = useNavigate();
  const { activeStore, routeBuilder } = useApplicationData();
  const [searchParams] = useSearchParams();
  const { data } = useQuery(PAYMENT_OPTIONS);

  // callbacks from payment provider
  const paymentMethodStatus = searchParams.get(QueryParamName.paymentMethodStatus);

  // to determine if we started Add PM from list/change/bulk
  const bulkUpdatePaymentMethodId = searchParams.get(QueryParamName.bulkId);
  const changePaymentMethodSubscriptionId = searchParams.get(QueryParamName.subscriptionId);
  const flowStartedFromBulk = !!bulkUpdatePaymentMethodId;
  const flowStartedFromChange = !!changePaymentMethodSubscriptionId;
  const flowStartedFromList = !flowStartedFromBulk && !flowStartedFromChange;

  let initialScreenKey = ScreenKey.paymentMethodOptions;
  if (paymentMethodStatus === 's') {
    // success flow:
    // - after redirect from payment provider we're back to this page
    // - since activating newly created PM takes time we need to show fake preloader for a while
    // - after fake preloading we are more or less sure the PM was activeted and we can show
    //   the success toast and navigate to the page where Add PM flow got started from (bulk/change/list)
    // - the toast will be shown on bulk/change/list and all PMs including newly added one will be rendered
    initialScreenKey = ScreenKey.createdPaymentMethodPolling;
  }

  if (paymentMethodStatus === 'f') {
    initialScreenKey = ScreenKey.createdPaymentMethodPolling;
  }

  const [currentScreen, setCurrentScreen] = useState<CurrentScreen>({
    key: initialScreenKey,
    data: {},
  });

  const Screen = Screens[currentScreen.key];

  useEffect(() => {
    const locale = getLocale() || DEFAULT_LOCALE;

    trackYourProfilePageVisitedEvent({
      path: location.pathname,
      store: activeStore.code,
      store_id: activeStore.store_id,
      locale,
      legacy: false,
    });
  }, []);

  useEffect(() => {
    if (paymentMethodStatus === 's') {
      setCurrentScreen({ key: ScreenKey.createdPaymentMethodPolling, data: {} });
    }
    if (paymentMethodStatus === 'f') {
      setCurrentScreen({ key: ScreenKey.createdPaymentMethodPolling, data: {} });
    }
  }, [paymentMethodStatus, bulkUpdatePaymentMethodId, changePaymentMethodSubscriptionId]);

  const handleSetScreen = useCallback((screenKey: ScreenKey, data: ScreenData) => {
    const finalData = {
      ...currentScreen.data,
      ...data,
    };

    setCurrentScreen({ key: screenKey, data: finalData });
  }, []);

  const handleClose = useCallback(() => {
    if (bulkUpdatePaymentMethodId) {
      return navigate(routeBuilder.buildPaymentMethodBulkUpdateRoute({ paymentMethodId: bulkUpdatePaymentMethodId }));
    }

    if (changePaymentMethodSubscriptionId) {
      return navigate(routeBuilder.buildChangePaymentMethodRoute({ subscriptionId: changePaymentMethodSubscriptionId }));
    }

    navigate(`/${activeStore.code}/your-profile/payments/payment-methods`);
  }, [bulkUpdatePaymentMethodId, changePaymentMethodSubscriptionId]);

  const handleBack = useCallback(() => {
    handleClose();
  }, []);

  const paymentOptions = data?.paymentOptions ?? ([] as PaymentOption[]);

  const withMobileBackButton =
    currentScreen.key === ScreenKey.addPaymentMethodError ||
    currentScreen.key === ScreenKey.paymentMethodOptions;

  return (
    <FlowPage
      newDesign
      withMobileBackButton={withMobileBackButton}
      onClose={handleClose}
      onBack={handleBack}
    >
      <DocumentTitle pageName={PageName.addPaymentMethod} />
      <Screen
        currentScreen={currentScreen}
        setScreen={handleSetScreen}
        onClose={handleClose}
        onBack={handleBack}
        paymentOptions={paymentOptions}
      />
    </FlowPage>
  );
};
