import { Platform } from '@/utils/useragent';
import { sendAnalyticsEvent } from '@devsbb/analytics-client';

type EventProperties = {
  userId: string;
  store: string;
  legacy: boolean;
  platform: Platform;
  rawUseragent: string;
};

type ErrorProperties = {
  userId: string;
  store: string;
  errorStatus: string;
  errorMessage: string;
  platform: Platform;
  rawUseragent: string;
};

export const trackAddPaymentMethodBackButtonClickEvent = (properties: EventProperties) => {
  sendAnalyticsEvent<EventProperties>('Back Button Clicked', properties);
};

export const trackAddPaymentMethodCardOptionClickEvent = (properties: EventProperties) => {
  sendAnalyticsEvent<EventProperties>('Card Option Clicked', properties);
};

export const trackAddPaymentMethodPaypalOptionClickEvent = (properties: EventProperties) => {
  sendAnalyticsEvent<EventProperties>('Paypal Option Clicked', properties);
};

export const trackAddPaymentMethodSepaOptionClickEvent = (properties: EventProperties) => {
  sendAnalyticsEvent<EventProperties>('Sepa Option Clicked', properties);
};

export const trackAddPaymentMethodCardSubmittedEvent = (properties: EventProperties) => {
  sendAnalyticsEvent<EventProperties>('Card Submitted', properties);
};

export const trackAddPaymentMethodSuccessEvent = (properties: EventProperties) => {
  sendAnalyticsEvent<EventProperties>('Add Payment Method Success', properties);
};

export const trackAddPaymentMethodErrorEvent = (properties: ErrorProperties) => {
  sendAnalyticsEvent<ErrorProperties>('Add Payment Method Error', properties);
};
