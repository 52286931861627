import styled from 'styled-components';

const spaceUnit = 4;

export interface GroupRootProps {
  gap?: number;
  mobileGap?: number;
  inline?: boolean;
  vertical?: boolean;
  centered?: boolean;
  fullWidth?: boolean;
}

export const Root = styled.div<GroupRootProps>`
  display: ${(props: GroupRootProps) => (props.inline ? 'inline-flex' : 'flex')};
  flex-direction: ${(props: GroupRootProps) => (props.vertical ? 'column' : 'row')};
  ${(props: GroupRootProps) => (props.centered ? 'align-items: center' : '')};
  ${(props: GroupRootProps) => (props.fullWidth ? 'width: 100%' : '')};

  & > * {
    margin-bottom: ${(props: GroupRootProps) =>
      props.vertical && props.gap ? `${props.gap * spaceUnit}px` : 0};
    margin-right: ${(props: GroupRootProps) =>
      !props.vertical && props.gap ? `${props.gap * spaceUnit}px` : 0};

    &:last-child {
      margin-bottom: 0;
      margin-right: 0;
    }
  }

  @media ${({ theme }) => theme.screenSizes.tabletMax} {
    & > * {
      margin-bottom: ${(props: GroupRootProps) =>
        props.vertical && props.mobileGap ? `${props.mobileGap * spaceUnit}px` : 0};
      margin-right: ${(props: GroupRootProps) =>
        !props.vertical && props.mobileGap ? `${props.mobileGap * spaceUnit}px` : 0};

      &:last-child {
        margin-bottom: 0;
        margin-right: 0;
      }
    }
  }
`;
