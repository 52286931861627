import { NextPaymentNote, Price, FlexDetails } from '@/generated/types';

interface SubscriptionItemUiFriendly {
  productName?: string;
  iconSvgPath?: string | null;
  imageSrc?: string;
  amountDue?: Price;
  nextPeriodStartsAt?: Date;
}

export const mapContractToSubscriptionItem = (
  contract: FlexDetails
): SubscriptionItemUiFriendly | null => {
  if (!contract) {
    return null;
  }

  if (contract.__typename === 'Flex') {
    const { goods, billingTerms } = contract;
    const product = goods?.[0]?.product;

    return {
      productName: product?.name,
      iconSvgPath: product?.category.images.iconSvgPath,
      imageSrc: product?.imageUrl,
      amountDue: billingTerms?.nextPeriod?.amountDue,
      nextPeriodStartsAt: billingTerms?.nextPeriod?.starts,
    };
  }

  if (contract.__typename === 'SubscriptionDetails') {
    const { product, price, note } = contract;

    return {
      productName: product?.name,
      iconSvgPath: product?.category.images.iconSvgPath,
      imageSrc: product?.imageUrl,
      amountDue: price,
      nextPeriodStartsAt: (note as NextPaymentNote)?.date,
    };
  }

  return null;
};
