import styled from 'styled-components';

export const RippleEmptyButton = styled.div`
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
`;

export const Root = styled.button<{ disabled?: boolean; fullWidth?: boolean }>`
  position: relative;
  border: 2px solid ${({ theme }) => theme.colors.stroke.lighterGrey};
  border-radius: 8px;
  padding: 24px 16px;
  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
  color: ${(props) =>
    props.disabled ? props.theme.colors.text.lightGrey : props.theme.colors.text.darkerGrey};
  fill: ${(props) =>
    props.disabled ? props.theme.colors.text.lightGrey : props.theme.colors.text.darkerGrey};
  background: ${(props) => props.theme.colors.background.white};
  ${(props) => props.theme.typography.RegularParagraphBold}
  display: flex;
  justify-content: space-between;
`;
