import styled, { DefaultTheme } from 'styled-components';

type ColorKey = keyof DefaultTheme['colors'] | string;

const getColor = (color: ColorKey, theme: DefaultTheme) => {
  if (!color) {
    return 'inherit';
  }

  return typeof color === 'string' ? color : theme.colors[color] || 'inherit';
};

export const TagRoot = styled.div<{
  backgroundColor: ColorKey;
  textColor: ColorKey;
  isMobile: boolean;
}>`
  width: fit-content;
  height: ${({ isMobile }) => (isMobile ? '26px' : '32px')};
  padding: 4px 8px;
  border-radius: ${({ isMobile }) => (isMobile ? '14px' : '16px')};
  background-color: ${({ backgroundColor, theme }) => getColor(backgroundColor, theme)};
  color: ${({ textColor, theme }) => getColor(textColor, theme)};
  display: flex;
  align-items: center;
  justify-content: center;
`;
