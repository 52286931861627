import React from 'react';
import { PaymentMethodWithContracts } from '@/types/paymentMethods';
import { BrandVisaIcon } from '@/icons/BrandVisaIcon';
import { BrandPaypalIcon } from '@/icons/BrandPaypalIcon';
import { BrandAmericanExpressIcon } from '@/icons/BrandAmericanExpressIcon';
import { BrandSepaIcon } from '@/icons/BrandSepaIcon';
import { BrandMastercardIcon } from '@/icons/BrandMastercardIcon';
import { ByInvoiceIcon } from '@/icons/ByInvoiceIcon';
import { BrandUnknownIcon } from '@/icons/BrandUnknownIcon';
import { OutlineWrapper, BrandPlaceholder } from './PaymentMethodBrand.styles';
import { PaymentMethodDisplayType } from '@/utils/paymentMethods';

type Size = 'small' | 'normal' | 'large';
interface PaymentMethodBrandProps {
  iconKey: PaymentMethodDisplayType;
  size: Size;
  outlined?: boolean;
  cardNumber?: string;
  editingView?: boolean;
}

const sizeToPixels: Record<Size, number> = {
  small: 24,
  normal: 36,
  large: 64,
};

const iconsMap: Record<PaymentMethodDisplayType, React.FunctionComponent<any>> = {
  [PaymentMethodDisplayType.visa]: BrandVisaIcon,
  [PaymentMethodDisplayType.mastercard]: BrandMastercardIcon,
  [PaymentMethodDisplayType.americanExpress]: BrandAmericanExpressIcon,
  [PaymentMethodDisplayType.paypal]: BrandPaypalIcon,
  [PaymentMethodDisplayType.sepa]: BrandSepaIcon,
  [PaymentMethodDisplayType.byInvoice]: ByInvoiceIcon,
  [PaymentMethodDisplayType.unknown]: BrandUnknownIcon,
};

export const PaymentMethodBrand = ({
  iconKey,
  outlined,
  size,
  cardNumber,
  editingView,
}: PaymentMethodBrandProps) => {
  const Icon = iconsMap[iconKey];

  if (editingView && !cardNumber) {
    return <BrandPlaceholder />;
  }

  if (outlined) {
    return (
      <OutlineWrapper>
        <Icon width={sizeToPixels[size]} />
      </OutlineWrapper>
    );
  }

  return <Icon width={sizeToPixels[size]} />;
};
