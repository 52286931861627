import { ApolloLink } from '@apollo/client';
import {
  getAccessToken,
  getImpersonatedUserId,
  getImpersonatedUserCompanyId,
  getLocale,
  getStore,
  getUserId,
  getDeviceId,
} from '@/utils/cookies';
import { HeaderKey } from '../types';

interface ContextType {
  headers?: Record<string, string>;
}

export const headersLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }: ContextType) => {
    const token = getAccessToken();
    const userId = getUserId();
    const store = getStore();
    const locale = getLocale();
    const deviceId = getDeviceId();
    const impersonatedUserId = getImpersonatedUserId();
    const impersonatedUserCompanyId = getImpersonatedUserCompanyId();

    const finalHeaders = { ...headers };

    if (token) finalHeaders[HeaderKey.AUTH] = `Bearer ${token}`;
    if (userId) finalHeaders[HeaderKey.USER] = userId;
    if (store) finalHeaders[HeaderKey.STORE] = store;
    if (locale) finalHeaders[HeaderKey.LOCALE] = locale;
    if (deviceId) finalHeaders[HeaderKey.DEVICE_ID] = deviceId;
    if (impersonatedUserId) finalHeaders[HeaderKey.IMPERSONATED_USER_ID] = impersonatedUserId;
    if (impersonatedUserCompanyId)
      finalHeaders[HeaderKey.IMPERSONATED_USER_COMPANY_ID] = impersonatedUserCompanyId;

    return {
      headers: finalHeaders,
    };
  });

  return forward(operation);
});
