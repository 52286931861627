import { useLocation } from 'react-router-dom';
import React from 'react';
import qs from 'qs';

export function useQueryParams() {
  const { search } = useLocation();
  return React.useMemo(
    () => qs.parse(search, { ignoreQueryPrefix: true }) as Record<string, string>,
    [search]
  );
}
