import React from 'react';
import { Root, GroupRootProps } from './Group.styles';

export interface GroupProps extends GroupRootProps {
  children: Array<React.ReactElement | null>;
  'data-testid'?: string;
}

export const Group = (props: GroupProps) => {
  const { children, ...rootProps } = props;

  return <Root {...rootProps}>{children}</Root>;
};
