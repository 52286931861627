import React, { useState, useRef, useEffect } from 'react';
import type { ReactNode } from 'react';
import { Root, ItemText, Portal, Value as SelectedValue } from '@radix-ui/react-select';
import { FilterTagOption } from '../types';

import {
  TriggerWrapper,
  Trigger,
  IconWrapper,
  ContentWrapper,
  ContentViewport,
  ItemWrapper,
  ContentScrollable,
} from './FilterTag.styles';

interface FilterTagProps {
  wrapperIcon?: ReactNode | null;
  placeholder?: string;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  onValueChange?: (newValue: string) => void;
  value: string;
  options: FilterTagOption[];
  selectedValueDisplay: ReactNode | string;
  iconClickable?: boolean;
  onIconClick?: (event: React.MouseEvent<HTMLElement>) => void;
  optionsScrollable?: boolean;
  triggerPadding?: string;
  triggerBackgroundColor?: string;
  contentWrapperMaxWidth?: string;
}

export const FilterTag = ({
  wrapperIcon,
  placeholder,
  open,
  onOpenChange,
  onValueChange,
  value,
  options,
  selectedValueDisplay,
  iconClickable,
  onIconClick,
  optionsScrollable,
  triggerPadding,
  triggerBackgroundColor,
  contentWrapperMaxWidth,
}: FilterTagProps): JSX.Element => {
  const firstDivRef = useRef<HTMLDivElement | null>(null);
  const [firstDivHeight, setFirstDivHeight] = useState<number>(0);

  useEffect(() => {
    if (firstDivRef.current) {
      setFirstDivHeight(firstDivRef.current?.clientHeight);
    }
  }, [options]);

  return (
    <Root
      open={open}
      onOpenChange={onOpenChange}
      onValueChange={onValueChange}
      value={value}
      data-testid="filter-tag-root"
    >
      {!iconClickable ? (
        <Trigger data-testid="filter-tag-trigger">
          {wrapperIcon}
          <SelectedValue placeholder={placeholder}>{selectedValueDisplay}</SelectedValue>
        </Trigger>
      ) : (
        <TriggerWrapper>
          <IconWrapper onClick={onIconClick}>{wrapperIcon}</IconWrapper>
          <Trigger
            data-testid="filter-tag-trigger"
            $padding={triggerPadding}
            $backgroundColor={triggerBackgroundColor}
          >
            <SelectedValue asChild placeholder={placeholder}>
              {selectedValueDisplay}
            </SelectedValue>
          </Trigger>
        </TriggerWrapper>
      )}

      <Portal>
        <ContentWrapper
          data-testid="filter-tag-options"
          position="popper"
          $maxWidth={contentWrapperMaxWidth}
          // HACK to fix selecting an option triggers a touch event on elements positioned behind
          ref={(instance: HTMLDivElement | null) => {
            if (!instance) return;
            instance.ontouchstart = (e: TouchEvent) => {
              e.preventDefault();
            };
          }}
        >
          <ContentViewport>
            {optionsScrollable ? (
              <ContentScrollable $maxHeight={firstDivHeight}>
                <div ref={firstDivRef}>
                  {options.slice(0, 4).map((option, idx) => (
                    <ItemWrapper
                      data-testid="filter-tag-option"
                      key={`filter-tag-item-${option.value}-${idx}`}
                      value={option.value}
                    >
                      <ItemText>{option.label}</ItemText>
                    </ItemWrapper>
                  ))}
                </div>
                <div>
                  {options.slice(4).map((option, idx) => (
                    <ItemWrapper
                      data-testid="filter-tag-option"
                      key={`filter-tag-item-${option.value}-${4 + idx}`}
                      value={option.value}
                    >
                      <ItemText>{option.label}</ItemText>
                    </ItemWrapper>
                  ))}
                </div>
              </ContentScrollable>
            ) : (
              options.map((option, idx) => (
                <ItemWrapper
                  data-testid="filter-tag-option"
                  key={`filter-tag-item-${option.value}-${idx}`}
                  value={option.value}
                >
                  <ItemText>{option.label}</ItemText>
                </ItemWrapper>
              ))
            )}
          </ContentViewport>
        </ContentWrapper>
      </Portal>
    </Root>
  );
};
