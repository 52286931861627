import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Offset } from '@/components/Offset';
import { PlusIcon } from '@/icons/PlusIcon';
import { AddPaymentMethodButtonRoot } from './AddPaymentMethodButton.styles';
import { trackChangePaymentMethodAddStartedEvent } from '@/analytics';
import { useApplicationData } from '@/providers/applicationData';
import { tk } from '@/i18n/translationKeys';
import { QueryParamName } from '@/constants';

export const AddPaymentMethodButton = ({
  withDelete,
  oldPaymentMethodId,
  contractId,
}: {
  withDelete?: boolean;
  oldPaymentMethodId?: string;
  contractId: string | null;
}) => {
  const { userId, storeCode, routeBuilder } = useApplicationData();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const query: Record<string, string> = {};
  if (oldPaymentMethodId) {
    query[QueryParamName.bulkId] = oldPaymentMethodId;
  }
  if (withDelete) {
    query[QueryParamName.mode] = 'delete';
  }
  if (contractId) {
    query[QueryParamName.subscriptionId] = contractId;
  }

  const handleClick = () => {
    trackChangePaymentMethodAddStartedEvent({
      userId,
      store: storeCode,
      bulk: !contractId,
    });
    navigate(routeBuilder.buildAddPaymentMethodRoute({ query: query }));
  };

  return (
    <AddPaymentMethodButtonRoot
      outlined
      fullWidth
      onClick={handleClick}
      icon={
        <Offset right={5}>
          <PlusIcon />
        </Offset>
      }
    >
      {t(tk.changePaymentMethodAddButtonText)}
    </AddPaymentMethodButtonRoot>
  );
};
