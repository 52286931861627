import styled from 'styled-components';
import { GroverLogo } from '@/icons/GroverLogo';

export const FlowPageRoot = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: ${(props) => props.theme.colors.background.white};
  display: flex;
  flex-direction: column;
`;

export const DesktopGroverLogo = styled(GroverLogo)`
  width: 105px;
  height: 35px;
`;

export const TopBarDesktop = styled.div`
  padding: 16px;
  margin: 32px 72px 0 72px;
  border-bottom: 2px solid ${({ theme }) => theme.colors.stroke.lighterGrey};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TopBarMobile = styled.div`
  display: flex;
  justify-content: left;
`;

export const HelpButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const ContentDesktop = styled.div<{ newDesign?: boolean;}>`
  position: relative;
  border: 2px solid ${({ theme }) => theme.colors.stroke.lighterGrey};
  border-radius: 8px;
  margin: ${({ newDesign }) => newDesign ? '40px 0 20px 0' : '40px 0 0 0'};
  padding: 32px 16px 32px 16px;
  width: ${({ newDesign }) => newDesign ? '750px' : '480px'};
`;

export const ContentMobile = styled.div`
  padding: 8px 16px 16px 16px;
  width: 100%;
  height: 100%;
`;

export const CloseButton = styled.div`
  height: 32px;
  width: 32px;
  border-radius: 16px;
  cursor: pointer;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid ${({ theme }) => theme.colors.stroke.lighterGrey};
  position: absolute;
  top: 16px;
  right: 16px;
`;

export const BackButton = styled.div`
  height: 64px;
  width: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
