import { loadBrowserEnv } from '@/config';
import { UserType } from '@/generated/types';
import { flagKeys, defaultFlagData, FeatureFlagName } from './flagKeys';
import { logger } from '@/logger';
import { FlagrBatchResponse } from './flagrTypes';
import { getDeviceId } from '@/utils/cookies';

const browserEnv = loadBrowserEnv();
const FLAGR_BASE_URL = browserEnv.FLAGR_BASE_URL;

export type FetchFlagsAndMapReturnType = {
  flags: Record<FeatureFlagName, boolean>;
  analyticsFlags: Record<FeatureFlagName, 'enabled' | 'disabled'>;
};

export const fetchFlagsAndMap = async ({
  userId,
  userType,
  storeCode,
}: {
  userId: number;
  userType: UserType;
  storeCode: string;
  }): Promise<FetchFlagsAndMapReturnType> => {
  const deviceId = getDeviceId();
  const entities = [
    {
      entityContext: {
        individual: false,
        flavour: 'web',
        storeType: 'main',
        storeCode,
        userId,
        userType,
      },
      entityID: deviceId ?? String(userId),
    },
  ];

  const response = await fetch(`${FLAGR_BASE_URL}/evaluation/batch`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ entities, flagKeys }),
  });

  if (response.status >= 400) {
    logger.error('(fetchFlagData): Error on fetch flagr data');
    return defaultFlagData as FetchFlagsAndMapReturnType;
  }

  const parsedResponse: FlagrBatchResponse = await response.json();
  const flags = parsedResponse.evaluationResults.reduce((acc, item) => {
    return {
      ...acc,
      [item.flagKey]: item.variantKey === 'enabled',
    };
  }, {});

  const analyticsFlags = parsedResponse.evaluationResults.reduce((acc, item) => {
    return {
      ...acc,
      [item.flagKey]: item.variantKey,
    };
  }, {});

  return {
    flags,
    analyticsFlags,
  } as FetchFlagsAndMapReturnType;
};
