import React, { useCallback, useEffect } from 'react';
import Squid from './assets/Squid.svg';
import { useTranslation } from 'react-i18next';
import { Text } from '@/components/Text';
import { Container, Image, Button } from './styles';
import { tk } from '@/i18n/translationKeys';
import { trackErrorScreenReloadButtonClickEvent, trackErrorScreenShowedEvent } from '@/analytics';
import { logger } from '@/logger';
import { useTheme } from 'styled-components';

export const Page500Light = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  
  useEffect(() => {
    logger.error('trackErrorScreenShowedEventInitialLoad');
    trackErrorScreenShowedEvent({ initialLoad: true });
  }, []);

  const handleReloadButtonClick = useCallback(() => {
    trackErrorScreenReloadButtonClickEvent({ initialLoad: true });
    location.reload();
  }, []);

  return (
    <Container centered vertical gap={8} mobileGap={8} data-testid="error500-page-light">
      <Image src={Squid} />

      <Text centered typography="Subheadline" color={theme.colors.text.darkerGrey}>
        {t(tk.errorScreenTitle)}
      </Text>

      <Button color="red" onClick={handleReloadButtonClick}>
        <Text typography="SuperTinyHeadline">{t(tk.errorScreenButtonText)}</Text>
      </Button>
    </Container>
  );
};
