import React from 'react';

export const ProgressIcon = ({ color }: { color?: string }) => {
  return (
    <svg width="29" height="24" viewBox="0 0 29 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.3335 12C4.3335 5.37333 9.70683 0 16.3335 0C22.9602 0 28.3335 5.37333 28.3335 12C28.3335 18.6267 22.9602 24 16.3335 24C13.0135 24 10.0268 22.6533 7.8535 20.48L9.74683 18.5867C11.4268 20.28 13.7602 21.3333 16.3335 21.3333C21.4935 21.3333 25.6668 17.16 25.6668 12C25.6668 6.84 21.4935 2.66667 16.3335 2.66667C11.1735 2.66667 7.00016 6.84 7.00016 12H11.0002L5.6135 17.3733L5.52016 17.1867L0.333496 12H4.3335Z"
        fill="#333333"
        fillOpacity="0.75"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6668 6.33333H17.3335V12.1437L22.1238 14.9905L20.823 17.1766L14.6668 13.5231V6.33333Z"
        fill="#333333"
        fillOpacity="0.75"
      />
    </svg>
  );
};
