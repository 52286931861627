import React from 'react';

export const CardErrorIcon = () => {
  return (
    <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle opacity="0.1" cx="36" cy="36" r="36" fill="#F35757" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.5 27.5V31H46.5V27.5H25.5ZM25.5 43.5V33.5H46.5V38H49V27C49 25.8954 48.1046 25 47 25H25C23.8954 25 23 25.8954 23 27V44C23 45.1046 23.8954 46 25 46H42V43.5H25.5ZM28.25 38C27.5596 38 27 38.5596 27 39.25C27 39.9404 27.5596 40.5 28.25 40.5H30.75C31.4404 40.5 32 39.9404 32 39.25C32 38.5596 31.4404 38 30.75 38H28.25ZM34 39.25C34 38.5596 34.5596 38 35.25 38H35.75C36.4404 38 37 38.5596 37 39.25C37 39.9404 36.4404 40.5 35.75 40.5H35.25C34.5596 40.5 34 39.9404 34 39.25Z"
        fill="#EF432D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.9993 40.0007C43.5574 40.4426 43.5574 41.1589 43.9993 41.6007L46.3993 44.0008L44 46.4C43.5582 46.8419 43.5582 47.5582 44 48C44.4419 48.4419 45.1582 48.4419 45.6 48L47.9993 45.6008L50.3993 48.0007C50.8411 48.4426 51.5574 48.4426 51.9993 48.0007C52.4411 47.5589 52.4411 46.8426 51.9993 46.4007L49.5993 44.0008L52 41.6C52.4419 41.1582 52.4419 40.4419 52 40C51.5582 39.5582 50.8419 39.5582 50.4 40L47.9993 42.4008L45.5993 40.0007C45.1574 39.5589 44.4411 39.5589 43.9993 40.0007Z"
        fill="#EF432D"
      />
    </svg>
  );
};
