import React from 'react';

export const BrandVisaIcon = ({ width = 36 }: { width: number }) => {
  return (
    <svg
      width={width}
      height="26"
      viewBox="0 0 65 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.6869 0.337066L16.1609 19.6901H10.5462L6.41682 4.38183C6.38346 4.01855 6.25095 3.66969 6.03224 3.36935C5.81354 3.06901 5.51613 2.8275 5.1691 2.66842C3.52231 1.92268 1.78689 1.3663 0 1.01119V0.44942H8.91225C9.50208 0.455986 10.0704 0.659898 10.5163 1.02498C10.9623 1.39006 11.257 1.89269 11.3483 2.44371L13.5466 13.6792L19.0128 0.505596L24.6869 0.337066ZM46.4625 13.3702C46.4625 8.28615 39.0356 8.00526 39.0951 5.70199C39.0951 5.02786 39.808 4.26947 41.3231 4.10094C43.1048 3.93036 44.902 4.22167 46.5219 4.9436L47.4429 0.84266C45.8625 0.295979 44.1942 0.01092 42.5114 0C37.3126 0 33.5992 2.61224 33.5992 6.40421C33.5992 9.21307 36.2134 10.7299 38.2038 11.6568C40.1942 12.5837 40.9369 13.2297 40.9369 14.0724C40.9369 15.3645 39.303 15.9263 37.7879 15.9543C35.9055 16.0012 34.0424 15.585 32.3812 14.7465L31.3414 18.9879C33.2366 19.6768 35.2526 20.0199 37.2829 19.9991C42.8085 19.9991 46.4031 17.415 46.4328 13.3702H46.4625ZM60.1577 19.6901H65L60.7815 0.337066H56.3254C55.8411 0.336588 55.3676 0.472615 54.9655 0.727776C54.5633 0.982938 54.2507 1.34565 54.0676 1.76958L46.1952 19.6901H51.7207L52.7902 16.8813H59.5338L60.1577 19.6901ZM54.2162 12.8365L57.0087 5.58964L58.6723 12.8084L54.2162 12.8365ZM32.1138 0.337066L27.7765 19.6901H22.6074L26.9447 0.337066H32.1138Z"
        fill="#1434CB"
      />
    </svg>
  );
};
