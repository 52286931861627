import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { withRipple } from '@getgrover/ui';
import { toast } from 'react-toastify';
import { RippleEmptyButton, Root } from './DeleteButton.styles';
import { DeleteDialog } from './DeleteDialog';
import {
  trackPaymentMethodDetailsDeletedEvent,
  trackPaymentMethodDetailsDeleteFailedEvent,
  trackPaymentMethodDetailsDeleteButtonClickEvent,
  trackPaymentMethodDetailsDeleteCancelEvent,
  trackPaymentMethodDetailsDeleteConfirmEvent,
} from '@/analytics';
import { Toast } from '@/components/Toast';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PaymentMethodWithContracts } from '@/types/paymentMethods';
import { DeleteIcon } from '@/icons/DeleteIcon';
import { Text } from '@/components/Text';
import { useMutation } from '@apollo/client';
import { DELETE_PAYMENT_METHOD_MUTATION } from '@/gql/mutations';
import { buildPaymentMethodSpecificText } from '@/utils/paymentMethods';
import { useApplicationData } from '@/providers/applicationData';
import { tk } from '@/i18n/translationKeys';
import { QueryParamName } from '@/constants';
import { FeatureName, LogPrefix, logger } from '@/logger';
import { PaymentMethodStatus } from '@/generated/types';
import { ModalWindow } from '@/components/ModalWindow';

const IconRippleEffect = withRipple(RippleEmptyButton);

export const DeleteButton = ({
  paymentMethod,
  userId,
  disabled,
  hasLinkedContracts,
}: {
  paymentMethod: PaymentMethodWithContracts;
  userId: string;
  disabled: boolean;
  hasLinkedContracts: boolean;
}) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const queryParamFrom = searchParams.get(QueryParamName.from);
  const [deleteConfirmDialog, setDeleteConfirmDialog] = useState(false);
  const navigate = useNavigate();
  const { storeCode, routeBuilder } = useApplicationData();
  const [deletePaymentMethod, { loading: isDeleting }] = useMutation(
    DELETE_PAYMENT_METHOD_MUTATION
  );
  const invalid = paymentMethod.status === PaymentMethodStatus.INVALID;

  const handleDeleteConfirmClick = async () => {
    trackPaymentMethodDetailsDeleteConfirmEvent({
      userId: userId,
      store: storeCode,
      invalid,
      paymentMethodType: paymentMethod.__typename,
      queryParamFrom,
    });

    if (hasLinkedContracts) {
      const bulkUpdateRoute = routeBuilder.buildPaymentMethodBulkUpdateRoute({
        paymentMethodId: paymentMethod.id,
        query: { [QueryParamName.mode]: 'delete' },
      });
      return navigate(bulkUpdateRoute);
    }

    try {
      await deletePaymentMethod({ variables: { id: paymentMethod.id } });

      trackPaymentMethodDetailsDeletedEvent({
        userId: userId,
        store: storeCode,
        invalid,
        paymentMethodType: paymentMethod.__typename,
        queryParamFrom,
      });

      logger.info(LogPrefix.Success, { feature: FeatureName.DeletePaymentMethod });

      setDeleteConfirmDialog(false);

      const toastText =
        buildPaymentMethodSpecificText(paymentMethod) ||
        t(tk.deletePaymentMethodSuccessToastGenericText);

      toast(
        <Toast
          type="success"
          mobile={false}
          title={t(tk.deletePaymentMethodSuccessToastTitle)}
          text={toastText as string}
        />
      );

      navigate(`/${storeCode}/your-profile/payments/payment-methods/`);
    } catch {
      logger.error(LogPrefix.Failure, { feature: FeatureName.DeletePaymentMethod });
      trackPaymentMethodDetailsDeleteFailedEvent({
        userId: userId,
        store: storeCode,
        invalid,
        paymentMethodType: paymentMethod.__typename,
        queryParamFrom,
      });
      toast(
        <Toast
          type="failure"
          mobile={false}
          title={t(tk.deletePaymentMethodFailureToastTitle)}
          text={t(tk.deletePaymentMethodFailureToastText)}
        />
      );
    }
  };

  const handleDeleteClick = () => {
    trackPaymentMethodDetailsDeleteButtonClickEvent({
      userId: userId,
      store: storeCode,
      invalid,
      paymentMethodType: paymentMethod.__typename,
      queryParamFrom,
    });
    setDeleteConfirmDialog(true);
  };

  const handleDeleteCancelClick = () => {
    trackPaymentMethodDetailsDeleteCancelEvent({
      userId: userId,
      store: storeCode,
      invalid,
      paymentMethodType: paymentMethod.__typename,
      queryParamFrom,
    });
    setDeleteConfirmDialog(false);
  };

  const confirmationDialogTextTranslationKey = hasLinkedContracts
    ? tk.bulkUpdateDeleteConfirmationDialogText
    : tk.deletePaymentMethodConfirmationDialogText;

  const confirmationDialogText = t(confirmationDialogTextTranslationKey, {
    paymentMethodSpecificText: buildPaymentMethodSpecificText(paymentMethod),
  });

  return (
    <>
      {deleteConfirmDialog && (
        <ModalWindow onClose={handleDeleteCancelClick} mobile={false}>
          <DeleteDialog
            text={confirmationDialogText}
            onConfirm={handleDeleteConfirmClick}
            onCancel={handleDeleteCancelClick}
            inProgress={isDeleting}
          />
        </ModalWindow>
      )}

      <Root fullWidth disabled={disabled} onClick={handleDeleteClick}>
        <Text typography="SuperTinyHeadline">{t(tk.deletePaymentMethodButtonText)}</Text>
        <DeleteIcon />
        {!disabled ? <IconRippleEffect /> : null}
      </Root>
    </>
  );
};
