import Cookies from 'js-cookie';

export enum CookieKeys {
  userId = 'user_id',
  userType = 'user_type',
  accessToken = 'api_access_token',
  refreshToken = 'api_refresh_token',
  deviceId = 'deviceId',
  locale = 'locale',
  store = 'shop_country_code',
  impersonatedUserId = 'impersonated_user_id',
  impersonatedUserCompanyId = 'impersonated_user_company_id',
}

export enum Namespace {
  translations = 'translations',
  translator = 'translator',
}

export type Translations = {
  [key: string]: string | Translations;
};

export enum LOCALE {
  EN = 'en',
  DE = 'de',
  NL = 'nl',
  ES = 'es',
}

export enum STORE {
  at = 'at',
  de = 'de',
  es = 'es',
  nl = 'nl',
  us = 'us',
}

export const getUserId = () => {
  return Cookies.get(CookieKeys.userId);
};

export const setUserId = (userId: string) => {
  Cookies.set(CookieKeys.userId, userId);
};

export const getImpersonatedUserId = () => {
  return Cookies.get(CookieKeys.impersonatedUserId);
};

export const setImpersonatedUserId = (impersonatedUserId: string) => {
  Cookies.set(CookieKeys.impersonatedUserId, impersonatedUserId);
};

export const getImpersonatedUserCompanyId = () => {
  return Cookies.get(CookieKeys.impersonatedUserCompanyId);
};

export const setImpersonatedUserCompanyId = (impersonatedUserCompanyId: string) => {
  Cookies.set(CookieKeys.impersonatedUserCompanyId, impersonatedUserCompanyId);
};

export const getDeviceId = () => {
  return Cookies.get(CookieKeys.deviceId);
};

export const setDeviceId = (deviceId: string) => {
  Cookies.set(CookieKeys.deviceId, deviceId);
};

export const getUserType = () => {
  return Cookies.get(CookieKeys.userType);
};

export const setUserType = (userType: string) => {
  Cookies.set(CookieKeys.userType, userType);
};

export const removeUserId = () => {
  Cookies.remove(CookieKeys.userId);
};

export const getStore = (): STORE => {
  return Cookies.get(CookieKeys.store) as STORE;
};

export const setStore = (store: string) => {
  Cookies.set(CookieKeys.store, store);
};

export const getLocale = (): LOCALE => {
  return Cookies.get(CookieKeys.locale) as LOCALE;
};

export const setLocale = (locale: string) => {
  Cookies.set(CookieKeys.locale, locale);
};

export const getAccessToken = () => {
  return Cookies.get(CookieKeys.accessToken);
};

export const setAccessToken = (token: string, options: { expires: Date | number }) => {
  Cookies.set(CookieKeys.accessToken, token, options);
};

export const removeAccessToken = () => {
  Cookies.remove(CookieKeys.accessToken);
};

export const getRefreshToken = () => {
  return Cookies.get(CookieKeys.refreshToken);
};

export const setRefreshToken = (token: string, options: { expires: Date | number }) => {
  Cookies.set(CookieKeys.refreshToken, token, options);
};

export const removeRefreshToken = () => {
  Cookies.remove(CookieKeys.refreshToken);
};
