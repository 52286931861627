import React from 'react';

import { Card } from '@getgrover/ui';
import { LoadingUnit } from '@/components/Loading';
import { Group } from '@/components/Group';
import { Text } from '@/components/Text';
import { ChevronRightIcon } from '@/icons/ChevronRightIcon';

import { StatContainer, TitleWrapper } from './StatBlock.styles';
import { useTheme } from 'styled-components';

interface StatBlockProps {
  title: string;
  primaryText: string | null;
  secondaryText?: string;
  url?: string;
  paymentsDetailsLoading?: boolean;
}

export const StatBlock = ({ title, primaryText, secondaryText, url, paymentsDetailsLoading }: StatBlockProps) => {
  const theme = useTheme();

  if (paymentsDetailsLoading || !primaryText) {
    return (
      <StatContainer data-testid="your-payments-stat-block">
        <Card>
          <Group vertical gap={2} mobileGap={2}>
            <LoadingUnit height={5} width={30} />
            <LoadingUnit height={8} width={24} />
            <LoadingUnit height={3} width={20} />
          </Group>
        </Card>
      </StatContainer>
    );
  }

  const handleClick = () => {
    if (url) {
      // wait for 2nd iteration design to navigate
    }
    return;
  };

  return (
    <StatContainer data-testid="your-payments-stat-block" onClick={handleClick} clickable={url}>
      <Card>
        <Group vertical gap={2} mobileGap={2}>
          <TitleWrapper>
            <Text block typography="SmallCapsHeadline" color={theme.colors.text.baseGrey}>
              {title}
            </Text>
            {url ? <ChevronRightIcon /> : null}
          </TitleWrapper>
          <Text block typography="Subheadline" color={theme.colors.text.darkerGrey}>
            {primaryText}
          </Text>
          <Text block typography="TinyParagraph" color={theme.colors.text.baseGrey}>
            {secondaryText}
          </Text>
        </Group>
      </Card>
    </StatContainer>
  );
};
