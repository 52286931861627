import { useMedia } from 'react-use';
import { useTheme } from 'styled-components';

enum SupportedDevices {
  mobile = 'mobile',
  desktop = 'desktop',
}

export function useScreenMatch() {
  const theme = useTheme();
  const isPhone = useMedia(theme.screenSizes.mobile);
  const isTablet = useMedia(theme.screenSizes.tablet);
  const isDesktopScreen = useMedia(theme.screenSizes.desktop);
  const isLargeDesktop = useMedia(theme.screenSizes.largeDesktop);
  const isWideScreen = useMedia(theme.screenSizes.widescreenMin);

  const isMobile = isPhone || isTablet;
  const isDesktop = isDesktopScreen || isLargeDesktop || isWideScreen;

  let deviceType = SupportedDevices.mobile as SupportedDevices;
  if (isDesktop) deviceType = SupportedDevices.desktop;

  return {
    isMobile,
    isDesktop,
    deviceType,
  };
}
